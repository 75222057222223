import React, { useState, useRef, useEffect, useCallback } from "react";
import "./DropDown.scss";

const DropDown = ({
	label = "DropDown",
	children = "Default content",
	className = "",
	select = false,
	disabled = false,
	active = false,
	...attrs
}) => {
	const [dropDownState, setDropDownState] = useState(active);
	const [positionState, setPositionState] = useState("left-bottom");

	const dropDownParent = useRef(null);

	const closeDropDown = useCallback(
		e => {
			if (dropDownParent.current && select) {
				setDropDownState(false);
				document.removeEventListener("click", closeDropDown);
			} else {
				if (dropDownParent.current && !dropDownParent.current.contains(e.target)) {
					setDropDownState(false);
					document.removeEventListener("click", closeDropDown);
				}
			}
		},
		[select]
	);

	useEffect(() => {
		if (dropDownState) {
			document.addEventListener("click", closeDropDown);
		}
	}, [dropDownState, closeDropDown]);

	const toggleDropDown = e => {
		if (disabled) {
			e.preventDefault();
		} else {
			const dropDownBoby = dropDownParent.current.querySelector(".drop-down__body"),
				bodyRect = document.body.getBoundingClientRect(),
				dropDownBobyRect = dropDownBoby.getBoundingClientRect(),
				// offsetTop = dropDownBobyRect.top - bodyRect.top,
				offsetRight = dropDownBobyRect.right - bodyRect.right;

			if (offsetRight < -85) setPositionState("center-bottom");
			if (offsetRight > 0) setPositionState("left-bottom");
			setDropDownState(!dropDownState);
		}
	};

	const wrapperClasses = `drop-down ${className} ${dropDownState ? "drop-down--active" : ""} ${
		disabled ? "drop-down--disabled" : ""
	} `;

	return (
		<>
			<div ref={dropDownParent} className={wrapperClasses} {...attrs}>
				<div className="drop-down__label" onClick={toggleDropDown}>
					{typeof label === "function" ? label() : label}
				</div>
				<div className={`drop-down__body drop-down__body--${positionState}`}>{children}</div>
			</div>
		</>
	);
};

export default DropDown;

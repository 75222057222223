export const formReducer = (state, action) => {
	switch (action.type) {
		case "FILLING": {
			const payload = () => {
				switch (action.payload.type) {
					case "checkbox":
						return action.payload.checked;

					default:
						return action.payload.value;
				}
			};

			// Set object as value if name attribute has []
			let name = action.payload.name;
			let value = payload();
			const matches = name.match(/\[(.*?)\]/);
			if (matches) {
				name = name.slice(0, matches['index']);
				if (action.payload.type === 'checkbox') {
					value = state[name];
					const itemIndex = value.indexOf(action.payload.value);
					if (itemIndex !== -1) {
						value.splice(itemIndex, 1);
					} else {
						value.push(action.payload.value);
					}
				} else {
					value = { ...state[name], [matches[1]]: action.payload.value }
				}
			}

			return {
				...state,
				[name]: value
			};
		}
		case "UPDATE-DEFAULT": {
			return { ...state, ...action.payload }
		}
		default:
			throw new Error();
	}
};

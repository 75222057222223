export const fetchReducer = (state, action) => {
	switch (action.type) {
		case "FETCH-INIT": {
			console.log("FETCH-INIT");
			return {
				...state,
				isLoading: true,
				error: false
			};
		}
		case "FETCH-SUCCESS": {
			console.log("FETCH-SUCCESS");
			return {
				...state,
				data: action.payload,
				isLoading: false
			};
		}
		case "FETCH-FAILURE": {
			console.log("FETCH-FAILURE", action.payload);
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		}
		default:
			throw new Error();
	}
};

/*
const useDataApi = (initialUrl, initialData) => {
  const [url, setUrl] = useState(initialUrl);

  const [state, dispatch] = useReducer(fetchReducer, {
    isLoading: false,
    isError: false,
    data: initialData,
  });

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'FETCH_INIT' });

      try {
        const result = await axios(url);

        dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
      } catch (error) {
        dispatch({ type: 'FETCH_FAILURE' });
      }
    };

    fetchData();
  }, [url]);
*/

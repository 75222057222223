import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.scss';
import AuthProvider from './context/AuthContext';
import UserProvider from './context/UserContext';
import JobProvider from './context/JobContext';
import AuthenticationRouteLayout from './components/router/layouts/AuthenticationRouteLayout/AuthenticationRouteLayout';
import SignIn from './components/auth/SignIn/SignIn';
import Restore from './components/auth/Restore/Restore';
import SetPassword from './components/auth/SetPassword/SetPassword';
import NotFound from './components/router/routes/NotFound/NotFound';
import DefaultPrivateRouteLayout from './components/router/layouts/DefaultPrivateRouteLayout/DefaultPrivateRouteLayout';
import Preloader from './components/partials/Preloader/Preloader';
import JobsRoute from './components/router/routes/JobsRoute/JobsRoute';
import ProfileRoute from './components/router/routes/ProfileRoute/ProfileRoute';
import ClientsRoute from './components/router/routes/ClientsRoute/ClientsRoute';
import SuppliersRoute from './components/router/routes/SuppliersRoute/SuppliersRoute';
import JobRoute from './components/router/routes/JobRoute/JobRoute';
import JobTypesRoute from './components/router/routes/JobTypes/JobTypesRoute';
import NotificationProvider from './context/NotificationContext';
import './i18n';
import LocaleProvider from './context/LocaleContext';

const SearchRoute = React.lazy(() => import('./components/router/routes/SearchRoute/SearchRoute'));
// const JobsRoute = React.lazy(() => import("./components/router/routes/JobsRoute/JobsRoute"));
// const ProfileRoute = React.lazy(() => import("./components/router/routes/ProfileRoute/ProfileRoute"));
// const BillingRoute = React.lazy(() => import("./components/router/routes/BillingRoute/BillingRoute"));
// const UsersRoute = React.lazy(() => import("./components/router/routes/UsersRoute/UsersRoute"));
// const SuppliersRoute = React.lazy(() => import("./components/router/routes/SuppliersRoute/SuppliersRoute"));
// const JobRoute = React.lazy(() => import("./components/router/routes/JobRoute/JobRoute"));

const App = () => {
  return (
    <div className="app-wrapper">
      <Suspense fallback={<Preloader className="preloader--full"/>}>
        <LocaleProvider>
          <AuthProvider>
            <UserProvider>
              <NotificationProvider>
                <JobProvider>
                  <Router>
                    <Switch>
                      <AuthenticationRouteLayout path={['/', '/sign-in']} exact component={SignIn}/>
                      <AuthenticationRouteLayout path="/restore" exact component={Restore}/>
                      <AuthenticationRouteLayout path="/set-password" exact component={SetPassword}/>
                      <DefaultPrivateRouteLayout path="/search" exact component={SearchRoute}/>
                      <DefaultPrivateRouteLayout path="/jobs" exact component={JobsRoute}/>
                      <DefaultPrivateRouteLayout path="/job-types" exact component={JobTypesRoute}/>
                      <DefaultPrivateRouteLayout path="/profile" exact component={ProfileRoute}/>
                      <DefaultPrivateRouteLayout path="/clients" exact component={ClientsRoute}/>
                      <DefaultPrivateRouteLayout path="/suppliers" exact component={SuppliersRoute}/>
                      <DefaultPrivateRouteLayout path="/jobs/:id" exact component={JobRoute}/>
                      <DefaultPrivateRouteLayout path="/users/:id" exact component={ProfileRoute}/>
                      <Route component={NotFound}/>
                    </Switch>
                  </Router>
                </JobProvider>
              </NotificationProvider>
            </UserProvider>
          </AuthProvider>
        </LocaleProvider>
      </Suspense>
    </div>
  );
};

export default App;

import { useState, useEffect } from 'react';
import useAPIService from '../common/useApiService';

export const useDeleteJobType = () => {
  const [id, setId] = useState(null);
  const [deleteJobType, invokeDeleteJobType] = useAPIService(`job-types/${id}`, { method: 'delete' });

  useEffect(() => {
    if (id) {
      invokeDeleteJobType();
    }
  }, [id])

  return [
    deleteJobType,
    {
      deleteJobTypeById: setId
    }
  ];
}
import { useState } from 'react';

export const useFilter = searchQuery => {
  const [query, setQuery] = useState(searchQuery);

  const sortBy = param => {
    const ascSort = `${searchQuery ? searchQuery + '&' : '?'}sort_by=${param}&sort_type=asc`;
    const descSort = `${searchQuery ? searchQuery + '&' : '?'}sort_by=${param}&sort_type=desc`;
    setQuery(prevState => (prevState === ascSort ? descSort : ascSort));
  };

  return [
    query,
    {
      setQuery,
      sortBy
    },
  ]
}
import React from 'react';
import { Link } from 'react-router-dom';
import './Pagination.scss';

const Pagination = ({ meta: { current_page, total, per_page }, links }) => {
  const totalPages = Math.ceil(total / per_page);

  const paginationArray = [];

  if (totalPages <= 5) {
    for (let i = 1; i <= totalPages; i++) {
      paginationArray.push(
        <Link className={`${i === current_page && 'active'}`} to={`/jobs?page=${i}`}>
          {i}
        </Link>,
      );
    }
  }

  if (totalPages > 5) {
    // 1,2,3*...7 - (6-3>=3 ?...)
    if (current_page <= 3) {
      for (let i = 1; i <= 3; i++) {
        paginationArray.push(
          <Link className={`${i === current_page && 'active'}`} to={`/jobs?page=${i}`}>
            {i}
          </Link>,
        );
      }
      paginationArray.push('...');
      paginationArray.push(<Link to={`/jobs?page=${totalPages}`}>{totalPages}</Link>);
    }

    //3,4*,5,...,7 - (4-1,4,)
    if (current_page > 3 && totalPages - current_page > 2) {
      for (let i = current_page - 1; i <= current_page + 1; i++) {
        paginationArray.push(
          <Link className={`${i === current_page && 'active'}`} to={`/jobs?page=${i}`}>
            {i}
          </Link>,
        );
      }
      paginationArray.push('...');
      paginationArray.push(<Link to={`/jobs?page=${totalPages}`}>{totalPages}</Link>);
    }

    //1,...,6,7*,8
    if (current_page > 3 && totalPages - current_page <= 2) {
      paginationArray.push(<Link to={`/jobs?page=1`}>1</Link>);
      paginationArray.push('...');
      for (let i = totalPages - 2; i <= totalPages; i++) {
        paginationArray.push(
          <Link className={`${i === current_page && 'active'}`} to={`/jobs?page=${i}`}>
            {i}
          </Link>,
        );
      }
    }
  }

  return (
    <>
      {totalPages >= 2 && (
        <ul className="pagination">
          {links.prev ? (
            <li>
              <Link to={'jobs?' + links.prev.split('?', 2)[1]}>{'<'}</Link>
            </li>
          ) : null}
          {paginationArray.map((paginationItem, i) => (
            <li key={`paginationItem${i}`}>{paginationItem}</li>
          ))}
          {links.next ? (
            <li>
              <Link to={'jobs?' + links.next.split('?', 2)[1]}>{'>'}</Link>
            </li>
          ) : null}
        </ul>
      )}
    </>
  );
};

export default Pagination;

import React from 'react';
import './ImagePreview.scss';
// import Preloader from "../../partials/Presloader/Preloader";

const ImagePreview = ({
  job_item = undefined,
  value = '',
  className = '',
  onClick = undefined,
  view = false,
  comment = false,
  remove = false,
  controls = true,
  setLoadStateCount = undefined,
  simple = false,
  complex = false,
  superComplex = false,
  disabled = false,
}) => {
  //Handle Load
  const handleLoad = () => {
    if (setLoadStateCount) {
      setLoadStateCount(prevState => ++prevState);
    }
  };

  return (
    <div
      onClick={() => {
        onClick && onClick();
      }}
      className={`img-previews__item ${className} ${onClick ? 'img-previews__item--pointer' : ''} ${(simple && 'img-previews__item--simple') ||
        (complex && 'img-previews__item--complex') ||
        (superComplex && 'img-previews__item--super-complex')}`}
    >
      <div className={`img-previews__controls ${disabled && 'img-previews__controls--disabled'}`} data-test={`${comment}`}>
        {controls && (view || comment || remove || (job_item && job_item.comment)) && (
          <>
            {view && (
              <>
                <div
                  className="img-previews__view icon icon--eyes"
                  onClick={() => {
                    if (! disabled) view(job_item);
                  }}
                ></div>
              </>
            )}
            {(comment || job_item.comment) && (
              <>
                <div
                  className="img-previews__view icon icon--comment"
                  onClick={() => {
                    if (! disabled) comment(job_item);
                  }}
                ></div>
              </>
            )}
            {remove && (
              <>
                <div
                  className="img-previews__view icon icon--trash"
                  onClick={() => {
                    if (! disabled) remove(job_item.id);
                  }}
                ></div>
              </>
            )}
          </>
        )}
        {simple && (
          <>
            <div className="img-previews__view icon icon--simple"></div>
          </>
        )}
        {complex && (
          <>
            <div className="img-previews__view icon icon--complex"></div>
          </>
        )}
        {superComplex && (
          <>
            <div className="img-previews__view icon icon--super-complex"></div>
          </>
        )}
      </div>
      {job_item ? <img onLoad={handleLoad} src={job_item.preview} alt="preview" /> : value}
    </div>
  );
};

export default ImagePreview;

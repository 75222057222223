const getJsonFromUrl = url => {
  var query = url.substr(url.indexOf('?') + 1, url.length);
  var result = {};
  query.split("&").forEach(function(part) {
    var item = part.split("=");
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
}

export default getJsonFromUrl;
import { useContext } from 'react';
import { DispatchNotificationContext } from '../../context/NotificationContext';

export const useShowNotification = () => {
  const DispatchNotification = useContext(DispatchNotificationContext);

  const infoNotification = message => {
    DispatchNotification({
      type: 'ADD-NOTIFICATION-DATA',
      payload: [{
        data: {
          description: message,
        },
        type: 'info',
        isSystem: true,
      }],
    })
  }

  const errorNotification = message => {
    DispatchNotification({
      type: 'ADD-NOTIFICATION-DATA',
      payload: [{
        data: {
          description: message,
        },
        type: 'error',
        isSystem: true,
      }],
    })
  }

  return {
    infoNotification,
    errorNotification,
  }
}
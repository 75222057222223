import { useState, useEffect } from 'react';
import useAPIService from '../common/useApiService';

export const useDeleteUser = () => {
  const [id, setId] = useState(null);
  const [deleteUser, invokeDeleteUser] = useAPIService(`users/${id}`, { method: 'delete' });

  useEffect(() => {
    if (id) {
      invokeDeleteUser();
    }
  }, [id])

  return [
    deleteUser,
    {
      deleteUserById: setId
    }
  ];
}
import React from "react";
import "./InfoBlock.scss";

const InfoBlock = ({ children = "", className = "" }) => {
	return (
		<p className={`info-block ${className}`}>
			<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					opacity="0.5"
					d="M7.2 5.60001H8.8V4.00001H7.2V5.60001ZM8 14.4C4.47119 14.4 1.6 11.5289 1.6 7.99998C1.6 4.4712 4.47119 1.6 8 1.6C11.5288 1.6 14.4 4.4712 14.4 7.99998C14.4 11.5289 11.5288 14.4 8 14.4ZM8 0C3.58159 0 0 3.5816 0 7.99998C0 12.4185 3.58159 16 8 16C12.4184 16 16 12.4185 16 7.99998C16 3.5816 12.4184 0 8 0ZM7.2 12H8.8V7.19998H7.2V12Z"
					fill="black"
				/>
			</svg>
			<span>{children}</span>
		</p>
	);
};

export default InfoBlock;

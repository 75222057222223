import React, { useContext, useState, useEffect } from 'react';
import './JobsRoute.scss';
import { Link } from 'react-router-dom';
import Button from '../../../partials/Button/Button';
import DropDown from '../../../partials/DropDown/DropDown';
import { TableBody, TableCell, TableFooter, TableHeader, TableRow, TableWrapper } from '../../../partials/Table/Table';
import { useApi } from '../../../../hooks/useApi';
import Preloader from '../../../partials/Preloader/Preloader';
import Pagination from '../../../partials/Pagination/Pagination';
import { StateUserContext } from '../.../../../../../context/UserContext';
import { url } from '../../../../constants/url';
import { withTranslation } from 'react-i18next';
import DownloadJobButton from '../../../partials/DownloadJobButton/DownloadJobButton';

const JobsRoute = ({ location: { search }, t }) => {
  const { role } = useContext(StateUserContext);
  //Start filter
  const [filter, setFilter] = useState(t('dashboard.all'));
  const [filterParameter, setFilterParameter] = useState(-1);

  const filterArray = [
    { status: 'draft', text: t('dashboard.draft') }, //0
    { status: 'request', text: t('dashboard.request') }, //1
    { status: 'pricing', text: t('dashboard.pricing') }, //2
    { status: 'approved', text: t('dashboard.approved') }, //3
    { status: 'completed', text: t('dashboard.completed') }, //4
    { status: 'revision', text: t('dashboard.revision') }, //5
    { status: 'archived', text: t('dashboard.archived') }, //6
    { status: 'cancelled', text: t('dashboard.cancelled') }, //7
  ];

  const handleFilterSelect = (status, e) => {
    setFilter(e.target.innerText);
    setFilterParameter(filterArray.indexOf(filterArray.filter(param => param.status === status)[0]));
  };
  //End filter

  //Start getting data
  const [getJobsParams, setGetJobsParams] = useState('&sort_by=updated_at&sort_type=desc'); //{url}?sort_by=name&sort_type=asc (desc)

  const jobsData = useApi(
    url + `jobs${search === '' ? '?' : search}${getJobsParams}${filterParameter !== -1 ? '&status=' + filterParameter : ''}`
  );

  //Start sorting
  const sortBy = (param = 'title') => {
    const params = `&sort_by=${param}&sort_type=asc`;
    const toggleParams = `&sort_by=${param}&sort_type=desc`;
    setGetJobsParams(prevState => (prevState === params ? toggleParams : params));
  };
  //End sorting

  useEffect(() => {
    setFilter(t('dashboard.all'));
  }, [t]);

  return (
    <>
      <section className="section section--default jobs">
        <div className="container">
          <div className="section__top-bar">
            <h1 className="title">{t('dashboard.jobs')}</h1>
            <div className="display--flex">
              <DropDown select={true} className="drop-down--select" label={<Button className="btn--white arrow arrow--bottom">{t('dashboard.show')} {filter}</Button>}>
                <ul className="drop-down__select-list">
                  <li onClick={handleFilterSelect.bind(this, 'all')}>{t('dashboard.all')}</li>
                  {role !== 3 && (
                    <li onClick={handleFilterSelect.bind(this, 'draft')} className="bullet bullet--purple">
                      {t('dashboard.draft')}
                    </li>
                  )}
                  <li onClick={handleFilterSelect.bind(this, 'request')} className="bullet bullet--yellow">
                    {t('dashboard.request')}
                  </li>
                  <li onClick={handleFilterSelect.bind(this, 'pricing')} className="bullet bullet--orange">
                    {t('dashboard.pricing')}
                  </li>
                  <li onClick={handleFilterSelect.bind(this, 'approved')} className="bullet bullet--blue">
                    {t('dashboard.approved')}
                  </li>
                  <li onClick={handleFilterSelect.bind(this, 'completed')} className="bullet bullet--green">
                    {t('dashboard.completed')}
                  </li>
                  <li onClick={handleFilterSelect.bind(this, 'revision')} className="bullet bullet--purple">
                    {t('dashboard.revision')}
                  </li>
                  <li onClick={handleFilterSelect.bind(this, 'archived')} className="bullet bullet--gray">
                    {t('dashboard.archived')}
                  </li>
                  <li onClick={handleFilterSelect.bind(this, 'cancelled')} className="bullet bullet--red">
                    {t('dashboard.cancelled')}
                  </li>
                </ul>
              </DropDown>
              {role === 2 && (
                <Button className="btn--big btn--yellow" to="jobs/create-new">
                  {t('dashboard.create_new_job')}
                </Button>
              )}
            </div>
          </div>

          <div className="section__main-content">
            <TableWrapper className="table--jobs">
              <TableHeader>
                {/* <TableCell>№</TableCell> */}
                <TableCell>
                  <Button
                    className="btn--filter double-arrow double-arrow--right"
                    onClick={() => {
                      sortBy('name');
                    }}
                  >
                    {t('dashboard.name')}
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    className="btn--filter double-arrow double-arrow--right"
                    onClick={() => {
                      sortBy('status');
                    }}
                  >
                    {t('dashboard.status')}
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    className="btn--filter double-arrow double-arrow--right"
                    onClick={() => {
                      sortBy('updated_at');
                    }}
                  >
                    {t('dashboard.last_updated')}
                  </Button>
                </TableCell>
              </TableHeader>
              {!jobsData || jobsData.isLoading || jobsData.data === null ? (
                <Preloader />
              ) : (
                <>
                  <TableBody>
                    {jobsData.data.data.length === 0 &&
                      <h3>
                        {t('dashboard.no_current_jobs') + ' '}
                        {role === 2 && <Link to="jobs/create-new"> {t('dashboard.create_a_new_job')}</Link>}
                      </h3>}
                    {jobsData.data.data.map(jobData => (
                      <TableRow key={jobData.id}>
                        {/* <TableCell>
														<div className="number">job {i}</div>
													</TableCell> */}
                        <TableCell>
                          <Link to={`/jobs/${jobData.id}`}>{jobData.name ? jobData.name : <span className="no-title">{t('job.no_title')}</span>}</Link>
                        </TableCell>
                        <TableCell>
                          <div className={`status status--${filterArray[jobData.status].status}`}>{filterArray[jobData.status].text}</div>
                        </TableCell>
                        <TableCell>{jobData.updated_at}</TableCell>
                        <TableCell>
                          {(filterArray[jobData.status].status === 'approved' && role !== 2) ||
                          filterArray[jobData.status].status === 'completed' ? (
                            <>
                              <DownloadJobButton
                                jobId={jobData.id}
                                onlyPercent={true}
                                className="btn--big btn--yellow-transparent btn--elips btn--uppercase">
                                {t('dashboard.download')}
                              </DownloadJobButton>
                            </>
                          ) : (
                            //   filterArray[jobData.status].toLowerCase() === 'completed' ? (
                            //   <Button
                            //     className="btn--big btn--yellow-transparent btn--elips btn--uppercase">Approved</Button>
                            // ) :
                            ''
                          )}
                        </TableCell>
                        <TableCell className="justify-self--end">
                          <DropDown select={true} className="drop-down--select" label={<div className="icon icon--three-dots icon--right" />}>
                            <ul className="drop-down__select-list">
                              <li>
                                <Link to={`/jobs/${jobData.id}`}>{t('dashboard.view_detail')}</Link>
                              </li>
                            </ul>
                          </DropDown>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter className="wrapper--center">
                    {/* <Button className="btn--big btn--uppercase btn--yellow-transparent">Show all jobs</Button> */}
                    <Pagination meta={jobsData.data.meta} links={jobsData.data.links} />
                  </TableFooter>
                </>
              )}
            </TableWrapper>
          </div>
        </div>
      </section>
    </>
  );
};

export default withTranslation()(JobsRoute);

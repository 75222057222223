import { useEffect } from 'react';
import useAPIService from '../common/useApiService';
import { useFilter } from '../common/useFilter';
import { useTranslation } from 'react-i18next';

export const useUsers = (search = '') => {
  // API call
  const [queryParams, { sortBy }] = useFilter(search);
  const [users, invokeUsers] = useAPIService(`users${queryParams}`);
  const { t } = useTranslation();

  useEffect(() => {
      invokeUsers();
  }, [queryParams, t]);

  return [
    users,
    {
      invokeUsers,
      sortBy,
    }
  ];
}
import React from "react";
import "./Button.scss";
import { Link } from "react-router-dom";

const Button = ({
  children = "",
  onClick = () => {},
  className = "",
  disabled = false,
  active = false,
  type = "button",
  ...attrs
}) => {
  const onClickAction = e => {
    if (disabled) {
      e.preventDefault();
    } else {
      return onClick(e);
    }
  };

  const classes = `btn ${className} ${active ? active : ""}`;
  // const classes = ["btn", className, active ? active : ""];
  // className={classes.join(" ").trim()}

  const Tag = attrs.href ? "a" : "button";
  //add router Link

  return (
    <>
      {!attrs.to ? (
        <Tag
          className={classes.trim()}
          type={type}
          disabled={disabled}
          onClick={onClickAction}
          {...attrs}
        >
          {children}
        </Tag>
      ) : (
        <Link className={classes} {...attrs}>
          {children}
        </Link>
      )}
    </>
  );
};

export default Button;

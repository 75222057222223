/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import './CreateNewJob.scss';
import Field from '../../../partials/Field/Field';
import Select from '../../../partials/Select/Select';
import Checkbox from '../../../partials/Checkbox/Checkbox';
import UploadBox from '../../../partials/UploadBox/UploadBox';
import Button from '../../../partials/Button/Button';
import InfoBlock from '../../../partials/InfoBlock/InfoBlock';
import Modal, { ModalBody, ModalHeader } from '../../../partials/Modal/Modal';
import ImagePreviews from '../../ImagePreviews/ImagePreviews';
import Plupload from 'react-plupload';
import { url } from '../../../../constants/url';
import { StateAuthContext } from '../../../../context/AuthContext';
import { useShowNotification } from '../../../../hooks/notifications/useShowNotification';
import { withTranslation } from 'react-i18next';
import { useCreateJob } from '../../../../hooks/jobs/useCreateJob';
import useForm from '../../../../hooks/common/useForm';
import { useUpdateJob } from '../../../../hooks/jobs/useUpdateJob';
import { useJobTypes } from '../../../../hooks/jobTypes/useJobTypes';
import { StateLocaleContext } from '../../../../context/LocaleContext';
import { useDeleteJobItem } from '../../../../hooks/jobItems/useDeleteJobItem';
import { DispatchJobContext, StateJobContext } from '../../../../context/JobContext';
import errorToString from '../../../../helpers/errorToString';

const CreateNewJob = ({ className = '', isCreating = true, id = '', history, t, ...attr }) => {
  let uploadPlugin = React.createRef();
  const locale = useContext(StateLocaleContext);
  // Job State
  const jobState = useContext(StateJobContext);
  const jobDispatch = useContext(DispatchJobContext);
  const jobItems = jobState.job_items ? jobState.job_items.all : [];
  const defaultFormState = {
    name: jobState.name || '',
    description: jobState.description || '',
    job_types: jobState.job_types.length ? jobState.job_types.map(jt => jt.id) : [],
    urgency: jobState.urgency,
    uploaded_size: jobState.uploaded_size,
    status: 1, // Requested
  }
  const [uploadEvent, setUploadEvent] = useState(null);
  // Auth
  const { access_token } = useContext(StateAuthContext);
  // Notifications
  const { infoNotification, errorNotification } = useShowNotification();
  // Form state
  const [formState, { updateFields, handleChange, ...form }] = useForm(defaultFormState);
  // Job
  const [createJob, invokeCreateJob] = useCreateJob({ status: 0 }); // create job in draft
  const [{ data: updateJobData, isLoading: jobIsLoading }, invokeUpdateJob] = useUpdateJob(createJob.data ? createJob.data.id : jobState.id, formState);
  // Job items
  const [uploadCount, setUploadCount] = useState(0);
  const [imageUploading, setImageUploading] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteJobItem, { deleteJobItemById }] = useDeleteJobItem();
  // Job Types
  const [{ data: jobTypesData, isLoading: jobTypesIsLoading }] = useJobTypes();
  const [jobTypesModalState, setJobTypesModalState] = useState(false);

  // On selected file
  const handleLoadImage = (payload, e) => {
    if (jobItems.length >= 100) {
      errorNotification(t('notifications.error.upload_count'));
      setUploadCount(prevState => ++prevState);
    } else if (jobItems.findIndex(item => item.name === payload.name) !== -1) {
      errorNotification(t('job.same_name'));
      setUploadCount(prevState => ++prevState);
    } else if (formState.uploaded_size >= 1000) {
      errorNotification(t('notifications.error.upload_size'));
      setUploadCount(prevState => ++prevState);
    } else {
      setImageUploading(true);
      uploadPlugin.current.uploader.addFile(payload);
      if (createJob.data || jobState.id) {
        uploadPlugin.current.uploader.setOption('url', `${url}job-items/${createJob.data ? createJob.data.id : jobState.id}`);
        uploadPlugin.current.doUpload(e);
      } else if (! createJob.data && ! createJob.isLoading && uploadPlugin.current.uploader.files.length === 1) {
        setUploadEvent(e);
        invokeCreateJob();
      }
    }
  };

  // File uploaded to server
  const handleFileUploaded = (uploader, file, { response }) => {
    const uploadedImage = JSON.parse(response);
    jobDispatch({ type: 'SET-LOADED-IMAGE', payload: uploadedImage });
    setUploadCount(prevState => ++prevState);

    if (uploader.files.length <= 1) {
      setImageUploading(false);
    }
  };

  // On upload error
  const handleUploadError = (uploader, error) => {
    setUploadCount(prevState => ++prevState);
    const responseError = JSON.parse(error.response).error;
    errorNotification(errorToString(responseError));
    if (uploader.files.length <= 1) {
      setImageUploading(false);
    }
  }

  const handleSubmit = e => {
    e.preventDefault();

    if (
      ! jobTypesData.data.filter(jt => formState.job_types.includes(jt.id) && jt.image_upload).length
      && formState.job_types.length > 0
    ) {
        errorNotification(t('notifications.error.selected_additional_job_type'));
    } else if (formState.job_types.length > 0 && jobItems.length > 0) {
      invokeUpdateJob();
    } else {
      errorNotification(t('notifications.error.all_required'));
    }
  };

  useEffect(() => {
    if (createJob.data && ! createJob.isLoading && ! createJob.error && uploadEvent) {
      uploadPlugin.current.uploader.setOption('url', `${url}job-items/${createJob.data.id}`);
      uploadPlugin.current.doUpload(uploadEvent);
    }
  }, [createJob.data, createJob.isLoading]);

  // On change job items state
  useEffect(() => {
    updateFields({
      uploaded_size: jobItems.length ? jobItems.reduce((accumulator, currentValue) => accumulator + Number(currentValue.size), 0) : 0
    })
  }, [jobItems])

  // Delete job item
  useEffect(() => {
    if (deleteId) {
      deleteJobItemById(deleteId)
    }
  }, [deleteId]);

  useEffect(() => {
    if (deleteJobItem.data !== null && ! deleteJobItem.isLoading && ! deleteJobItem.error) {
      jobDispatch({ type: 'REMOVE-LOADED-IMAGE', payload: deleteId })
      setDeleteId(null);
    } else if (deleteJobItem.error) {
      errorNotification(t('notifications.error.delete_image'))
    }
  }, [deleteJobItem.data, deleteJobItem.isLoading, deleteJobItem.error]);

  useEffect(() => {
    if (updateJobData && updateJobData.id) {
      infoNotification(t('notifications.info.job_created'));
      if (createJob.data) {
        history.push(`/jobs/${updateJobData.id}`);
      } else {
        jobDispatch({ type: 'SET-JOB-DATA', payload: updateJobData })
      }
    }
  }, [updateJobData]);

  return (
    <>
      <div className="container">
        <div className={`section__content job__create-new  ${className}`} {...attr}>
          <div className="job__heading">
            <h1 className="title">{t('dashboard.create_new_job')}</h1>
            <p className="sub-title">{t('job.create_job_description')}</p>
          </div>
          <div className="job__body">
            <form action="" className="form" onSubmit={handleSubmit} autoComplete="false">
              <div className="job__detail">
                <div className="job__detail-item">
                  <div>{t('job.choose_job_name')} <span className="required">*</span></div>
                  <Field type="text" name="name" required placeholder={t('job.job_name_placeholder')} value={formState.name}
                         onChange={handleChange} disabled={jobIsLoading}/>
                </div>

                <div className="job__detail-item">
                  <div>{t('job.type')} <span className="required">*</span></div>
                  <Button
                    className="btn--select"
                    onClick={() => {
                      setJobTypesModalState(true);
                    }}
                    disabled={jobIsLoading}
                  >
                    {t('job.select_type')}
                  </Button>
                  <ul className="selected-options">{jobTypesData && jobTypesData.data.map((item, index) =>
                    formState.job_types.includes(item.id) && <li key={index + item.id}>{item.name}</li>)}
                  </ul>
                </div>

                <div className="job__detail-item">
                  <div>{t('job.description')}</div>
                  <Field
                    tag="textarea"
                    type="text"
                    name="description"
                    placeholder={t('job.description_placeholder')}
                    rows="6"
                    value={formState.description}
                    onChange={handleChange}
                    disabled={jobIsLoading}
                  />
                </div>

                <div className="job__detail-item">
                  <div>{t('job.urgency.urgency_label')}</div>
                  <Select name="urgency" value={formState.urgency} onChange={handleChange} disabled={jobIsLoading}>
                    <option value={0}>{t('job.urgency.urgency_0')}</option>
                    <option value={1}>{t('job.urgency.urgency_50')}</option>
                  </Select>
                </div>
              </div>

              <div className="job__images">
                <Plupload
                  ref={uploadPlugin}
                  onFileUploaded={handleFileUploaded}
                  id="plupload"
                  chunk_size="10mb"
                  headers={{
                    'authorization': `Bearer ${access_token}`,
                    'Accept': 'application/json',
                    'X-Localization': locale,
                  }}
                  onError={handleUploadError}
                  multipart
                  url={`${url}job-items/${createJob.data ? createJob.data.id : null}`}
                />

                <div className="job__detail-item">
                  <div>{t('job.uploaded_images')} <span className="required">*</span></div>
                  <UploadBox
                    setLoadedFiles={handleLoadImage}
                    single={false}
                    disabled={jobIsLoading}
                    uploadCount={uploadCount}
                    setUploadCount={setUploadCount}
                  />
                </div>

                {jobItems && jobItems.length >= 1 ? (
                  <ImagePreviews
                    job_items={jobItems}
                    // updateJobItem={handleUpdateImage}
                    removeJobItem={(id) => { setDeleteId(id) }}
                    maxItems={5}
                    uploadedSize={formState.uploaded_size}
                    view={true}
                    disabled={jobIsLoading || deleteId}
                    remove={true}
                    comment={true}
                  />
                ) : (
                  <InfoBlock>{t('job.upload_max')}</InfoBlock>
                )}
              </div>

              <div>
                <div className="btn-group">
                  <Button
                    className="btn--gray btn--big"
                    onClick={() => { history.push('/') }}
                  >
                    {t('job.back')}
                  </Button>
                  <Button className="btn--yellow btn--big" type="submit" disabled={jobIsLoading || imageUploading}>
                    {jobIsLoading ? t('sign_in.loading') : t('job.create_job')}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Modal state={jobTypesModalState} setState={setJobTypesModalState}>
        <ModalHeader>
          {t('job.select_types_work')} <span
          className="hidden-on-mobile">{jobTypesData !== null && t('job.count_available_types', {'count': jobTypesData.data.length})}</span>
        </ModalHeader>
        <ModalBody className="modal-window__body--single-file-upload">
          <form className="form form--select-job-type">
            {jobTypesData === null && jobTypesIsLoading && t('modals.uploading')}
            {jobTypesData !== null &&
            jobTypesData.data.map((item, index) => (
              <div className="form__item" key={item.id}>
                <div className="form__item__job-type-name">
                  <Checkbox
                    type="checkbox"
                    id={index}
                    value={item.name}
                    inputValue={item.id}
                    checked={formState.job_types.includes(item.id)}
                    name={`job_types[${index}]`}
                    onChange={handleChange}
                  />
                  {item.description && (
                    <div className="question-mark">
                      <span className="question-mark__icon">&#63;</span>
                      <div className="question-mark__description">
                        {item.description}
                      </div>
                    </div>
                  )}
                </div>
                <span className="price">€{item.prices_by_difficulty ? item.prices.map(p => p.price).join('-') : item.price}</span>
              </div>
            ))}
          </form>
          <div className="display--flex justify-content--center">
            <Button
              className="btn--yellow btn--big"
              onClick={() => {
                setJobTypesModalState(false);
              }}
            >
              {t('modals.apply')}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};


export default withTranslation()(CreateNewJob);

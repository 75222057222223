import { useEffect } from 'react';
import useAPIService from '../common/useApiService';

export const useNotifications = () => {
  // API call
  const [notifications, invokeNotifications] = useAPIService(`notifications`);

  // useEffect(() => {
  //     invokeNotifications();
  // }, []);

  return [
    notifications,
    invokeNotifications,
  ];
}
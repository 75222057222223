import React from "react";
import "./Badge.scss";

const Badge = ({
	icon = null,
	counter = null,
	label = null,
	className = "",
	disabled = false,
	active = false,
	...attrs
}) => {
	const classes = `badge ${counter ? "badge--counter" : ""} ${
		label ? "badge--label" : ""
		} ${icon ? "badge--icon badge--yellow" : ""} ${active ? active : ""} ${
		counter !== null && icon ? "badge--gray" : ""
		} ${className}`;

	return (
		<div {...attrs} className={`${classes}`}>
			{icon ? <span className={`icon icon--${icon}`} /> : ""}
			{counter ? (
				<span className="badge__counter wrapper--center">{counter}</span>
			) : (
					""
				)}
			{label ? label : ""}
		</div>
	);
};

export default Badge;

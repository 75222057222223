/* eslint-disable react-hooks/exhaustive-deps */
import React, { useReducer, useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { formReducer } from '../../../reducers/formReducer';
import { useApi } from '../../../hooks/useApi';
import Button from '../../partials/Button/Button';
import Field from '../../partials/Field/Field';
import { url } from '../../../constants/url';
import { useShowNotification } from '../../../hooks/notifications/useShowNotification';
import { Trans, withTranslation } from 'react-i18next';
import { SetLocaleContext } from '../../../context/LocaleContext';

const SetPassword = ({ location: { search }, history: {push}, t }) => {
  //?token=306bf808ff375237af8d1257837e6c1f34fa6cb7ac88a91e0f92bca855a45d79&amp;email=admin%40admin.com

  const setPasswordParameters = search.replace('?', '').split('&');
  const setPasswordToken = setPasswordParameters[0].split('=')[1];
  const setPasswordEmail = setPasswordParameters[1].split('=')[1].replace('%40', '@');
  const locale = setPasswordParameters[2] ? setPasswordParameters[2].split('=')[1] : null;
  const type = setPasswordParameters[3] ? setPasswordParameters[3].split('=')[1] : null;
  const { errorNotification, infoNotification } = useShowNotification();
  const setLocale = useContext(SetLocaleContext);

  //Start form logic
  const initialState = {
    password: '',
    password_confirmation: '',
    token: setPasswordToken,
    email: setPasswordEmail,
    type: type,
  };

  const [formState, formDispatch] = useReducer(formReducer, initialState);

  const handleFilling = e => {
    formDispatch({
      type: 'FILLING',
      payload: e.target,
    });
  };

  //End form logic

  //Start api logic

  const [signInPostData, setSignInPostData] = useState([]);
  const restorePassword = useApi(...signInPostData);
  const { isLoading, data, error } = restorePassword;
  const { password, password_confirmation } = formState;

  const handleSubmit = e => {
    e.preventDefault();

    if (formState.password === formState.password_confirmation) {
      setSignInPostData([
        `${url}resetPassword`,
        {
          method: 'POST',
          api: false,
          payload: JSON.stringify(formState),
        },
      ]);
    } else {
      errorNotification(t('notifications.error.passwords_not_the_same'));
    }
  };

  useEffect(() => {
    if (locale) {
      setLocale(locale);
    }
  }, []);

  useEffect(() => {
    if (!(data === null) && !error && !isLoading) {
      push('/sign-in');
      infoNotification(data.message)
    }
  }, [restorePassword]);

  return (
    <div className="authentication__content">
      <form className="form" onSubmit={handleSubmit}>
        <div className="form__item">
          <Field
            type="password"
            name="password"
            required
            placeholder={t('sign_in.new_password')}
            value={password}
            onChange={handleFilling}
            icon="password"
            autoComplete="off"
          />
        </div>
        <div className="form__item">
          <Field
            type="password"
            name="password_confirmation"
            required
            placeholder={t('sign_in.repeat_new_password')}
            value={password_confirmation}
            onChange={handleFilling}
            icon="password"
            autoComplete="off"
          />
        </div>
        <div className="form__item">
          <Button className="btn--big btn--yellow" type="submit" disabled={isLoading}>
            {isLoading ? t('modals.saving') : t('sign_in.save_password')}
          </Button>
        </div>
      </form>
      <hr />
      <p className="text--center">
        <Trans i18nKey="sign_in.click_here_to">
          Click here to <Link className="link" to="/sign-in">Sign in</Link>.
        </Trans>
      </p>
    </div>
  );
};

export default withTranslation()(SetPassword);

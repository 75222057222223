import { useState, useEffect } from 'react';
import useAPIService from '../common/useApiService';

export const useNotificationsDelete = () => {
  // API call
  const [notificationId, setNotificationId] = useState(null);
  const [deleteNotification, invokeDeleteNotification] = useAPIService(`notifications/delete/${notificationId}`, { method: 'delete' });
  const [deleteAllNotifications, invokeDeleteAllNotifications] = useAPIService('notifications/delete-all', { method: 'delete' });

  useEffect(() => {
    if (notificationId) {
      invokeDeleteNotification();
    }
  }, [notificationId]);

  return [
    {
      deleteNotification,
      deleteAllNotifications,
    },
    {
      deleteById: setNotificationId,
      deleteAll: invokeDeleteAllNotifications,
    },
  ];
}
import React, { useRef } from "react";
import "./Modal.scss";
import Button from "../Button/Button";
import FadeTransition from "../../partials/FadeTransition/FadeTransition";

const ModalHeader = ({ children, className = "" }) => (
	<div className={`modal-window__header ${className}`}>{children}</div>
);

const ModalBody = ({ children, className = "" }) => <div className={`modal-window__body ${className}`}>{children}</div>;

const Modal = ({ state = false, setState = () => {}, className = "", children }) => {
	const dropDownWrapper = useRef(null);

	const handleWrapperClose = e => {
		if (dropDownWrapper.current === e.target) {
			setState(false);
		}
	};

	return (
		<>
			<FadeTransition in={state} unmountOnExit>
				<div
					ref={dropDownWrapper}
					className={`modal-wrapper ${className}`}
					onClick={handleWrapperClose}
				>
					<div className="modal-window">
						<Button
							onClick={() => {
								setState(false);
							}}
							className="btn--close"
						>
							<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M12 1.20857L10.7914 0L6 4.79143L1.20857 0L0 1.20857L4.79143 6L0 10.7914L1.20857 12L6 7.20857L10.7914 12L12 10.7914L7.20857 6L12 1.20857Z"
									fill="black"
								/>
							</svg>
						</Button>
						{children}
					</div>
				</div>
			</FadeTransition>
		</>
	);
};

export default Modal;
export { ModalHeader, ModalBody };

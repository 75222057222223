import React, { createContext, useEffect, useContext, useState } from 'react';
import { StateUserContext } from './UserContext';
import useAPIService from '../hooks/common/useApiService';
import i18n from '../i18n';

export const StateLocaleContext = createContext();
export const SetLocaleContext = createContext();

const LocaleProvider = ({ children }) => {
  const userState = useContext(StateUserContext);
  const [locale, setLocale] = useState(
    localStorage.getItem('lang') || (userState ? userState.lang : 'en')
  );
  const [, invokeChangeLocale] = useAPIService('user/change-locale', {
    method: 'post',
    data: { locale }
  })

  useEffect(() => {
    if (localStorage.getItem('lang') !== locale) {
      localStorage.setItem('lang', locale);
      if (userState && userState.id) {
        invokeChangeLocale();
      }
      i18n.changeLanguage(locale);
    }
  }, [locale]);

  return (
   <SetLocaleContext.Provider value={setLocale}>
     <StateLocaleContext.Provider value={locale}>{children}</StateLocaleContext.Provider>
   </SetLocaleContext.Provider>
  )
}

export default LocaleProvider;
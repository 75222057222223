export const userReducer = (state, action) => {
  switch (action.type) {
    case "SET-USER-DATA": {
      console.log("SET-USER-DATA");
      return {
        ...action.payload
      };
    }
    case "SAVE-USER-DATA": {
      console.log("SAVE-USER-DATA");
      localStorage.setItem("user-data", JSON.stringify(state));
      return state;
    }
    case "DELETE-USER-DATA": {
      console.log("DELETE-USER-DATA");
      localStorage.removeItem("user-data");
      return {};
    }
    case "UPDATE-USER-DATA": {
      console.log("UPDATE-USER-DATA");
      const newState = { ...state, ...action.payload }
      localStorage.setItem("user-data", JSON.stringify(newState));
      return newState;
    }
    default:
      break;
  }
};

import React, { useContext } from "react";
import "./ContentSwitcher.scss";
import { StateJobContext } from "../../../context/JobContext";

const ContentSwitcher = ({ contents = [] }) => {
	const { status } = useContext(StateJobContext);
	return <>{contents[status]}</>;
};

export default ContentSwitcher;

import React, { useState } from 'react';
import Modal, { ModalBody, ModalHeader } from '../partials/Modal/Modal';
import Field from '../partials/Field/Field';
import Button from '../partials/Button/Button';
import LangFormSwitcher from '../partials/LangFormSwitcher/LangFormSwitcher';
import Checkbox from '../partials/Checkbox/Checkbox';
import { withTranslation } from 'react-i18next';

const JobTypeModal = ({ state, error, isLoading, isUpdate, isOpen, setIsOpen, handleSubmit, handleChange, t }) => {
  const { name, description, description_for_supplier, private: isPrivate, image_upload, price, prices, prices_by_difficulty } = state;
  const [updateLang, setUpdateLang] = useState('en');

  return (
    <Modal state={isOpen} setState={setIsOpen}>
      <ModalHeader>
        {isUpdate ? t('job_type.update') : t('job_type.add_new')}
        <LangFormSwitcher value={updateLang} onChange={lang => { setUpdateLang(lang) }} />
      </ModalHeader>
      <ModalBody className="modal-window__body--add-user">
        <form className="form" onSubmit={handleSubmit}>
          <div className="form__item">
            <Field
              type="text"
              value={name[updateLang]}
              onChange={handleChange}
              name={`name[${updateLang}]`}
              placeholder={t('job_type.name')}
              required />
            <span className="field-lang">{updateLang}</span>
          </div>
          <div className="form__item">
            <Field
              type="text"
              value={description[updateLang]}
              onChange={handleChange}
              name={`description[${updateLang}]`}
              tag="textarea"
              rows="6"
              placeholder={t('job_type.description')} />
            <span className="field-lang">{updateLang}</span>
          </div>
          <div className="form__item">
            <Checkbox type="checkbox" name="prices_by_difficulty" id="prices_by_difficulty" value={t('job_type.price_impact')} checked={prices_by_difficulty} onChange={handleChange}/>
          </div>
          {(! prices_by_difficulty) ? (
            <div className="form__item">
              <Field
                type="text"
                value={price}
                onChange={handleChange}
                name="price"
                placeholder={t('job_type.price')} />
            </div>
          ) : (
            <div className="form__row">
              <div className="form__item">
                <Field
                  type="text"
                  value={prices[1]}
                  onChange={handleChange}
                  name="prices[1]"
                  placeholder={t('job_type.simple')} />
              </div>
              <div className="form__item">
                <Field
                  type="text"
                  value={prices[2]}
                  onChange={handleChange}
                  name="prices[2]"
                  placeholder={t('job_type.complex')} />
              </div>
              <div className="form__item">
                <Field
                  type="text"
                  value={prices[3]}
                  onChange={handleChange}
                  name="prices[3]"
                  placeholder={t('job_type.super_complex')} />
              </div>
            </div>
          )}
          <div className="form__item">
            <Field
              type="text"
              value={description_for_supplier}
              onChange={handleChange}
            name="description_for_supplier"
              tag="textarea"
              rows="6"
              placeholder={t('job_type.description_for_supplier')} />
          </div>
          <div className="form__item">
            <Checkbox type="checkbox" name="image_upload" id="image_upload" value={t('job_type.upload_dropzone')} checked={image_upload} onChange={handleChange}/>
          </div>
          <div className="form__item">
            <Checkbox type="checkbox" name="private" id="private" value={t('job_type.is_private')} checked={isPrivate} onChange={handleChange}/>
          </div>
          <div className="display--flex justify-content--center">
            <Button className="btn--big btn--yellow btn" type="submit" disabled={isLoading}>
              {isLoading ? t('sign_in.loading') : (isUpdate ? t('job_type.update_button') : t('job_type.create_button'))}
            </Button>
            <Button
              className="btn--gray btn--big"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              {t('modals.cancel')}
            </Button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
}

export default withTranslation()(JobTypeModal);
import React from "react";
import "./ProgressBar.scss";

const ProgressBar = ({
  current = 0,
  total = 1,
  firstFile = {},
  title = '',
}) => {
  const totalCount = total === 0 ? 1 : total;
  const loadIcon = (
    <svg  width="20px" height="20px" viewBox="0 0 128 128">
      <g>
        <circle cx="16" cy="64" r="16" fill="#f3cd29" fill-opacity="1"/>
        <circle cx="16" cy="64" r="14.344" fill="#f3cd29" fill-opacity="1" transform="rotate(45 64 64)"/>
        <circle cx="16" cy="64" r="12.531" fill="#f3cd29" fill-opacity="1" transform="rotate(90 64 64)"/>
        <circle cx="16" cy="64" r="10.75" fill="#f3cd29" fill-opacity="1" transform="rotate(135 64 64)"/>
        <circle cx="16" cy="64" r="10.063" fill="#f3cd29" fill-opacity="1" transform="rotate(180 64 64)"/>
        <circle cx="16" cy="64" r="8.063" fill="#f3cd29" fill-opacity="1" transform="rotate(225 64 64)"/>
        <circle cx="16" cy="64" r="6.438" fill="#f3cd29" fill-opacity="1" transform="rotate(270 64 64)"/>
        <circle cx="16" cy="64" r="5.375" fill="#f3cd29" fill-opacity="1" transform="rotate(315 64 64)"/>
        <animateTransform attributeName="transform" type="rotate"
                          values="0 64 64;315 64 64;270 64 64;225 64 64;180 64 64;135 64 64;90 64 64;45 64 64"
                          calcMode="discrete" dur="1040ms" repeatCount="indefinite">
      </animateTransform>
      </g>
    </svg>
  )

  return (
    <>
      <div className="progress-bar">
        {totalCount === 1 && firstFile ? (
          <div className="progress-bar__one-file">{loadIcon} { firstFile.name }</div>
        ) : (
          <div className="progress-bar__line-wrapper">
            <div className="progress-bar__line" style={{ width: (current * 100 / totalCount) + '%' }}></div>
            <span>{parseInt(current * 100 / totalCount)} %</span>
          </div>
        )}
      </div>
    </>
  );
};

export default ProgressBar;

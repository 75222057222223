import React, { useContext } from 'react';
import './CompletedJob.scss';
import Button from '../../../partials/Button/Button';
import ImagePreviews from '../../ImagePreviews/ImagePreviews';
import { StateJobContext } from '../../../../context/JobContext';
import { withTranslation } from 'react-i18next';
import DownloadJobButton from '../../../partials/DownloadJobButton/DownloadJobButton';

const CompletedJob = ({ className = '', t, ...attr }) => {
  const jobState = useContext(StateJobContext);

  return (
    <>
      <div className="container">
        <div className={`section__content job__completed  ${className}`} {...attr}>
          <div className="job__heading">
            <div>
              <h1 className="title">
                {t('job.job_completed')}{' '}
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.2 5.60001H8.8V4.00001H7.2V5.60001ZM8 14.4C4.47119 14.4 1.6 11.5289 1.6 7.99998C1.6 4.4712 4.47119 1.6 8 1.6C11.5288 1.6 14.4 4.4712 14.4 7.99998C14.4 11.5289 11.5288 14.4 8 14.4ZM8 0C3.58159 0 0 3.5816 0 7.99998C0 12.4185 3.58159 16 8 16C12.4184 16 16 12.4185 16 7.99998C16 3.5816 12.4184 0 8 0ZM7.2 12H8.8V7.19998H7.2V12Z"
                    fill="black"
                  />
                </svg>
              </h1>
              <p className="sub-title">{t('job.job_completed_client_description')}</p>
            </div>
            <DownloadJobButton jobId={jobState.id} className="btn--big btn--yellow icon icon--left icon--download">
              {t('dashboard.download')}
            </DownloadJobButton>
          </div>
          <div className="job__body">
            <div className="job__images">
              <div className="job__detail-item">
                <div className="content-line ">
                  <span>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle opacity="0.06" cx="16" cy="16" r="16" fill="#05C46B" />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19.3972 13.421C19.6449 13.421 19.7859 13.7043 19.6365 13.9019L19.1638 14.5272L18.7568 15.0626L12.9167 22.7821C12.8027 22.9336 12.5676 22.7919 12.6482 22.6204L12.6797 22.5532L15.3628 16.8821L15.9978 15.536H12.4735C12.2534 15.536 12.1082 15.3069 12.2022 15.1079L15.4783 8.17187C15.5279 8.06694 15.6336 8 15.7496 8H18.0865C18.3066 8 18.4518 8.22919 18.3577 8.4282L15.9978 13.421H19.3972Z"
                        fill="#05C46B"
                      />
                    </svg>
                    {t('job.simple_images')}
                  </span>
                  <span>
                    <small>{jobState.job_items.simple.length} {t('job.images')}</small>
                  </span>
                </div>
                <ImagePreviews
                  className="img-previews--full"
                  job_items={jobState.job_items.simple}
                  maxItems={5}
                  editable={false}
                  resultView={true}
                  uploadedSize={jobState.job_items.simple.reduce((accumulator, currentValue) => accumulator + Number(currentValue[0].size), 0)}
                  jobTypes={jobState.job_types}
                />
              </div>

              <div className="job__detail-item">
                <div className="content-line ">
                  <span>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle opacity="0.06" cx="16" cy="16" r="16" fill="#F3CD29" />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19.3972 13.421C19.6449 13.421 19.7859 13.7043 19.6365 13.9019L19.1638 14.5272L18.7568 15.0626L12.9167 22.7821C12.8027 22.9336 12.5676 22.7919 12.6482 22.6204L12.6797 22.5532L15.3628 16.8821L15.9978 15.536H12.4735C12.2534 15.536 12.1082 15.3069 12.2022 15.1079L15.4783 8.17187C15.5279 8.06694 15.6336 8 15.7496 8H18.0865C18.3066 8 18.4518 8.22919 18.3577 8.4282L15.9978 13.421H19.3972Z"
                        fill="#F3CD29"
                      />
                    </svg>
                    {t('job.complex_images')}
                  </span>
                  <span>
                    <small>{jobState.job_items.complex.length} {t('job.images')}</small>
                  </span>
                </div>

                <ImagePreviews
                  className="img-previews--full"
                  job_items={jobState.job_items.complex}
                  maxItems={5}
                  resultView={true}
                  editable={false}
                  uploadedSize={jobState.job_items.complex.reduce((accumulator, currentValue) => accumulator + Number(currentValue[0].size), 0)}
                  jobTypes={jobState.job_types}
                />
              </div>

              <div className="job__detail-item">
                <div className="content-line ">
                  <span>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle opacity="0.06" cx="16" cy="16" r="16" fill="#F18F1C" />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19.3972 13.421C19.6449 13.421 19.7859 13.7043 19.6365 13.9019L19.1638 14.5272L18.7568 15.0626L12.9167 22.7821C12.8027 22.9336 12.5676 22.7919 12.6482 22.6204L12.6797 22.5532L15.3628 16.8821L15.9978 15.536H12.4735C12.2534 15.536 12.1082 15.3069 12.2022 15.1079L15.4783 8.17187C15.5279 8.06694 15.6336 8 15.7496 8H18.0865C18.3066 8 18.4518 8.22919 18.3577 8.4282L15.9978 13.421H19.3972Z"
                        fill="#F18F1C"
                      />
                    </svg>
                    {t('job.super_complex_images')}
                  </span>
                  <span>
                    <small>{jobState.job_items.super_complex.length} {t('job.images')}</small>
                  </span>
                </div>

                <ImagePreviews
                  className="img-previews--full"
                  job_items={jobState.job_items.super_complex}
                  maxItems={5}
                  resultView={true}
                  editable={false}
                  uploadedSize={jobState.job_items.super_complex.reduce((accumulator, currentValue) => accumulator + Number(currentValue[0].size), 0)}
                  jobTypes={jobState.job_types}
                />
              </div>

              <div className="btn-group">
                <Button className="btn--yellow btn--big" to="/">
                  {t('job.go_to_dashboard')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(CompletedJob);

import { useContext, useEffect, useReducer } from 'react';
import { fetchReducer } from '../reducers/fetchReducer';
import { proxy } from '../constants/proxy';
import { StateAuthContext } from '../context/AuthContext';
import axios from 'axios';
import { DispatchNotificationContext } from '../context/NotificationContext';
import { StateLocaleContext } from '../context/LocaleContext';
import { useTranslation } from 'react-i18next';

export const useApi = (
  url = null,
  { api = true, payload = null, initialData = null, method = 'GET', headers = null, accept = 'application/json', contentType = 'application/json' } = {
    api: true,
    payload: null,
    initialData: null,
    method: 'GET',
    headers: null,
    contentType: 'application/json',
    accept: 'application/json',
  },
) => {
  const { access_token } = useContext(StateAuthContext);
  const DispatchNotification = useContext(DispatchNotificationContext);
  const locale = useContext(StateLocaleContext);
  const [state, dispatch] = useReducer(fetchReducer, {
    isLoading: false,
    error: false,
    data: initialData,
  });
  const { t } = useTranslation();

  useEffect(() => {
    let cancelled = false;
    if (url) {
      const fetchData = async () => {
        dispatch({
          type: 'FETCH-INIT',
        });

        try {
          let response;
          switch ((api, payload)) {
            case !api && !payload:
              response = await axios({
                method: method,
                url: `${proxy}${url}`,
                headers: {
                  'X-Localization': locale,
                }
              });
              break;
            case api && !payload:
              response = await axios({
                method: method, url: `${proxy}${url}`,
                headers: !headers
                  ? {
                    authorization: `Bearer ${access_token}`,
                    'Accept': accept,
                    'X-Localization': locale,
                  }
                  : headers,
              });
              break;
            case api && payload:
              response = await axios({
                method: method,
                url: `${proxy}${url}`,
                headers: !headers
                  ? {
                    authorization: `Bearer ${access_token}`,
                    'Accept': accept,
                    'Content-Type': contentType,
                    'X-Localization': locale,
                  }
                  : {
                    authorization: `Bearer ${access_token}`,
                    'Accept': accept,
                    'Content-Type': contentType,
                    'X-Localization': locale,
                    ...headers,
                  },
                data: payload,
              });
              break;
            case !api && payload:
              response = await axios({
                method: method, url: `${proxy}${url}`,
                headers: !headers
                  ? {
                    'Content-Type': contentType,
                    'X-Localization': locale,
                  }
                  : headers,
                data: payload,
              });
              break;
            default:
              break;
          }

          const data = await response.data;

          if (!cancelled)
            dispatch({
              type: 'FETCH-SUCCESS',
              payload: data,
            });
        } catch (error) {
          let errorMessage = '';
          console.log(error.response);

          if (error.response.data.error === 'invalid_credentials') {
            errorMessage = t('notifications.error.invalid_credentials');
          } else if (error.response && error.response.data.error.code === 'validation_error') {
            const errorFields = Object.values(error.response.data.error.message);
            errorMessage = errorFields.map(fieldError => fieldError).join(' ')
          } else {
            errorMessage = error.response && error.response.data
                            ? error.response.data.error.message || error.response.data.message
                            : null
          }

          DispatchNotification({
            type: 'ADD-NOTIFICATION-DATA',
            payload: [{
              data: {
                description: errorMessage,
              },
              type: 'error',
              isSystem: true,
            }],
          })

          dispatch({
            type: 'FETCH-FAILURE',
            payload: errorMessage,
          });
        }
      };

      fetchData();
    }

    return () => {
      cancelled = true;
    };
  }, [url, accept, access_token, api, payload, method, headers, contentType]);

  return state;
};
const countDownCounter = (countDownDate, countDownInterval = () => {
}) => {

  const countDay = countDownDate.split(' ')[0];
  const countHours = countDownDate.split(' ')[1];
  const countDayParts = countDay.split('.');

  const countDownTime = new Date(`${countDayParts[2]}/${countDayParts[1]}/${countDayParts[0]} ${countHours}`).getTime();
  const now = new Date(new Date().toLocaleString("en-US", {timeZone: "Europe/Brussels"})).getTime();
  const distance = countDownTime - now;

  const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  // If the count down is finished, write some text
  if (distance < 0) {
    clearInterval(countDownInterval);
    return { hours: 0, minutes: 0 };
  }

  return { hours: hours, minutes };
};

export default countDownCounter;

import React, { useContext, useEffect, useState } from 'react';
import './ApprovedJob.scss';
// import Button from "../../partials/Button/Button";
import ImagePreviews from '../../ImagePreviews/ImagePreviews';
import { StateJobContext } from '../../../../context/JobContext';
// import DropDown from "../../partials/DropDown/DropDown"
import countDownCounter from '../../../../helpers/countDownCounter';
import Button from '../../../partials/Button/Button';
import { withTranslation } from 'react-i18next';
import numberToNlFormat from '../../../../helpers/numberToNlFormat';

const ApprovedJob = ({ className = '', t, ...attr }) => {
  const jobState = useContext(StateJobContext);

  // noinspection JSUnusedLocalSymbols
  const { invoice, name, job_items, urgency, uploaded_size, complete_at } = jobState;
  console.log(jobState);
  const {
    sub_total,
    total,
    items: { complex, simple, super_complex },
    urgency_percent,
  } = invoice;

  //Start countdown
  const countDownCounterFormated = (countDownDate, countDownInterval) => {
    const { hours, minutes } = countDownCounter(countDownDate, countDownInterval);
    return `${hours < 10 ? '0' + hours.toString() : hours}:${minutes < 10 ? '0' + minutes.toString() : minutes}`;
  };

  const [countDown, setCountDown] = useState(countDownCounterFormated(complete_at));

  const countDownInterval = setInterval(() => {
    setCountDown(countDownCounterFormated(complete_at, countDownInterval));
  }, 1000);

  useEffect(() => {
    return () => {
      clearInterval(countDownInterval);
    };
  });
  //End countdown

  return (
    <>
      <div className="container">
        <div className={`section__content job__approved  ${className}`} {...attr}>
          <div className="job__heading">
            <div>
              <h1 className="title">{t('job.job_approved')}</h1>
              <p className="sub-title">{t('job.job_approved_client_description')}</p>
            </div>
            <div className="countdown">{countDown}</div>
          </div>
          <div className="job__body">
            <div className="job__detail">
              <div className="job__detail-item">
                <div>{t('job.job_name')}</div>
                <div>{name}</div>
              </div>

              <div className="job__detail-item">
                <div>{t('dashboard.pricing')}</div>
                <div className="content-line ">
                  <span>{t('job.simple_images')}</span>
                  <span>
                    <small>{simple.length} {t('job.images')}</small>
                    <strong>€{simple.length > 0 ? numberToNlFormat(simple.length * simple[0].cost) : 0}</strong>
                  </span>
                </div>
                <div className="content-line ">
                  <span>{t('job.complex_images')}</span>
                  <span>
                    <small>{complex.length} {t('job.images')}</small>
                    <strong>€{complex.length > 0 ? numberToNlFormat(complex.length * complex[0].cost) : 0}</strong>
                  </span>
                </div>
                <div className="content-line ">
                  <span>{t('job.super_complex_images')}</span>
                  <span>
                    <small>{super_complex.length} {t('job.images')}</small>
                    <strong>€{super_complex.length > 0 ? numberToNlFormat(super_complex.length * super_complex[0].cost) : 0}</strong>
                  </span>
                </div>
              </div>

              {urgency && (
                <div className="job__detail-item">
                  <div>{t('job.extra_changes')}</div>
                  <div className="content-line ">
                    <span>{t('job.urgency.12_hours')}</span>
                    <span>
                      {/*<small></small>*/}
                      <strong>+{urgency_percent * 100}%</strong>
                    </span>
                  </div>
                </div>
              )}

              <div className="job__detail-item">
                <div>{t('job.subtotal')}</div>
                <div className="content-line ">
                  <span>{t('job.images')}</span>
                  <span>
                    <small>{simple.length + complex.length + super_complex.length} {t('job.images')}</small>
                    <strong>€{numberToNlFormat(sub_total)}</strong>
                  </span>
                </div>
                <div className="content-line ">
                  <span>{t('job.vat')}</span>
                  <span>
                    <strong>€{numberToNlFormat(total - sub_total)}</strong>
                  </span>
                </div>

                <hr />
                <div className="content-line ">
                  <span>{t('job.total')}</span>
                  <span>
                    <strong>€{numberToNlFormat(total)}</strong>
                  </span>
                </div>
              </div>
            </div>
            <div className="job__images">
              <div className="job__detail-item">
                <div className="content-line ">
                  <span>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle opacity="0.06" cx="16" cy="16" r="16" fill="#05C46B" />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19.3972 13.421C19.6449 13.421 19.7859 13.7043 19.6365 13.9019L19.1638 14.5272L18.7568 15.0626L12.9167 22.7821C12.8027 22.9336 12.5676 22.7919 12.6482 22.6204L12.6797 22.5532L15.3628 16.8821L15.9978 15.536H12.4735C12.2534 15.536 12.1082 15.3069 12.2022 15.1079L15.4783 8.17187C15.5279 8.06694 15.6336 8 15.7496 8H18.0865C18.3066 8 18.4518 8.22919 18.3577 8.4282L15.9978 13.421H19.3972Z"
                        fill="#05C46B"
                      />
                    </svg>
                    {t('job.simple_images')}
                  </span>
                  <span>
                    <small>{job_items.simple.length} {t('job.images')}</small>
                  </span>
                </div>
                <ImagePreviews
                  job_items={job_items.simple}
                  maxItems={2}
                  uploadedSize={job_items.simple.reduce((accumulator, currentValue) => accumulator + Number(currentValue.size), 0) / 1000000}
                  editable={false} />
              </div>

              <div className="job__detail-item">
                <div className="content-line ">
                  <span>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle opacity="0.06" cx="16" cy="16" r="16" fill="#F3CD29" />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19.3972 13.421C19.6449 13.421 19.7859 13.7043 19.6365 13.9019L19.1638 14.5272L18.7568 15.0626L12.9167 22.7821C12.8027 22.9336 12.5676 22.7919 12.6482 22.6204L12.6797 22.5532L15.3628 16.8821L15.9978 15.536H12.4735C12.2534 15.536 12.1082 15.3069 12.2022 15.1079L15.4783 8.17187C15.5279 8.06694 15.6336 8 15.7496 8H18.0865C18.3066 8 18.4518 8.22919 18.3577 8.4282L15.9978 13.421H19.3972Z"
                        fill="#F3CD29"
                      />
                    </svg>
                    {t('job.complex_images')}
                  </span>
                  <span>
                    <small>{job_items.complex.length} {t('job.images')}</small>
                  </span>
                </div>

                <ImagePreviews
                  job_items={jobState.job_items.complex}
                  maxItems={2}
                  uploadedSize={job_items.complex.reduce((accumulator, currentValue) => accumulator + Number(currentValue.size), 0) / 1000000}
                  editable={false} />
              </div>

              <div className="job__detail-item">
                <div className="content-line ">
                  <span>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle opacity="0.06" cx="16" cy="16" r="16" fill="#F18F1C" />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19.3972 13.421C19.6449 13.421 19.7859 13.7043 19.6365 13.9019L19.1638 14.5272L18.7568 15.0626L12.9167 22.7821C12.8027 22.9336 12.5676 22.7919 12.6482 22.6204L12.6797 22.5532L15.3628 16.8821L15.9978 15.536H12.4735C12.2534 15.536 12.1082 15.3069 12.2022 15.1079L15.4783 8.17187C15.5279 8.06694 15.6336 8 15.7496 8H18.0865C18.3066 8 18.4518 8.22919 18.3577 8.4282L15.9978 13.421H19.3972Z"
                        fill="#F18F1C"
                      />
                    </svg>
                    {t('job.super_complex_images')}
                  </span>
                  <span>
                    <small>{job_items.super_complex.length} {t('job.images')}</small>
                  </span>
                </div>

                <ImagePreviews
                  job_items={job_items.super_complex}
                  maxItems={2}
                  uploadedSize={job_items.super_complex.reduce((accumulator, currentValue) => accumulator + Number(currentValue.size), 0) / 1000000}
                  editable={false} />
              </div>
            </div>

            <div className="btn-group">
              <Button className="btn--yellow btn--big" to="/">
                {t('job.go_to_dashboard')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(ApprovedJob);

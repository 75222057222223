/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import ImagePreview from '../ImagePreview/ImagePreview';
import Modal, { ModalBody, ModalHeader } from '../../partials/Modal/Modal';
import Button from '../../partials/Button/Button';
import Field from '../../partials/Field/Field';
import './ImagePreviews.scss';
import { withTranslation } from 'react-i18next';
import { StateUserContext } from '../../../context/UserContext';
import { useCreateItemComment } from '../../../hooks/itemComments/useCreateItemComment';
import { useUpdateItemComment } from '../../../hooks/itemComments/useUpdateItemComment';
import { DispatchJobContext } from '../../../context/JobContext';

const ImagePreviews = ({
  className = 'img-previews--default',
  job_items = [],
  updateJobItem = () => {},
  removeJobItem = () => {},
  uploadedSize = 0,
  maxItems = undefined,
  view = false,
  comment = false,
  remove = false,
  editable = true,
  resultView = false,
  result = false,
  controls = true,
  selectModalButton = '',
  disabled = false,
  t
}) => {
  // General const
  const previewsArray = [];
  const imagesCount = job_items.length;
  const { role } = useContext(StateUserContext);
  const jobDispatch = useContext(DispatchJobContext);
  // Item data
  const [itemState, setItemState] = useState({});
  // Start view preview logic
  const [viewImageModalState, setViewImageModalState] = useState(false);
  const [viewNewImageState, setViewNewImageState] = useState([{}]);
  const [viewNewImageDetailState, setViewNewImageDetailState] = useState({ name: t('job.original_image') });
  // Comments
  const commentData = {
    comment: itemState.comment || null,
    job_item_id: itemState.id,
  }
  const [commentImageModalState, setCommentImageModalState] = useState(false);
  const [commentId, setCommentId] = useState(null);
  const [createItemComment, invokeCreateItemComment] = useCreateItemComment(commentData);
  const [updateItemComment, invokeUpdateItemComment] = useUpdateItemComment(commentId, commentData);
  // Modal previews
  const [allImageModalState, setAllImageModalState] = useState(false);

  const findArrayWithNewImages = (mainArray, childImage) => {
    return mainArray.filter(imgChildArray => {
      const foundedChildArrayItem = imgChildArray.find(imgChildArrayItem => imgChildArrayItem.id === childImage.id);
      if (foundedChildArrayItem) {
        return true;
      } else {
        return false;
      }
    })[0];
  };

  const openViewImageModal = useCallback(
    img => {
      setItemState(img);
      // closeAllPreviewsModal(); // close previews modal
      if (resultView) {
        const resultImages = findArrayWithNewImages(job_items, img);
        setViewNewImageState(resultImages);
      }
      setViewImageModalState(true);
    },
    [resultView, job_items]
  );

  const switchViewImage = (newViewImage, i) => {
    setItemState(newViewImage);
    setViewNewImageDetailState(newViewImage.job_type);
  };
  //End view preview logic

  //Start comment logic
  const handleCommentFilling = e => {
    e.persist();
    setItemState(prevState => ({
      ...prevState,
      comment: e.target.value,
    }));
  };

  const openCommentImageModal = useCallback(img => {
    setItemState({ ...img, comment: img.comments.length ? img.comments[0].comment : '' });
    setCommentId(img.comments.length ? img.comments[0].id : null);
    setCommentImageModalState(true);
  }, []);

  const closeCommentImageModal = () => {
    setCommentImageModalState(false);
  };
  //End comment logic

  //Start remove from general state logic
  const removeImageModal = useCallback(
    id => {
      removeJobItem(id);
    },
    [removeJobItem]
  );
  //End remove from general state logic

  const openAllPreviewsModal = () => {
    setAllImageModalState(true);
  };

  const closeAllPreviewsModal = () => {
    setAllImageModalState(false);
  };
  //End modal previews

  const previewsPopupArray = job_items.map(item => (
    <ImagePreview
      controls={controls}
      disabled={disabled}
      comment={comment || (item.comments && item.comments.length > 0) ? openCommentImageModal : false}
      remove={remove ? removeImageModal : false}
      job_item={resultView || result ? item[0] : item}
      view={resultView ? openViewImageModal : false}
    />
  ));

  useEffect(() => {
    if ((createItemComment.data && ! createItemComment.error) || (updateItemComment.data && ! updateItemComment.error)) {
      jobDispatch({
        type: 'UPDATE-LOADED-IMAGE',
        payload: {
          ...itemState,
          comments: [createItemComment.data ? createItemComment.data : updateItemComment.data],
        },
      })
      setCommentImageModalState(false);
    }
  }, [createItemComment.data, updateItemComment.data])

  //Start all elements without the last
  let i = 0;
  while (i < (maxItems ? maxItems : imagesCount)) {
    if (imagesCount > i) {

      previewsArray.push(
        <ImagePreview
          controls={controls}
          disabled={disabled}
          comment={comment || (job_items[i].comments && job_items[i].comments.length > 0) ? openCommentImageModal : false}
          remove={remove ? removeImageModal : false}
          job_item={resultView || result ? job_items[i][0] : job_items[i]}
          view={resultView ? openViewImageModal : false}
        />
      );
    } else {
      previewsArray.push(<ImagePreview className="img-previews__item--empty" />);
    }
    i++;
  }
  //End all elements without the last

  //Start last element
  if (imagesCount > maxItems) {
    previewsArray.push(<ImagePreview onClick={openAllPreviewsModal} className="img-previews__item--more" value={t('job.more', {'count': imagesCount - maxItems})} />);
  } else {
    previewsArray.push(<ImagePreview className="img-previews__item--empty" />);
  }
  //End last element

  return (
    <>
      <div className={`img-previews ${className} ${resultView && 'img-previews--resultView'}`}>
        {selectModalButton && <div className="img-previews__item-wrapper">{selectModalButton}</div>}
        {previewsArray.map((item, index) => (
          <div className="img-previews__item-wrapper" key={item.id ? item.id : index}>
            {item}
          </div>
        ))}
      </div>
      {imagesCount > 0 && (
        <>
          <Modal state={allImageModalState} setState={closeAllPreviewsModal}>
            <ModalHeader>
              {t('job.uploaded_images')}{' '}
              <span className="hidden-on-mobile ">
                {imagesCount} {t('job.images') + ' '}
                {
                  (uploadedSize < 0.5 ?
                    parseInt(uploadedSize * 1000) + ' kB' :
                    (+uploadedSize).toFixed(2) + ' MB')
                }
              </span>
            </ModalHeader>
            <ModalBody>
              <div className="img-previews img-previews--modal">
                {previewsPopupArray.map((item, index) => (
                  <div className="img-previews__item-wrapper" key={item.id ? item.id : index}>
                    {item}
                  </div>
                ))}
              </div>
            </ModalBody>
          </Modal>
        </>
      )}
      {view && (
        <Modal state={viewImageModalState} setState={setViewImageModalState}>
          <ModalBody className="modal-window__body--img">
            <img src={itemState.original} alt="preview" />
          </ModalBody>
        </Modal>
      )}
      {resultView && (
        <Modal state={viewImageModalState} setState={setViewImageModalState}>
          <ModalHeader className="modal-window__header--rows-on-mobile">
            {t('modals.image_detail')}
            <span>{viewNewImageDetailState ? viewNewImageDetailState.name : t('job.original_image')}</span>
          </ModalHeader>
          <ModalBody className="modal-window__body--img">
            <img src={itemState.original} alt="preview" />
            <div className="preview-switcher">
              {viewNewImageState[0]
                ? viewNewImageState.map((newImage, i) => {
                    return (
                      <img
                        className={newImage.id === itemState.id ? 'active' : ''}
                        onClick={() => {
                          switchViewImage(newImage, i);
                        }}
                        key={'switch' + newImage.id}
                        src={newImage.original}
                        alt="preview"
                      />
                    );
                  })
                : null}
            </div>
          </ModalBody>
        </Modal>
      )}
      {(comment || itemState.comments || itemState.comment) && (
        <Modal state={commentImageModalState} setState={setCommentImageModalState}>
          <ModalHeader>{t('modals.leave_comment')}</ModalHeader>
          <ModalBody>
            <div className="img-comment">
              <div className="img-comment__header">
                <div className="img-comment__preview">
                  <img src={itemState.original} alt="preview" />
                </div>
                <ul className="img-comment__detail">
                  <li>
                    {t('modals.file_name')} <strong>{itemState.name}</strong>
                  </li>
                  <li>
                    {t('modals.file_size')} <strong>{
                      (itemState.size < 0.5 ?
                        parseInt(itemState.size * 1000) + ' kB' :
                        (+itemState.size).toFixed(2) + ' MB')
                    }</strong>
                  </li>
                  <li>
                    {t('modals.file_type')} <strong>{itemState.type}</strong>
                  </li>
                </ul>
              </div>
              <div className="img-comment__body">
                <div className="job__detail-item">
                  <div>{t('modals.comment')}</div>
                  {editable ? (
                    <Field value={itemState.comment} onChange={handleCommentFilling} tag="textarea" placeholder={t('modals.write_comment')} rows="8" />
                  ) : (
                    <>
                      {itemState.comments.map(comment => (
                        <div key={comment.id}>{role === 3 ? (comment.comment_en || comment.comment) : comment.comment}</div>
                      ))}
                      {/*<div>{itemState.comment}</div>*/}
                    </>
                  )}
                </div>
                {editable ? (
                  <Button
                    onClick={() => {
                      commentId ? invokeUpdateItemComment() : invokeCreateItemComment();
                    }}
                    className="btn--yellow btn--big"
                  >
                    {(createItemComment.isLoading || updateItemComment.isLoading) ? t('sign_in.loading') : t('modals.save')}
                  </Button>
                ) : (
                  <Button className="btn--yellow btn--big" onClick={closeCommentImageModal}>
                    {t('modals.close')}
                  </Button>
                )}
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default withTranslation()(ImagePreviews);

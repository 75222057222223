import React, { useState, useEffect } from 'react';
import Button from '../../../partials/Button/Button';
import { TableBody, TableCell, TableFooter, TableHeader, TableRow, TableWrapper } from '../../../partials/Table/Table';
import Preloader from '../../../partials/Preloader/Preloader';
import Pagination from '../../../partials/Pagination/Pagination';
import './JobTypesRoute.scss';
import { useJobTypes } from '../../../../hooks/jobTypes/useJobTypes';
import JobTypeModal from '../../../jobType/JobTypeModal';
import useForm from '../../../../hooks/common/useForm';
import { useGetJobType } from '../../../../hooks/jobTypes/useGetJobType';
import { useUpdateJobType } from '../../../../hooks/jobTypes/useUpdateJobType';
import { useCreateJobType } from '../../../../hooks/jobTypes/useCreateJobType';
import { useShowNotification } from '../../../../hooks/notifications/useShowNotification';
import { withTranslation } from 'react-i18next';
import DropDown from '../../../partials/DropDown/DropDown';
import { useDeleteJobType } from '../../../../hooks/jobTypes/useDeleteJobType';

const defaultState = {
  name: {},
  description: {},
  description_for_supplier: '',
  price: '',
  prices: [],
  prices_by_difficulty: false,
  image_upload: true,
  private: false,
};

const JobTypesRoute = ({ location: { search }, t }) => {
  // Notifications
  const { infoNotification } = useShowNotification();
  // Get Job Types data
  const [jobTypes, { sortBy, invokeJobTypes }] = useJobTypes(search);
  const [state, { updateFormState, handleSubmit, ...form }] = useForm(defaultState);
  //Create job type
  const [createJobType, invokeCreateJobType] = useCreateJobType(state);
  // Update job type
  const [currentId, setCurrentId] = useState(null);
  const [getJobType, invokeGetJobType] = useGetJobType(currentId, { headers: { 'X-Localization': '*' } });
  const [updateJobType, invokeUpdateJobType] = useUpdateJobType(currentId, state);
  // Delete job type
  const [deleteJobType, { deleteJobTypeById }] = useDeleteJobType();
  // Modal
  const [isOpen, setIsOpen] = useState(false);

  // Open modal in create state
  const openCreateModal = () => {
    updateFormState({ data: defaultState });
    setCurrentId(null);
    setIsOpen(true);
  };

  // Open modal in update state
  const openUpdateModal = id => {
    setCurrentId(id);
    if (currentId === id) {
      setIsOpen(true);
    }
  };

  // Invoke getJobType when current jobType id isn't null
  // or set default data for formState
  useEffect(() => {
    if (currentId) {
      invokeGetJobType();
    } else {
      updateFormState({ data: defaultState });
    }
  }, [currentId]);

  // Update form state when getJobType is invoked
  useEffect(() => {
    if (getJobType.data && !getJobType.error && !getJobType.isLoading) {
      setIsOpen(true);

      const prices = getJobType.data.prices;
      const newPrices = {};
      for (const price of prices) {
        newPrices[price.difficulty] = price.price;
      }
      getJobType.data.prices = newPrices;
      updateFormState(getJobType);
    } else {
      updateFormState(getJobType);
    }
  }, [getJobType.data, getJobType.error, getJobType.isLoading]);

  useEffect(() => {
    if (updateJobType.data && ! updateJobType.isLoading && ! updateJobType.error) {
      invokeJobTypes();
      setIsOpen(false);
      infoNotification(t('notifications.info.job_type_updated'));
    }
    updateFormState(updateJobType);
  }, [updateJobType.data, updateJobType.error, updateJobType.isLoading]);

  useEffect(() => {
    if (deleteJobType.data !== null && ! deleteJobType.isLoading && ! deleteJobType.error) {
      invokeJobTypes();
      infoNotification(t('notifications.info.job_type_deleted'));
    }
  }, [deleteJobType.data, deleteJobType.error, deleteJobType.isLoading]);

  useEffect(() => {
    if (createJobType.data && ! createJobType.isLoading && ! createJobType.error) {
      invokeJobTypes();
      setIsOpen(false);
      infoNotification(t('notifications.info.job_type_created'));
    }
    updateFormState(createJobType);
  }, [createJobType.data, createJobType.error, createJobType.isLoading]);

  return (
    <>
      <section className="section section--default companies">
        <div className="container">
          <div className="section__top-bar">
            <h1 className="title">{t('profile.form.job_types_label')}</h1>
            <Button className="btn--big btn--yellow" onClick={openCreateModal}>
              {t('job_type.add_new')}
            </Button>
          </div>
          <div className="section__main-content">
            <TableWrapper className="table--job-types">
              <TableHeader>
                <TableCell>
                  <Button
                    className="btn--filter double-arrow double-arrow--right"
                    onClick={() => {
                      sortBy('name');
                    }}
                  >
                    {t('job_type.name')}
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    className="btn--filter double-arrow double-arrow--right"
                    onClick={() => {
                      sortBy('description');
                    }}
                  >
                    {t('job_type.description')}
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    className="btn--filter double-arrow double-arrow--right"
                    onClick={() => {
                      sortBy('private');
                    }}
                  >
                    {t('job_type.type')}
                  </Button>
                </TableCell>
              </TableHeader>
              {jobTypes.isLoading ? (
                <Preloader/>
              ) : (
                !(jobTypes.data === null) && (
                  <>
                    <TableBody>
                      {jobTypes.data.data.map((jobType, i) => (
                        <TableRow key={jobType.id}>
                          <TableCell>
                            <div className="user-link" onClick={openUpdateModal.bind(this, jobType.id)}>
                              {jobType.name}
                            </div>
                          </TableCell>
                          <TableCell>{jobType.description}</TableCell>
                          <TableCell>
                            <div className={`status status--${jobType.private ? 'yellow' : 'green'}`}>
                              {jobType.private ? t('job_type.private') : t('job_type.public')}
                            </div>
                          </TableCell>
                          <TableCell>
                            <DropDown select={true} className="drop-down--select" label={<div className="icon icon--three-dots icon--right" />}>
                              <ul className="drop-down__select-list">
                                <li onClick={openUpdateModal.bind(this, jobType.id)}>
                                 {t('job_type.edit')}
                                </li>
                                <li className="delete" onClick={() => { if (window.confirm("Are you sure?")) deleteJobTypeById(jobType.id) }}>
                                  {t('job_type.delete')}
                                </li>
                              </ul>
                            </DropDown>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter className="wrapper--center">
                      {/* <Button className="btn--big btn--uppercase btn--yellow-transparent">Show all users</Button> */}
                      <Pagination meta={jobTypes.data.meta} links={jobTypes.data.links}/>
                    </TableFooter>
                  </>
                )
              )}
            </TableWrapper>
          </div>
        </div>
      </section>
      <JobTypeModal
        state={state}
        isUpdate={currentId}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        {...form}
        handleSubmit={handleSubmit.bind(this, currentId ? invokeUpdateJobType : invokeCreateJobType)}/>
    </>
  );
};

export default withTranslation()(JobTypesRoute);
import React, { createContext, useReducer } from 'react';
import { jobReducer } from '../reducers/jobReducer';

export const StateJobContext = createContext();
export const DispatchJobContext = createContext();

const JobProvider = ({ children }) => {
  const initialState = {
    status: 0,
    name: '',
    description: '',
    urgency: 0,
    id: '',
    job_types: [],
    uploaded_size: 0,
    job_items: {
      all: [],
      simple: [],
      complex: [],
      super_complex: [],
    },
  };

  const [jobState, jobDispatch] = useReducer(jobReducer, initialState);

  return (
    <DispatchJobContext.Provider value={jobDispatch}>
      <StateJobContext.Provider value={jobState}>{children}</StateJobContext.Provider>
    </DispatchJobContext.Provider>
  );
};

export default JobProvider;

import React, { useReducer, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../partials/Button/Button';
import Field from '../../partials/Field/Field';
import { formReducer } from '../../../reducers/formReducer';
import { useApi } from '../../../hooks/useApi';
import { url } from '../../../constants/url';
import { useShowNotification } from '../../../hooks/notifications/useShowNotification';
import { Trans, withTranslation } from 'react-i18next';

function Restore({ history: { push }, t }) {
  //Start form logic
  const initialState = {
    email: '',
  };

  const [formState, formDispatch] = useReducer(formReducer, initialState);
  const { infoNotification, errorNotification } = useShowNotification();

  const handleFilling = e => {
    formDispatch({
      type: 'FILLING',
      payload: e.target,
    });
  };
  //End form logic

  //Start api logic

  const [signInPostData, setSignInPostData] = useState([]);
  const restorePassword = useApi(...signInPostData);
  const { isLoading, data, error } = restorePassword;

  const handleSubmit = e => {
    e.preventDefault();
    setSignInPostData([
      `${url}sendResetLinkEmail`,
      {
        method: 'POST',
        api: false,
        payload: JSON.stringify(formState),
      },
    ]);
  };

  useEffect(() => {
    // if (!data && error && !isLoading) {
    //   errorNotification(data.error);
    // }
    if (data && !error && !isLoading) {
      push('/sign-in');
      infoNotification(data.message);
    }
  }, [restorePassword])

  return (
    <div className="authentication__content">
      <form className="form" autoComplete="off" onSubmit={handleSubmit}>
        <div className="form__item">
          <Field
            required
            icon="email"
            name="email"
            onChange={handleFilling}
            value={formState.email}
            type="email"
            required
            placeholder={t('profile.form.email')}
            autoComplete="current-email"
          />
        </div>
        <div className="form__item">
          <Button type="submit" className="btn btn--big btn--yellow">
            {isLoading ? t('sign_in.sending') : t('sign_in.send_reset_link')}
          </Button>
        </div>
      </form>
      <hr />
      <p className="text--center">
        <Trans i18nKey="sign_in.back_to_signin">
          Back to <Link to="/sign-in">Sign in</Link>.
        </Trans>
      </p>
    </div>
  );
}

export default withTranslation()(Restore);

import React, { useContext } from 'react';
import './SupplierCompletedJob.scss';
import Button from '../../../partials/Button/Button';
import ImagePreviews from '../../ImagePreviews/ImagePreviews';
import { StateJobContext } from '../../../../context/JobContext';
import { withTranslation } from 'react-i18next';

const SupplierCompletedJob = ({ className = '', t, ...attr }) => {
  const jobState = useContext(StateJobContext);
  const { name, job_types, description, description_en, urgency} = jobState;

  return (
    <>
      <div className="container">
        <div
          className={`section__content job__requested  ${className}`}
          {...attr}
        >
          <div className="job__heading">

            <h1 className="title">{t('job.awaiting_revision')}</h1>
            <p className="sub-title">{t('job.awaiting_revision_description')}</p>

          </div>
          <div className="job__body">
            <div className="job__detail">
              <div className="job__detail-item">
                <div>{t('job.job_name')}</div>
                <div className="bullet bullet--yellow">{name}</div>
              </div>

              <div className="job__detail-item">
                <div>{t('job.type')}</div>
                {job_types.map(type => (
                  <div key={type.id} className="bullet bullet--yellow">
                    {type.name}
                    {type.description_for_supplier && (
                      <div className="question-mark">
                        <span className="question-mark__icon">&#63;</span>
                        <div className="question-mark__description">
                          {type.description_for_supplier}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>

              {(description_en || description) && (
                <div className="job__detail-item">
                  <div>{t('job.description')}</div>
                  <div className="bullet bullet--yellow">{description_en || description}</div>
                </div>
              )}

              <div className="job__detail-item">
                <div>{t('job.urgency.urgency_label')}</div>
                <div
                  className="bullet bullet--yellow">{urgency ? t('job.urgency.urgency_50') : t('job.urgency.urgency_0')}</div>
              </div>
            </div>
            <div className="job__images">
              <div className="job__detail-item">
                <div>{t('job.uploaded_images')}</div>
              </div>

              <ImagePreviews
                job_items={jobState.job_items.all}
                maxItems={8}
                uploadedSize={jobState.uploaded_size}
                editable={false}
              />
            </div>

            <div className="btn-group">
              <Button className="btn--yellow btn--big" to="/">
                {t('job.go_to_dashboard')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(SupplierCompletedJob);

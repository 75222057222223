import React, { useEffect, useReducer, useState } from 'react';
import Button from '../../../partials/Button/Button';
import { Link } from 'react-router-dom';
import { TableBody, TableCell, TableFooter, TableHeader, TableRow, TableWrapper } from '../../../partials/Table/Table';
import './ClientsRoute.scss';
import Modal, { ModalBody, ModalHeader } from '../../../partials/Modal/Modal';
import Field from '../../../partials/Field/Field';
import { useApi } from '../../../../hooks/useApi';
import Preloader from '../../../partials/Preloader/Preloader';
import Pagination from '../../../partials/Pagination/Pagination';
import { formReducer } from '../../../../reducers/formReducer';
import { url } from '../../../../constants/url';
import { useShowNotification } from '../../../../hooks/notifications/useShowNotification';
import { withTranslation } from 'react-i18next';
import Select from '../../../partials/Select/Select';
import { useDeleteUser } from '../../../../hooks/users/useDeleteUser';
import { useUsers } from '../../../../hooks/users/useUsers';
import DropDown from '../../../partials/DropDown/DropDown';

const initialState = {
  first_name: '',
  last_name: '',
  email: '',
  role: 2,
};

const ClientsRoute = ({ location: { search }, t }) => {
  //Start getting data
  const { infoNotification } = useShowNotification();
  const [formState, formDispatch] = useReducer(formReducer, initialState);
  // Get users
  const [users, { invokeUsers, sortBy }] = useUsers('?role=2');
  // Create user
  const [createClientModalState, setCreateClientModalState] = useState(false);
  const [addClientPostData, setAddClientPostData] = useState([]);
  const addClient = useApi(...addClientPostData);
  // Delete user
  const [deleteUser, { deleteUserById }] = useDeleteUser();

  const handleFormFilling = e => {
    formDispatch({
      type: 'FILLING',
      payload: e.target,
    });
  };

  const handleAddClientSubmit = e => {
    e.preventDefault();
    const postData = {...formState};
    setAddClientPostData([
      `${url}users`,
      {
        method: 'POST',
        payload: JSON.stringify(postData),
      },
    ]);
    formDispatch({ type: 'UPDATE-DEFAULT', payload: initialState });
  };

  useEffect(() => {
    if (!(addClient.data === null) && !addClient.error && !addClient.isLoading) {
      invokeUsers();
      setCreateClientModalState(false);
      infoNotification(t('notifications.info.client_added'));
    }
  }, [addClient]);

  useEffect(() => {
    if (deleteUser.data !== null && ! deleteUser.isLoading && ! deleteUser.error) {
      invokeUsers();
      infoNotification(t('notifications.info.client_deleted'));
    }
  }, [deleteUser.data, deleteUser.isLoading]);


  const { email, first_name, last_name, locale } = formState;

  return (
    <>
      <section className="section section--default clients">
        <div className="container">
          <div className="section__top-bar">
            <h1 className="title">{t('header.clients')}</h1>
            {/* <div className="display--flex"> */}
            <Button
              className="btn--big btn--yellow"
              onClick={() => {
                setCreateClientModalState(true);
              }}
            >
              {t('client.add_new')}
            </Button>
            {/* </div> */}
          </div>
          <div className="section__main-content">
            <TableWrapper className="table--profiles">
              <TableHeader>
                <TableCell>
                  <Button
                    className="btn--filter double-arrow double-arrow--right"
                    onClick={() => {
                      sortBy('first_name');
                    }}
                  >
                    {t('client.name')}
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    className="btn--filter double-arrow double-arrow--right"
                    onClick={() => {
                      sortBy('email');
                    }}
                  >
                    {t('client.email')}
                  </Button>
                </TableCell>
                <TableCell>
                  {/* <Button className="btn--filter double-arrow double-arrow--right">Account Spent</Button> */}
                  <Button
                    className="btn--filter double-arrow double-arrow--right"
                    onClick={() => {
                      sortBy('updated_at');
                    }}
                  >
                    {t('client.updated_data')}
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    className="btn--filter double-arrow double-arrow--right"
                    onClick={() => {
                      sortBy('created_at');
                    }}
                  >
                    {t('client.created_data')}
                  </Button>
                </TableCell>
              </TableHeader>
              {users.isLoading ? (
                <Preloader />
              ) : (
                !(users.data === null) && (
                  <>
                    <TableBody>
                      {users.data.data.map((user, i) => (
                        <TableRow key={user.id}>
                          <TableCell>
                            <Link className="user-link" to={`/users/${user.id}`}>
                              <img className="user-link__avatar" src={user.avatar} alt="" />
                              <span className="user-link__name">
                                {user.first_name} {user.last_name}
                              </span>
                            </Link>
                          </TableCell>
                          <TableCell>{user.email}</TableCell>
                          {/* <TableCell>{user.total_spent}</TableCell> */}
                          <TableCell>{user.updated_at}</TableCell>
                          <TableCell>{user.created_at}</TableCell>
                          <TableCell>
                            <DropDown select={true} className="drop-down--select" label={<div className="icon icon--three-dots icon--right" />}>
                              <ul className="drop-down__select-list">
                                <li className='drop-down__item--edit'>
                                  <Link to={`/users/${user.id}?edit=true`}>
                                    {t('client.edit')}
                                  </Link>
                                </li>
                                <li className="delete" onClick={() => { if (window.confirm("Are you sure?")) deleteUserById(user.id) }}>
                                  {t('job_type.delete')}
                                </li>
                              </ul>
                            </DropDown>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter className="wrapper--center">
                      {/* <Button className="btn--big btn--uppercase btn--yellow-transparent">Show all users</Button> */}
                      <Pagination meta={users.data.meta} links={users.data.links} />
                    </TableFooter>
                  </>
                )
              )}
            </TableWrapper>
          </div>
        </div>
      </section>
      <Modal state={createClientModalState} setState={setCreateClientModalState}>
        <ModalHeader>{t('client.modal.title')}</ModalHeader>
        <ModalBody className="modal-window__body--add-user">
          <form className="form" onSubmit={handleAddClientSubmit}>
            <div className="form__item">
              <Field icon="user" type="text" value={first_name} onChange={handleFormFilling} name="first_name" required placeholder={t('profile.form.first_name')} />
            </div>
            <div className="form__item">
              <Field icon="user" type="text" value={last_name} onChange={handleFormFilling} name="last_name" required placeholder={t('profile.form.last_name')} />
            </div>
            <div className="form__item">
              <Field icon="email" type="email" value={email} onChange={handleFormFilling} name="email" required placeholder={t('profile.form.email')} />
            </div>
            <div className="form__item">
              <Select wrapperClassName="input icon icon--locale" name="locale" value={locale} onChange={handleFormFilling}>
                <option value="en">{t('profile.form.app_language_select.english')}</option>
                <option value="nl">{t('profile.form.app_language_select.dutch')}</option>
                <option value="fr">{t('profile.form.app_language_select.french')}</option>
              </Select>
            </div>
            {!(addClient.data === null) && addClient.data.error && !addClient.isLoading && <p className="form__result form__result--red">{addClient.data.error}</p>}
            <div className="display--flex justify-content--center">
              <Button className="btn--big btn--yellow btn" type="submit" disabled={addClient.isLoading}>
                {addClient.isLoading ? t('sign_in.loading') : t('client.modal.button_add')}
              </Button>
              <Button
                className="btn--gray btn--big"
                onClick={() => {
                  setCreateClientModalState(false);
                }}
              >
                {t('modals.cancel')}
              </Button>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default withTranslation()(ClientsRoute);

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useLayoutEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import checkToken from '../../../../helpers/checkToken';
import { DispatchAuthContext, StateAuthContext } from '../../../../context/AuthContext';
import { proxy } from '../../../../constants/proxy';
import { secretKey } from '../../../../constants/secretKey';
import { clientId } from '../../../../constants/clientId';
import Preloader from '../../../partials/Preloader/Preloader';
import axios from 'axios';
import { url } from '../../../../constants/url';

const PrivateRoute = ({ component: Component, render, ...rest }) => {
  let tokenStatus = checkToken();
  const authState = useContext(StateAuthContext);
  const authDispatch = useContext(DispatchAuthContext);

  //Start refresh token
  const refreshToken = async () => {

    try {
      const updateAccessTokenResponse = await axios({
        url: `${proxy}${url}oauth/token`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          client_secret: secretKey,
          client_id: clientId,
          scope: '',
          grant_type: 'refresh_token',
          refresh_token: authState.refresh_token,
        }),
      });

      // const updateAccessTokenResponse = await fetch(
      //   `${proxy}https://api.wingman.be/oauth/token`,
      //   {
      //     method: 'POST',
      //     headers: {
      //       'Content-Type': 'application/json',
      //     },
      //     body: JSON.stringify({
      //       client_secret: secretKey,
      //       client_id: clientId,
      //       scope: '',
      //       grant_type: 'refresh_token',
      //       refresh_token: authState.refresh_token,
      //     }),
      //   },
      // );

      // const updateAccessTokenData = await updateAccessTokenResponse.json();
      const updateAccessTokenData = await updateAccessTokenResponse.data;

      if (!updateAccessTokenData.error) {
        authDispatch({
          type: 'SET-AUTH-DATA',
          payload: {
            ...updateAccessTokenData,
          },
        });
      } else {
        authDispatch({ type: 'DELETE-AUTH-DATA' });
      }

    } catch (error) {
      authDispatch({ type: 'DELETE-AUTH-DATA' });
    }
  };

  useLayoutEffect(() => {
    tokenStatus === 'refresh' && refreshToken();
    tokenStatus === 'not-valid' && authDispatch({ type: 'DELETE-AUTH-DATA' });
  }, [tokenStatus]);
  //End refresh token

  const renderComponent = props => {
    switch ((tokenStatus, render)) {
      case tokenStatus === 'valid' && render:
        return render(props);
      case tokenStatus === 'valid' && !render:
        return <Component {...props} />;
      case tokenStatus === 'refresh' && render:
        return render(props);
      case tokenStatus === 'refresh' && !render:
        return <Component {...props} />;
      default:
        return (
          <Redirect
            to={{
              pathname: '/',
              state: {
                from: props.location,
              },
            }}
          />
        );
    }
  };

  useEffect(() => {
    tokenStatus = checkToken();
  }, [checkToken]);

  return <>{tokenStatus === 'refresh' ? <Preloader /> : <Route {...rest} render={props => renderComponent(props)} />}</>;
};

export default PrivateRoute;

import React, { useContext } from 'react';
import './SupplierApprovalJob.scss';
import Button from '../../../partials/Button/Button';
import ImagePreviews from '../../ImagePreviews/ImagePreviews';
import { StateJobContext } from '../../../../context/JobContext';
import { withTranslation } from 'react-i18next';

const SupplierApprovalJob = ({ className = '', t, ...attr }) => {
  const jobState = useContext(StateJobContext);
  const { name, job_items, job_types, description, description_en, urgency} = jobState;
  console.log("Job", jobState);
  return (
    <>
      <div className="container">
        <div className={`section__content job__requested  ${className}`} {...attr}>
          <div className="job__heading">
            <h1 className="title">{t('job.quotation_requested')}</h1>
            <p className="sub-title">{t('job.requested_supplier_description')} </p>
          </div>
          <div className="job__body">
            <div className="job__detail">
              <div className="job__detail-item">
                <div>{t('job.job_name')}</div>
                <div className="bullet bullet--yellow">{name}</div>
              </div>

              <div className="job__detail-item">
                <div>{t('job.type')}</div>
                {job_types.map((type) =>
                  <div key={type.id} className="bullet bullet--yellow">
                    {type.name}
                    {type.description_for_supplier && (
                      <div className="question-mark">
                        <span className="question-mark__icon">&#63;</span>
                        <div className="question-mark__description">
                          {type.description_for_supplier}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>

              {(description_en || description) && (
                <div className="job__detail-item">
                  <div>{t('job.description')}</div>
                  <div className="bullet bullet--yellow">{description_en || description}</div>
                </div>
              )}

              <div className="job__detail-item">
                <div>{t('job.urgency.urgency_label')}</div>
                <div
                  className="bullet bullet--yellow">{urgency ? t('job.urgency.urgency_50') : t('job.urgency.urgency_0')}</div>
              </div>
            </div>
            <div className="job__images">
              <div className="job__detail-item">
                <div className="content-line ">
                  <span>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle opacity="0.06" cx="16" cy="16" r="16" fill="#05C46B"/>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19.3972 13.421C19.6449 13.421 19.7859 13.7043 19.6365 13.9019L19.1638 14.5272L18.7568 15.0626L12.9167 22.7821C12.8027 22.9336 12.5676 22.7919 12.6482 22.6204L12.6797 22.5532L15.3628 16.8821L15.9978 15.536H12.4735C12.2534 15.536 12.1082 15.3069 12.2022 15.1079L15.4783 8.17187C15.5279 8.06694 15.6336 8 15.7496 8H18.0865C18.3066 8 18.4518 8.22919 18.3577 8.4282L15.9978 13.421H19.3972Z"
                        fill="#05C46B"
                      />
                    </svg>
                    {t('job.simple_images')}
                  </span>
                  <span>
                    <small>{job_items.simple.length} {t('job.images')}</small>
                  </span>
                </div>
                <ImagePreviews
                  job_items={job_items.simple}
                  uploadedSize={job_items.simple.reduce((accumulator, currentValue) => accumulator + Number(currentValue.size), 0)}
                  maxItems={2}
                  editable={false} />
              </div>

              <div className="job__detail-item">
                <div className="content-line ">
                  <span>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle opacity="0.06" cx="16" cy="16" r="16" fill="#F3CD29"/>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19.3972 13.421C19.6449 13.421 19.7859 13.7043 19.6365 13.9019L19.1638 14.5272L18.7568 15.0626L12.9167 22.7821C12.8027 22.9336 12.5676 22.7919 12.6482 22.6204L12.6797 22.5532L15.3628 16.8821L15.9978 15.536H12.4735C12.2534 15.536 12.1082 15.3069 12.2022 15.1079L15.4783 8.17187C15.5279 8.06694 15.6336 8 15.7496 8H18.0865C18.3066 8 18.4518 8.22919 18.3577 8.4282L15.9978 13.421H19.3972Z"
                        fill="#F3CD29"
                      />
                    </svg>
                    {t('job.complex_images')}
                  </span>
                  <span>
                    <small>{job_items.complex.length} {t('job.images')}</small>
                  </span>
                </div>

                <ImagePreviews
                  job_items={job_items.complex}
                  uploadedSize={job_items.complex.reduce((accumulator, currentValue) => accumulator + Number(currentValue.size), 0)}
                  maxItems={2}
                  editable={false}/>
              </div>

              <div className="job__detail-item">
                <div className="content-line ">
                  <span>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle opacity="0.06" cx="16" cy="16" r="16" fill="#F18F1C"/>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19.3972 13.421C19.6449 13.421 19.7859 13.7043 19.6365 13.9019L19.1638 14.5272L18.7568 15.0626L12.9167 22.7821C12.8027 22.9336 12.5676 22.7919 12.6482 22.6204L12.6797 22.5532L15.3628 16.8821L15.9978 15.536H12.4735C12.2534 15.536 12.1082 15.3069 12.2022 15.1079L15.4783 8.17187C15.5279 8.06694 15.6336 8 15.7496 8H18.0865C18.3066 8 18.4518 8.22919 18.3577 8.4282L15.9978 13.421H19.3972Z"
                        fill="#F18F1C"
                      />
                    </svg>
                    {t('job.super_complex_images')}
                  </span>
                  <span>
                    <small>{job_items.super_complex.length} {t('job.images')}</small>
                  </span>
                </div>

                <ImagePreviews
                  job_items={job_items.super_complex}
                  uploadedSize={job_items.super_complex.reduce((accumulator, currentValue) => accumulator + Number(currentValue.size), 0)}
                  maxItems={2}
                  editable={false}/>
              </div>
            </div>

            <div className="btn-group">
              <Button className="btn--yellow btn--big" to="/">
                {t('job.go_to_dashboard')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(SupplierApprovalJob);

import { useState, useEffect } from 'react';
import useAPIService from '../common/useApiService';

export const useCreateItemComment = data => {
  const [createItemComment, invokeCreateItemComment] = useAPIService(`item-comments`, { method: 'post', data });

  return [
    createItemComment,
    invokeCreateItemComment,
  ];
}
import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import logo from "../../../../assets/images/logo.svg";
import "./AuthenticationRouteLayout.scss";
import Notifications from '../../../notification/Notifications';
import Select from '../../../partials/Select/Select';
import { SetLocaleContext, StateLocaleContext } from '../../../../context/LocaleContext';
import LangSwitcher from '../../../partials/LangSwitcher/LangSwitcher';

const DefaultPrivateRouteLayout = ({ component: Component, ...rest }) => {
	const locale = useContext(StateLocaleContext);
	const setLocale = useContext(SetLocaleContext);

	const conditionalRendering = () => {
		if (localStorage.getItem("auth-data")) return <Redirect to="/jobs" />;
		if (window.location.pathname === "/") return <Redirect to="/sign-in" />;
		return (
			<Route
				{...rest}
				render={props => (
					<>
						<div className="section authentication">
							<div className="notifications-wrapper">
								<Notifications />
							</div>
							<LangSwitcher className="locale-switcher-wrapper" value={locale} onChange={setLocale} />
							{/*<Select wrapperClassName="locale-switcher-wrapper" className="locale-switcher" value={locale} onChange={(e) => setLocale(e.target.value)}>*/}
							{/*	{['en', 'nl', 'fr'].map((locale, index) => <option key={index} value={locale}>{locale}</option>)}*/}
							{/*</Select>*/}
							<div className="authentication__logo">
								<img src={logo} alt="" />
							</div>
							<Component {...props} />
						</div>
					</>
				)}
			/>
		);
	};

	return <>{conditionalRendering()}</>;
};

export default DefaultPrivateRouteLayout;

export const authReducer = (state, action) => {
  switch (action.type) {
    case 'SET-AUTH-DATA': {
      console.log('SET-AUTH-DATA');
      return {
        ...action.payload,
        time_of_set: Date.now(),
      };
    }
    case 'SAVE-AUTH-DATA': {
      console.log('SAVE-AUTH-DATA');
      localStorage.setItem('auth-data', JSON.stringify(state));
      return { ...state, isSave: true };
    }
    case 'DELETE-AUTH-DATA': {
      console.log('DELETE-AUTH-DATA');
      localStorage.removeItem('auth-data');
      return {
        access_token: undefined,
      };
    }
    default:
      break;
  }
};

import React from "react";
import "./Footer.scss";
import { withTranslation } from 'react-i18next';

const Footer = ({t}) => {
	return (
		<footer className="footer">
			<div className="container text--center">
				{t('footer.copyright')}{" "}
				<a target="_blank" href="https://fold.eu">
					FOLD.eu
				</a>
			</div>
		</footer>
	);
};

export default withTranslation()(Footer);

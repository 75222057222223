import React, { useEffect, useRef, useState } from 'react';
import { url } from '../../../../constants/url';
// import dataURLtoFile from '../../../../helpers/dataUrlToFile';

function NotFound() {
  useRef('');
  const [sendRequestState] = useState();

  useEffect(() => {
    if (sendRequestState) {
      (async () => {
        try {
          const response = await fetch(`${url}uploads`, {
            method: 'POST',
            body: sendRequestState,
            headers: {
              // 'Content-Type': 'application/json',
              // 'Accept': 'application/json',
              // 'Content-Type': 'multipart/form-data',
            },
          });
          const result = await response.json();
        } catch (error) {
          console.error('Error:', error);
        }
      })();
    }
  }, [sendRequestState]);



  return (
    <div>
      <h1>404</h1>
      {/*<form action="" className="form" onSubmit={handleSubmit} autoComplete="false">*/}
      {/*  <input ref={inputRef} type="file" multiple={true}/>*/}
      {/*  <button type="submit">submit</button>*/}
      {/*</form>*/}
    </div>
  );
}

export default NotFound;

const checkToken = () => {
  const userData = JSON.parse(localStorage.getItem('auth-data'));
  const timeNow = Date.now();
  if (userData && userData.access_token) {
    if (
      userData.expires_in * 100 + userData.time_of_set - timeNow > 2643488524//30.59days
    ) {
      return 'valid';
    } else {
      if (userData.expires_in * 100 + userData.time_of_set - timeNow <= 0) {
        return 'not-valid';
      } else {
        return 'refresh';
      }
    }
  } else {
    return 'not-set';
  }


};

export default checkToken;

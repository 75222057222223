import React, { useContext } from 'react';
import './StatusSwitcher.scss';
import { StateJobContext } from '../../../context/JobContext';

const StatusSwitcher = ({ statuses = [] }) => {
	const { status } = useContext(StateJobContext);

	//Start development
	// const jobDispatch = useContext(DispatchJobContext)
	// const changeJobStatus = (i) => {
	// 	jobDispatch({
	// 		type: "UPDATE-JOB-DATA",
	// 		payload: { status: i }
	// 	})
	// }
	// const statusesArray = statuses.map((statusItem, i) => {
	// 	const isActive = Array.isArray(statusItem.id) ? Array.from(statusItem.id).find(i => i === status) : status === statusItem.id;
	// 	return (
	// 		<div
	// 			key={`${statusItem.id}${statusItem.name}`}
	// 			className={`job__state ${isActive && "job__state--active"}`}
	// 			onClick={() => { changeJobStatus(i) }}
	// 		>
	// 			<h3 className="title">{statusItem.name}</h3>
	// 			<p className="sub-title">{statusItem.description}</p>
	// 		</div>
	// 	)
	// });
	//End for development

	return (
		<div className="container">
			<div
				className={`section__top-bar job__state-wrapper job__state-wrapper--active-${status} `}
			>
				{statuses.map((statusItem, i) => (
					<div
						key={`${statusItem.id}${statusItem.name}`}
						className={`job__state ${
							Array.isArray(statusItem.id)
								? statusItem.id.indexOf(status) >= 0 && 'job__state--active'
								: status === statusItem.id && 'job__state--active'
						}`}
					>
						<h3 className="title">{statusItem.name}</h3>
						<p className="sub-title">{statusItem.description}</p>
					</div>
				))}
			</div>
		</div>
	);
};

export default StatusSwitcher;

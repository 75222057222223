export const jobReducer = (state, action) => {
  switch (action.type) {
    case 'SET-JOB-DATA':
      return { ...action.payload };
    case 'UPDATE-JOB-DATA':
      return { ...state, ...action.payload };
    case 'SET-LOADED-IMAGE':
      return {
        ...state,
        job_items: {
          ...state.job_items,
          all: state.job_items.all ? [...state.job_items.all, action.payload] : [action.payload],
        },
      };
    case 'SET-UPLOADED-SIZE':
      return { ...state, uploaded_size: action.payload };
    case 'REMOVE-LOADED-IMAGE':
      return {
        ...state,
        job_items: {
          ...state.job_items,
          all: state.job_items.all.filter(img => {
            if (img.id === action.payload) {
              return false;
            }
            return true;
          }),
        },
      };
    case 'UPDATE-LOADED-IMAGE':
      return {
        ...state,
        job_items: {
          ...state.job_items,
          all: state.job_items.all.map(img => {
            if (img.id === action.payload.id) {
              return { ...img, ...action.payload };
            } else {
              return img;
            }
          }),
        },
      };
    case 'UPDATE-LOADED-IMAGE-BY-NAME':
      return {
        ...state,
        job_items: {
          ...state.job_items,
          all: state.job_items.all.map(img => {
            console.log();
            const currentImageUpdateData = action.payload.filter(imgNeedsToUpdate => {
              if (img.name === imgNeedsToUpdate.name) {
                return true;
              } else {
                return false;
              }
            });
            if (currentImageUpdateData) {
              return { ...img, ...currentImageUpdateData[0] };
            } else {
              return img;
            }
          }),
        },
      };
    //Logic with complexity
    case 'SET-SIMPLE-LOADED-IMAGE':
      return {
        ...state,
        job_items: {
          ...state.job_items,
          simple: state.job_items.simple ? [...state.job_items.simple, action.payload] : [action.payload],
        },
      };
    case 'REMOVE-SIMPLE-LOADED-IMAGE':
      return {
        ...state,
        job_items: {
          ...state.job_items,
          simple: state.job_items.simple.filter(img => {
            if (img.id === action.payload) {
              return false;
            }
            return true;
          }),
        },
      };
    case 'SET-COMPLEX-LOADED-IMAGE':
      return {
        ...state,
        job_items: {
          ...state.job_items,
          complex: state.job_items.complex ? [...state.job_items.complex, action.payload] : [action.payload],
        },
      };
    case 'REMOVE-COMPLEX-LOADED-IMAGE':
      return {
        ...state,
        job_items: {
          ...state.job_items,
          complex: state.job_items.complex.filter(img => {
            if (img.id === action.payload) {
              return false;
            }
            return true;
          }),
        },
      };
    case 'SET-SUPER-COMPLEX-LOADED-IMAGE':
      return {
        ...state,
        job_items: {
          ...state.job_items,
          super_complex: state.job_items.super_complex ? [...state.job_items.super_complex, action.payload] : [action.payload],
        },
      };
    case 'REMOVE-SUPER-COMPLEX-LOADED-IMAGE':
      return {
        ...state,
        job_items: {
          ...state.job_items,
          super_complex: state.job_items.super_complex.filter(img => {
            if (img.id === action.payload) {
              return false;
            }
            return true;
          }),
        },
      };

    default:
      return state;
  }
};

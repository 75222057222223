import React from "react";
import "./Table.scss";

const TableWrapper = ({ className = "", children = "Default table", ...attr }) => {
	return (
		<div {...attr} className={`table ${className}`}>
			{children}
		</div>
	);
};

const TableHeader = ({ className = "", children = "", ...attr }) => {
	return (
		<div {...attr} className={`table__header ${className}`}>
			{children}
		</div>
	);
};

const TableBody = ({ className = "", children = "", ...attr }) => {
	return (
		<div {...attr} className={`table__body ${className}`}>
			{children}
		</div>
	);
};

const TableFooter = ({ className = "", children = "", ...attr }) => {
	return (
		<div {...attr} className={`table__footer ${className}`}>
			{children}
		</div>
	);
};

const TableCell = ({ className = "", children = "", ...attr }) => {
	return (
		<div {...attr} className={`table__cell ${className}`}>
			{children}
		</div>
	);
};

const TableRow = ({ className = "", children = "", ...attr }) => {
	return (
		<div {...attr} className={`table__row ${className}`}>
			{children}
		</div>
	);
};

export { TableWrapper, TableHeader, TableCell, TableFooter, TableRow, TableBody };

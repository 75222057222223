import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Link } from 'react-router-dom';
import { DispatchAuthContext } from '../../../context/AuthContext';
import { DispatchUserContext } from '../../../context/UserContext';
import { formReducer } from '../../../reducers/formReducer';
import { useApi } from '../../../hooks/useApi';
import Button from '../../partials/Button/Button';
import Field from '../../partials/Field/Field';
import Checkbox from '../../partials/Checkbox/Checkbox';
import { secretKey } from '../../../constants/secretKey';
import { clientId } from '../../../constants/clientId';
import { url } from '../../../constants/url';
import { withTranslation } from 'react-i18next';

const SignIn = ({ history, t }) => {
  const authDispatch = useContext(DispatchAuthContext);
  const userDispatch = useContext(DispatchUserContext);

  //Start form logic
  const initialState = {
    username: '',
    password: '',
    client_secret: secretKey,
    client_id: clientId,
    scope: '*',
    grant_type: 'password',
    rememberMe: false,
  };

  const [formState, formDispatch] = useReducer(formReducer, initialState);

  const handleFilling = e => {
    formDispatch({
      type: 'FILLING',
      payload: e.target,
    });
  };
  //End form logic

  //Start api logic

  const [signInPostData, setSignInPostData] = useState([]);
  const signIn = useApi(...signInPostData);

  const [userPostData, setUserPostData] = useState([]);
  const user = useApi(...userPostData);

  const handleSubmit = e => {
    e.preventDefault();
    //getting auth
    setSignInPostData([
      url + 'oauth/token',
      {
        method: 'POST',
        payload: JSON.stringify(formState),
      },
    ]);
  };

  useEffect(() => {
    if (signIn.data && !signIn.data.error) {

      if (!user.isLoading && !user.data) {
        setUserPostData([
          url + `user`,
          {
            headers: {
              authorization: `Bearer ${signIn.data.access_token}`,
            },
          },
        ]);
      }

      if (user.data && !user.data.error) {
        userDispatch({
          type: 'SET-USER-DATA',
          payload: { ...user.data.data },
        });

        setUserPostData([]);

        authDispatch({
          type: 'SET-AUTH-DATA',
          payload: { ...signIn.data },
        });

        setSignInPostData([]);

        history.push('/jobs');
      }
    }
  }, [signIn, history, authDispatch, user, userDispatch]);

  const [isLoading, setIsLoading] = useState(false);

  if (!isLoading && signIn.isLoading) {
    setIsLoading(true);
  }

  if (isLoading && !signIn.isLoading && (signIn.data || signIn.error)) {
    setIsLoading(false);
  }

  //End api logic
  const { username, password, rememberMe } = formState;

  return (
    <div className="authentication__content">
      <form className="form" onSubmit={handleSubmit}>
        <div className="form__item">
          <Field
            type="email"
            name="username"
            required
            placeholder={t('profile.form.email')}
            value={username}
            onChange={handleFilling}
            autoComplete="current-email"
            icon="email"
          />
        </div>
        <div className="form__item">
          <Field
            type="password"
            name="password"
            required
            placeholder={t('profile.form.password')}
            value={password}
            onChange={handleFilling}
            autoComplete="current-password"
            icon="password"
          />
        </div>
        <div className="form__item">
          <Checkbox type="checkbox" name="rememberMe" id="remember-me" value={t('sign_in.remember_me')} checked={rememberMe}
                    onChange={handleFilling}/>
          <Link className="link link--dark" to="/restore">
            {t('sign_in.forgot_password')}
          </Link>
        </div>
        <div className="form__item">
          <Button className="btn--big btn--yellow" type="submit" disabled={isLoading}>
            {isLoading ? t('sign_in.loading') : t('sign_in.sign_in_button')}
          </Button>
        </div>
        {/*{!signIn.isLoading && signIn.error && <p className="form__result form__result--red">{signIn.error}</p>}*/}
      </form>
      <hr/>
      <p className="text--center">
        {t('sign_in.dont_have_account')}{' '}
        <a className="link" href="mailto:timur@fold.eu">
          {t('sign_in.contact_us')}
        </a>
        {/* <Link className="link" to="/sign-up">
					Sign Up
				</Link> */}
      </p>
    </div>
  );
};

export default withTranslation()(SignIn);

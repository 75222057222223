import React, { useContext, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { DispatchAuthContext } from '../../../context/AuthContext';
import { DispatchUserContext } from '../../../context/UserContext';
// import { useApi } from "../../../hooks/useApi";
import Field from '../../partials/Field/Field';
import Badge from '../../partials/Badge/Badge';
import logo from '../../../assets/images/logo.svg';
import './Header.scss';
import DropDown from '../../partials/DropDown/DropDown';
import { StateUserContext } from '../../../context/UserContext';
import Notifications from '../../notification/Notifications';
import { StateNotificationContext } from '../../../context/NotificationContext';
import { Trans, withTranslation } from 'react-i18next';
import LangFormSwitcher from '../../partials/LangFormSwitcher/LangFormSwitcher';
import Select from '../../partials/Select/Select';
import { SetLocaleContext, StateLocaleContext } from '../../../context/LocaleContext';
import LangSwitcher from '../../partials/LangSwitcher/LangSwitcher';

const Header = ({ history, t }) => {
  const { role } = useContext(StateUserContext);

  //Mobile menu start
  const [mobileMenu, setMobileMenu] = useState(false);
  const menuActiveClass = mobileMenu ? 'active' : '';
  //Mobile menu end

  //Log out start (mb will be better to move it to the separate component)

  const authDispatch = useContext(DispatchAuthContext);
  const userDispatch = useContext(DispatchUserContext);
  // Lang switcher
  const locale = useContext(StateLocaleContext);
  const setLocale = useContext(SetLocaleContext);

  const handleLogOut = e => {
    e.preventDefault();
    userDispatch({
      type: 'DELETE-USER-DATA',
    });
    authDispatch({
      type: 'DELETE-AUTH-DATA',
    });
  };
  //Log out end

  //Start notification
  const notificationCounter = useContext(StateNotificationContext).filter(n => ! n.isSystem && ! n.isPush).length;
  const [showNotification, setShowNotification] = useState(false);

  const handleShowNotification = () => {
    setShowNotification(true);
  };
  //End notification

  //Start search
  const [searchFieldState, setSearchFieldState] = useState('');

  const handleSearchFill = e => {
    setSearchFieldState(e.target.value);
  };

  const handleSearchSubmit = e => {
    e.preventDefault();
    setMobileMenu(false);
    history.push(`/search?name=${searchFieldState}&description=${searchFieldState}`);
  };
  //End search
  return (
    <>
      <header className="header">
        <div className="beta-version">
          <div className="container">
            <Trans i18nKey="header.beta">
              This is a beta test version. Please report issues to <a href="mailto:support@wingman.be">support@wingman.be</a>
            </Trans>
          </div>
        </div>
        <div className="container display--flex justify-content--space-between align-items--center">
          <div className="header__logo">
            <Link to="/">
              <img src={logo} alt=""/>
            </Link>
          </div>
          <div className="desktop-menu hidden-on-mobile">
            <form className="form" onSubmit={handleSearchSubmit}>
              <Field icon="search" required placeholder={t('header.search')} value={searchFieldState} onChange={handleSearchFill}/>
            </form>
            <LangSwitcher value={locale} onChange={setLocale} />
            <Badge counter={notificationCounter} icon="bell-black" className="badge--pointer"
                   onClick={handleShowNotification}/>

            <DropDown select={true} label={<Badge icon="user-black"/>}>
              <nav className="navigation">
                <ul>
                  <li>
                    <NavLink className="navigation__item arrow arrow--right" to="/">
                      {t('header.dashboard')}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="navigation__item arrow arrow--right" to="/profile">
                      {t('header.profile')}
                    </NavLink>
                  </li>
                  {role === 1 && (
                    <>
                      <li>
                        <NavLink className="navigation__item arrow arrow--right" to="/job-types">
                          {t('header.job_types')}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink className="navigation__item arrow arrow--right" to="/clients">
                          {t('header.clients')}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink className="navigation__item arrow arrow--right" to="/suppliers">
                          {t('header.suppliers')}
                        </NavLink>
                      </li>
                    </>
                  )}
                  <li>
                    <span className="navigation__item" onClick={handleLogOut}>
                      {t('header.log_out')}
                    </span>
                  </li>
                </ul>
              </nav>
            </DropDown>
          </div>
          <button
            className={`header__burger burger hidden-on-desktop ${menuActiveClass}`}
            onClick={() => {
              setMobileMenu(!mobileMenu);
            }}
          >
            <span/>
            <span/>
            <span/>
          </button>
        </div>
      </header>
      <div className="container notifications-wrapper">
        <Notifications showNotificationBtn={showNotification} setShowNotificationBtn={setShowNotification}/>
      </div>
      <div className={`mobile-menu ${menuActiveClass}`}>
        <form className="form" onSubmit={handleSearchSubmit}>
          <Field icon="search" required placeholder="Search" value={searchFieldState} onChange={handleSearchFill}/>
        </form>
        <nav className="navigation">
          <ul>
            <li
              onClick={() => {
                setMobileMenu(!mobileMenu);
              }}
            >
              <NavLink className="navigation__item arrow arrow--right" to="/">
                {t('header.dashboard')}
              </NavLink>
            </li>
            <li
              onClick={() => {
                setMobileMenu(!mobileMenu);
              }}
            >
              <NavLink className="navigation__item arrow arrow--right" to="/profile">
                {t('header.profile')}
              </NavLink>
            </li>
            {role === 1 && (
              <>
                <li
                  onClick={() => {
                    setMobileMenu(!mobileMenu);
                  }}
                >
                  <NavLink className="navigation__item arrow arrow--right" to="/job-types">
                    {t('header.job_types')}
                  </NavLink>
                </li>
                <li
                  onClick={() => {
                    setMobileMenu(!mobileMenu);
                  }}
                >
                  <NavLink className="navigation__item arrow arrow--right" to="/clients">
                    {t('header.clients')}
                  </NavLink>
                </li>
                <li
                  onClick={() => {
                    setMobileMenu(!mobileMenu);
                  }}
                >
                  <NavLink className="navigation__item arrow arrow--right" to="/suppliers">
                    {t('header.suppliers')}
                  </NavLink>
                </li>
                {/*<li*/}
                {/*  onClick={() => {*/}
                {/*    setMobileMenu(!mobileMenu);*/}
                {/*  }}*/}
                {/*>*/}
                {/*  /!*<NavLink className="navigation__item arrow arrow--right" to="/companies">*!/*/}
                {/*  /!*  Companies*!/*/}
                {/*  /!*</NavLink>*!/*/}
                {/*</li>*/}
              </>
            )}
            <li>
              <Badge className="navigation__item" counter={notificationCounter} onClick={() => {
                setMobileMenu(!mobileMenu);
                handleShowNotification();
              }} label={<span className="label">{t('header.notifications')}</span>}/>
            </li>
            <li>
              <span className="navigation__item" onClick={handleLogOut}>
                {t('header.log_out')}
              </span>
            </li>
            <li>
              <LangFormSwitcher className="locale-switcher--mobile" value={locale} onChange={lang => { setMobileMenu(false); setLocale(lang) }} />
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default withTranslation()(Header);

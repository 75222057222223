import { useContext, useEffect, useState } from 'react';
import { StateUserContext } from '../../context/UserContext';
import { StateAuthContext } from '../../context/AuthContext';
import io from 'socket.io-client';
import Echo from 'laravel-echo';

const useSocket = () => {
  const userState = useContext(StateUserContext);
  const authState = useContext(StateAuthContext);
  const [echo, setEcho] = useState(null);
  window.io = io;

  useEffect(() => {
    if (authState && userState && authState.access_token && userState.id) {
      setEcho(new Echo({
        broadcaster: 'socket.io',
        // host: window.location.hostname + ':6001',
        host: process.env.REACT_APP_ECHO_HOST,
        rejectUnauthorized: false,
        sslCertPath: process.env.REACT_APP_ECHO_SSL_CRT,
        sslKeyPath: process.env.REACT_APP_ECHO_SSL_KEY,
        auth: {
          headers: {
            Authorization: 'Bearer ' + authState.access_token
          },
        },
      }));
    }

    return () => {
      if (echo) echo.disconnect();
    }
  }, [authState]);

  return [
    echo,
    setEcho
  ];
}

export default useSocket;
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { formReducer } from '../../../../reducers/formReducer';
import Modal, { ModalBody, ModalHeader } from '../../../partials/Modal/Modal';
import Button from '../../../partials/Button/Button';
import Field from '../../../partials/Field/Field';
import Select from '../../../partials/Select/Select';
import UploadBox from '../../../partials/UploadBox/UploadBox';
import { useApi } from '../../../../hooks/useApi';
import { DispatchUserContext, StateUserContext } from '../.../../../../../context/UserContext';
import './ProfileRoute.scss';
import Preloader from '../../../partials/Preloader/Preloader';
import dataURLtoFile from '../../../../helpers/dataUrlToFile';
import { url } from '../../../../constants/url';
import ModalSelect from '../../../partials/ModalSelect/ModalSelect';
import { useJobTypes } from '../../../../hooks/jobTypes/useJobTypes';
import { useShowNotification } from '../../../../hooks/notifications/useShowNotification';
import { Trans, withTranslation } from 'react-i18next';

const ProfileRoute = ({
  match: {
    params: { id },
  },
  location: { search },
  history,
  t
}) => {
  //Start general state
  const editing = search === '?edit=true';
  const creating = search === '?new=true';
  const [editState, setEditState] = useState(editing || creating ? true : false);
  const { role } = useContext(StateUserContext);
  const userDispatch = useContext(DispatchUserContext);
  const user = useContext(StateUserContext);
  const [jobTypes] = useJobTypes('?private=1');
  const { errorNotification } = useShowNotification();
  // const [editState, setEditState] = useState(location.search === "?edit=true" ? true : false);
  //End general state

  //Start form logic
  const userFormInitialState = {
    first_name: '',
    last_name: '',
    avatar: '',
    email: '',
    phone: '',
    address: '',
    balance: '',
    invoice: false,
    currency: 'eur',
    locale: 'eng',
    tl_company_id: '',
    password: '',
    password_confirmation: '',
  };

  const companyFormInitialState = {
    id: null,
    name: '',
    email: '',
    vat_code: '',
    telephone: '',
    country: '',
    zipcode: '',
    city: '',
    street: '',
    number: '',
  };

  const [userDetailFormState, userDetailFormDispatch] = useReducer(formReducer, userFormInitialState);
  const [companyDetailFormState, companyDetailFormDispatch] = useReducer(formReducer, companyFormInitialState);
  const { infoNotification } = useShowNotification();

  const handleUserFilling = e => {
    userDetailFormDispatch({
      type: 'FILLING',
      payload: e.target,
    });

    if (e.target.name === 'tl_company_id') {
      if (e.target.value) {
        setCompanyGetData(`${url}companies/${e.target.value}`);
      } else {
        setCompanyGetData('');
      }
    }
  };

  // const handleCompanyFilling = e => {
  //   companyDetailFormDispatch({
  //     type: 'FILLING',
  //     payload: e.target,
  //   });
  // };
  //End form logic

  //Start getting data

  //Start getting the user data
  const getUser = useApi(`${url}users/${id ? id : user.id}`);

  useEffect(() => {
    if (!(getUser.data === null)) {
      userDetailFormDispatch({
        type: 'UPDATE-DEFAULT',
        payload: getUser.data.user,
      });

      companyDetailFormDispatch({
        type: 'UPDATE-DEFAULT',
        payload: {
          company_list: { ...getUser.data.companies },
          ...getUser.data.company_detail,
        },
      });
    }
  }, [getUser]);

  //End getting the user data

  //Start getting the company data

  //Start getting data
  const [companyGetData, setCompanyGetData] = useState('');

  const getCompany = useApi(companyGetData);

  useEffect(() => {
    if (!(getCompany.data === null) && !getCompany.isLoading) {
      companyDetailFormDispatch({
        type: 'UPDATE-DEFAULT',
        payload: {...companyFormInitialState, ...getCompany.data },
      });
      setCompanyGetData('');
    }
  }, [getCompany]);

  //End getting data

  //End getting the company data

  //End getting data

  //Start sending data
  const [profilePostData, setProfilePostData] = useState([]);
  const sendProfile = useApi(...profilePostData);

  //Start validation
  const [validationMassage, setValidationMassage] = useState(null);
  //End validation

  const handleSubmit = e => {
    e.preventDefault();
    if (userDetailFormState.password !== userDetailFormState.password_confirmation) {
      errorNotification(t('notifications.error.passwords_not_the_same'));
    } else {
      setValidationMassage(false);
      // const userDetailFormStateCopy = userDetailFormState;
      // delete userDetailFormStateCopy.company_id;
      setProfilePostData([
        `${url}users/${id ? id : user.id}`,
        {
          method: 'PUT',
          payload: JSON.stringify(userDetailFormState),
        },
      ]);
    }
  };

  useEffect(() => {
    if (!(sendProfile.data === null) && !sendProfile.error && !sendProfile.isLoading) {
      if (!validationMassage) {
        //update user in local storage
        userDispatch({
          type: 'UPDATE-USER-DATA',
          payload: sendProfile.data.data,
        });
        setEditState(false);
        infoNotification(t('notifications.info.user_saved'));
      }
    }
  }, [sendProfile, history, validationMassage, userDispatch]);

  //End sending data

  //Start upload avatar
  const [uploadImageModalState, setUploadImageModalState] = useState(false);
  const [avatarItem, setAvatarItems] = useState({}); // avatar upload
  const [avatarPostData, setAvatarPostData] = useState([]);
  const uploadAvatar = useApi(...avatarPostData);

  // const uploadImageFormData = new FormData();

  // uploadImageFormData.append("image_data", jobItems.data);

  const handleAvatarUpload = e => {
    e.preventDefault();
    const avatarFormData = new FormData();
    avatarFormData.append('image', dataURLtoFile(avatarItem.original, avatarItem.name, avatarItem.type));
    setAvatarPostData([
      `${url}users/${id ? id : user.id}/avatar`,
      {
        method: 'POST',
        payload: avatarFormData,
      },
    ]);
    //fetch upload
    setAvatarItems(prevState => ({ ...prevState, is_upLoaded: true }));
  }

  useEffect(() => {
    if (!(uploadAvatar.data === null) && !uploadAvatar.error && !uploadAvatar.isLoading) {
      userDetailFormDispatch({
        type: 'UPDATE-DEFAULT',
        payload: { avatar: uploadAvatar.data.avatar },
      });
      setUploadImageModalState(false);
      setAvatarItems({});
    }
  }, [uploadAvatar, userDetailFormDispatch]);
  //End upload avatar

  //Start parsing data
  //Start company
  const companyArray = [];

  for (let property in companyDetailFormState.company_list) {
    companyArray.push({
      id: property,
      title: companyDetailFormState.company_list[property],
    });
  }

  const companyDataIsLoading = getCompany.isLoading;
  const companyDataData = getCompany.data;
  // const companyDataError = getCompany.error;
  //End company

  //Start form
  const sendProfileIsLoading = sendProfile.isLoading;
  const sendProfileData = sendProfile.data;
  const sendProfileError = sendProfile.error;
  //End form

  //Start upload
  const uploadingAvatarIsLoading = uploadAvatar.isLoading;

  const uploadingAvatarError = uploadAvatar.error;
  //End upload
  //End parsing data

  return (
    <>
      <section className="section section--default profile">
        {getUser.isLoading ? (
          <Preloader />
        ) : (
          !(getUser.data === null) && (
            <div className="container">
              <div className="profile__row">
                <div className="profile__sidebar">
                  <div className="avatar">
                    <img src={userDetailFormState.avatar} alt="" />
                    <Button
                      onClick={() => {
                        setUploadImageModalState(true);
                      }}
                    >
                      <svg width="12" height="12" viewupload-box="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M0 9.50525V12H2.49475L9.5952 4.89955L7.10045 2.4048L0 9.50525ZM11.8021 2.70065C12.066 2.43678 12.066 2.02099 11.8021 1.75712L10.2429 0.197901C9.97901 -0.065967 9.56322 -0.065967 9.29935 0.197901L7.996 1.50925L10.4908 4.004L11.8021 2.70065V2.70065Z"
                          fill="#F3CD29"
                        />
                      </svg>
                    </Button>
                  </div>
                  {editState && (
                    <div className="instruction">
                      <h6 className="title">
                        <Trans i18nKey="profile.sidebar.fill_form" />
                      </h6>
                      <p>{t('profile.sidebar.add_info')}</p>
                      <a href="mailto:timur@fold.eu">{t('profile.sidebar.need_help')}</a>
                    </div>
                  )}
                </div>
                <div className={`profile__detail ${editState && 'profile__detail--editing'}`}>
                  <form className="form" onSubmit={handleSubmit}>
                    <div className="section__top-bar justify-content--space-between">
                      <h1 className="title">
                        {userDetailFormState.first_name} {userDetailFormState.last_name}
                      </h1>
                      {editState ? (
                        <div>
                          <Button className="btn--big btn--yellow" type="submit">
                            {sendProfileIsLoading ? t('modals.saving') : t('modals.save')}
                          </Button>
                        </div>
                      ) : (
                        <Button
                          className="btn--big btn--yellow"
                          onClick={() => {
                            setEditState(true);
                            setProfilePostData([]);
                          }}
                        >
                          {t('profile.edit_profile')}
                        </Button>
                      )}
                    </div>

                    <div className="profile__detail-group">
                      {editState && (
                        <>
                          <div className="profile__detail-item">
                            <div>{t('profile.form.first_name')}</div>
                            <div>
                              <Field
                                type="text"
                                name="first_name"
                                required
                                placeholder={t('profile.form.first_name')}
                                value={userDetailFormState.first_name}
                                onChange={handleUserFilling}
                              />
                            </div>
                          </div>
                          <div className="profile__detail-item">
                            <div>{t('profile.form.last_name')}</div>
                            <div>
                              <Field
                                type="text"
                                name="last_name"
                                required
                                placeholder={t('profile.form.last_name')}
                                value={userDetailFormState.last_name}
                                onChange={handleUserFilling}
                              />
                            </div>
                          </div>
                        </>
                      )}

                      <div className="profile__detail-item">
                        <div>{t('profile.form.email')}</div>
                        {editState ? (
                          <div>
                            <Field type="email" name="email" required placeholder={t('profile.form.email')} value={userDetailFormState.email} onChange={handleUserFilling} />
                          </div>
                        ) : (
                          <div>{userDetailFormState.email}</div>
                        )}
                      </div>

                      {editState && (
                        <>
                          <div className="profile__detail-item">
                            <div>{t('profile.form.password')}</div>
                            <div>
                              <Field type="password" name="password" placeholder={t('profile.form.password')} value={userDetailFormState.password} onChange={handleUserFilling} />
                            </div>
                          </div>
                          <div className="profile__detail-item">
                            <div>{t('profile.form.confirm_password')}</div>
                            <div>
                              <Field
                                type="password"
                                name="password_confirmation"
                                placeholder={t('profile.form.confirm_password')}
                                value={userDetailFormState.password_confirmation}
                                onChange={handleUserFilling}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    {role === 1 && (
                      <>
                        {/* ---- Company ---- */}
                        {!userDetailFormState.tl_company_id && !editState ? null : (
                          <div className="profile__detail-group">
                            <h2 className="title">{t('profile.form.company_info')}</h2>

                            {editState && (
                              <>
                                <div className="profile__detail-item">
                                  <div>{companyDataIsLoading ? t('profile.form.loading') : t('profile.form.select_company')}</div>

                                  <Select name="tl_company_id" value={userDetailFormState.tl_company_id || ''} onChange={handleUserFilling}>
                                    <option value="">{t('profile.form.select_company_default')}</option>
                                    {companyArray.map(company => (
                                      <option key={company.id} value={company.id}>
                                        {company.title}
                                      </option>
                                    ))}
                                  </Select>
                                </div>
                              </>
                            )}
                            {(!companyDataIsLoading && userDetailFormState.tl_company_id) ? (
                              <>
                                <div className="profile__detail-item">
                                  <div>{t('profile.form.company_name')}</div>
                                  <div>{companyDetailFormState.name}</div>
                                </div>

                                <div className="profile__detail-item">
                                  <div>{t('profile.form.invoice_email_address')}</div>
                                  <div>{companyDetailFormState.email}</div>
                                </div>

                                <div className="profile__detail-item">
                                    <div>{t('profile.form.address')}</div>
                                    <div>{`${companyDetailFormState.street} ${companyDetailFormState.number}, ${companyDetailFormState.zipcode}, ${companyDetailFormState.city}, ${companyDetailFormState.country}`}</div>
                                </div>

                                <div className="profile__detail-item">
                                  <div>{t('profile.form.number')}</div>
                                  <div>{companyDetailFormState.telephone}</div>
                                </div>

                                <div className="profile__detail-item">
                                  <div>{t('profile.form.vat_number')}</div>
                                  <div>{companyDetailFormState.vat_code}</div>
                                </div>
                              </>
                            ) : (
                              ''
                            )}
                          </div>
                        )}

                        {editState && (
                          <div className="profile__detail-group">
                            <h2 className="title">{t('profile.form.settings')}</h2>

                            <div className="profile__detail-item">
                              <div>{t('profile.form.pay_by_invoice_label')}</div>
                              <Select
                                name="invoice"
                                value={+userDetailFormState.invoice}
                                onChange={handleUserFilling}
                              >
                                <option value={1}>{t('profile.form.pay_by_invoice_select.allow')}</option>
                                <option value={0}>{t('profile.form.pay_by_invoice_select.dont_allow')}</option>
                              </Select>
                            </div>

                            <div className="profile__detail-item">
                              <div>{t('profile.form.currency_label')}</div>
                              <Select name="currency" value={userDetailFormState.currency} onChange={handleUserFilling}>
                                <option value="eur">€ ({t('profile.form.currency_select.euro')})</option>
                                <option value="usd">$ ({t('profile.form.currency_select.dollar')})</option>
                              </Select>
                            </div>

                            <div className="profile__detail-item">
                              <div>{t('profile.form.app_language_label')}</div>
                              <Select name="locale" value={userDetailFormState.locale} onChange={handleUserFilling}>
                                <option value="en">{t('profile.form.app_language_select.english')}</option>
                                <option value="nl">{t('profile.form.app_language_select.dutch')}</option>
                                <option value="fr">{t('profile.form.app_language_select.french')}</option>
                              </Select>
                            </div>

                            {userDetailFormState.role === 2 && (
                              <ModalSelect
                                name="private_job_types"
                                wrapperClassName="profile__detail-item"
                                selectLabel={t('profile.form.job_types_label')}
                                modalTitle={t('profile.form.job_types_placeholder')}
                                options={jobTypes.data.data}
                                onChange={handleUserFilling}
                                values={userDetailFormState.private_job_types} />
                            )}

                          </div>
                        )}

                        {editState && (
                          <div className="profile__detail-group profile__detail-group--full-width">

                            {userDetailFormState.role === 2 && (
                              <div className="profile__detail-item">
                                <div>{t('profile.form.comment_for_supplier')}</div>
                                <Field
                                  type="text"
                                  value={userDetailFormState.comment_for_supplier}
                                  onChange={handleUserFilling}
                                  name={t('profile.form.comment_for_supplier')}
                                  tag="textarea"
                                  rows="6"
                                  placeholder={t('profile.form.comment_for_supplier')} />
                              </div>
                            )}

                          </div>
                        )}
                      </>
                    )}
                  </form>
                </div>
              </div>
            </div>
          )
        )}
      </section>

      <Modal state={uploadImageModalState} setState={setUploadImageModalState}>
        <ModalHeader>{t('profile.upload_new_picture')}</ModalHeader>
        <ModalBody className="modal-window__body--single-file-upload">
          <UploadBox loadedFiles={avatarItem} setLoadedFiles={setAvatarItems} single={true} />
          <div className="display--flex justify-content--center">
            <Button className="btn--yellow btn--big" type="submit" onClick={handleAvatarUpload}>
              {uploadingAvatarIsLoading ? t('modals.uploading') : t('modals.upload')}
            </Button>
            <Button
              className="btn--gray btn--big"
              onClick={() => {
                setUploadImageModalState(false);
                setAvatarItems({});
              }}
            >
              {t('modals.cancel')}
            </Button>
          </div>
          <div className="display--flex justify-content--center">
            {uploadingAvatarError && !uploadingAvatarIsLoading && <p className="form__result text--right form__result--red">{uploadingAvatarError}</p>}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default withTranslation()(ProfileRoute);

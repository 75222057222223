import React from "react";
import "./Select.scss";

const Select = ({
	value = "",
	onChange = () => {},
	disabled = false,
	icon = "",
	wrapperClassName = "",
	className = "",
	children = "",
	...attrs
}) => {
	const onChangeAction = e => {
		if (disabled) {
			e.preventDefault();
		} else {
			return onChange(e);
		}
	};

	const classes = `${icon ? `icon icon--${icon}` : null}`;

	return (
		<div className={`${classes} ${wrapperClassName}`}>
			<select className={className} disabled={disabled} value={value} onChange={onChangeAction} {...attrs}>
				{children}
			</select>
		</div>
	);
};

export default Select;

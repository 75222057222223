import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { notificationReducer } from "../reducers/notificationReducer";
import { useNotifications } from '../hooks/notifications/useNotifications';
import Echo from 'laravel-echo';
import io from 'socket.io-client';
import { StateAuthContext } from './AuthContext';
import { StateUserContext } from './UserContext';
import useSocket from '../hooks/common/useSocket';

export const DispatchNotificationContext = createContext();
export const StateNotificationContext = createContext();

const NotificationProvider = ({ children }) => {
	//Start create and set a default auth state with data from local storage

	const [notifications, invokeNotifications] = useNotifications();
	const [notificationState, notificationDispatch] = useReducer(notificationReducer, []);
	const [socketConnection] = useSocket();
	//End create and set a default auth state with data from local storage
	const userState = useContext(StateUserContext);

	useEffect(() => {
		if (socketConnection && userState.id) {
			socketConnection.private(`user.${userState.id}`)
				.notification((notification) => {
					const { id, type, ...notificationData } = notification;
					notificationDispatch({
						type: 'ADD-NOTIFICATION-DATA',
						payload: [{
							id,
							type,
							isPush: true,
							data: { ...notificationData },
						}],
					})
				});

			invokeNotifications();
		}

		return () => {
			if (socketConnection) {
				socketConnection.leave(`user.${userState.id}`);
			}
		};
	}, [socketConnection]);

	//Start update local storage data
	useEffect(() => {
		if (notifications.data && ! notifications.error && ! notifications.isLoading) {
			notificationDispatch({
				type: 'SET-NOTIFICATION-DATA',
				payload: notifications.data
			});
		}
	}, [notifications.data]);

	//End update local storage data

	return (
		<DispatchNotificationContext.Provider value={notificationDispatch}>
			<StateNotificationContext.Provider value={notificationState}>
				{children}
			</StateNotificationContext.Provider>
		</DispatchNotificationContext.Provider>
	);
};

export default NotificationProvider;

import React from "react";
import { CSSTransition } from "react-transition-group";
import "./FadeTransition.scss";

const FadeTransition = ({ children, timeout = 300, delay = 0, ...attrs }) => {

	// const [render, setRender] = useState('')

	// setTimeout(() => setRender(<CSSTransition timeout={timeout} classNames="fade" {...attrs}>
	// 	{children}
	// </CSSTransition>), delay)

	return (
		<CSSTransition timeout={timeout} classNames="fade" {...attrs}>
			{children}
		</CSSTransition>
		// <>
		// 	{render}
		// </>
	);
};

export default FadeTransition;

import useAPIService from '../common/useApiService';

export const useUpdateJobType = (id, state) => {
  // Job type data
  const [updateJobType, invokeUpdateJobType] = useAPIService(`job-types/${id}`, { method: 'put', data: state });

  return [
    updateJobType,
    invokeUpdateJobType
  ];
}
import { useEffect } from 'react';
import useAPIService from '../common/useApiService';
import { useFilter } from '../common/useFilter';
import { useTranslation } from 'react-i18next';

export const useJobTypes = (search = '') => {
  // API call
  const [queryParams, { sortBy }] = useFilter(search);
  const [jobTypes, invokeJobTypes] = useAPIService(`job-types${queryParams}`);
  const { t } = useTranslation();

  useEffect(() => {
      invokeJobTypes();
  }, [queryParams, t]);

  return [
    jobTypes,
    {
      invokeJobTypes,
      sortBy,
    }
  ];
}
import React, { useContext, useEffect, useState } from 'react';
import './JobRoute.scss';
import StatusSwitcher from '../../../job/StatusSwitcher/StatusSwitcher';
import CreateNewJob from '../../../job/client/CreateNewJob/CreateNewJob';
import RequestedJob from '../../../job/client/RequestedJob/RequestedJob';
import SupplierRequestedJob from '../../../job/supplier/SupplierRequestedJob/SupplierRequestedJob';
import SupplierApprovalJob from '../../../job/supplier/SupplierApprovalJob/SupplierApprovalJob';
import SupplierApprovedJob from '../../../job/supplier/SupplierApprovedJob/SupplierApprovedJob';
import SupplierCompletedJob from '../../../job/supplier/SupplierCompletedJob/SupplierCompletedJob';
import ApprovedJob from '../../../job/client/ApprovedJob/ApprovedJob';
import CompletedJob from '../../../job/client/CompletedJob/CompletedJob';
import RequestedJobPaying from '../../../job/client/RequestedJobPaying/RequestedJobPaying';
import ContentSwitcher from '../../../job/ContentSwitcher/ContentSwitcher';
import { DispatchJobContext } from '../../../../context/JobContext';
import { StateUserContext } from '../../../../context/UserContext';
import Preloader from '../../../partials/Preloader/Preloader';
import { withTranslation } from 'react-i18next';
import useAPIService from '../../../../hooks/common/useApiService';

const JobRoute = ({
  match: {
    params: { id },
  },
  history,
  t
}) => {
  //Start general
  const creating = id === 'create-new';

  const { role } = useContext(StateUserContext);

  const jobDispatch = useContext(DispatchJobContext);

  // const filterArray = [
  // 	'DRAFT', //0
  // 	'REQUEST', //1
  // 	'PRICING', //2
  // 	'APPROVED', //3
  // 	'COMPLETED', //4
  // 	'REVISION', //5
  // 	'ARCHIVED', //6
  // 	'CANCELLED' //7
  // ]

  //End general

  //Start getting data
  const [gotDataState, setGotDataState] = useState(false);

  const [{ data, isLoading}, invokeJob] = useAPIService(`${!creating ? 'jobs/' + id : ''}`);

  useEffect(() => {
    if (!creating) {
      invokeJob();
    }
  }, [t, id]);

  useEffect(() => {
    if (creating) {
      jobDispatch({
        type: 'UPDATE-JOB-DATA',
        payload: {
          status: 0,
          name: '',
          description: '',
          urgency: false,
          id: '',
          job_types: [],
          uploaded_size: 0,
          job_items: {
            all: [],
            simple: [],
            complex: [],
            super_complex: [],
          },
        },
      });
    }

    if (data !== null) {
      jobDispatch({
        type: 'SET-JOB-DATA',
        payload: { ...data },
      });

      setGotDataState(true);
    }
  }, [data, isLoading, jobDispatch, creating]);
  //End getting data

  //Start route logic

  const clientStatuses = [
    { name: '1. ' + t('dashboard.create_new_job'), description: t('job.upload_images'), id: 0 },
    { name: '2. ' + t('job.requested'), description: t('job.confirm_price'), id: [1, 2] },
    { name: '3. ' + t('job.approved'), description:  t('job.waiting_for_result'), id: 3 },
    { name: '4. ' + t('job.completed'), description: t('job.download_your_files'), id: 4 },
  ];

  const clientContents = [
    <CreateNewJob isCreating={creating} history={history} id={id} />,
    <RequestedJob />,
    <RequestedJobPaying />,
    <ApprovedJob />,
    <CompletedJob />,
  ];

  const supplierStatuses = [
    { name: '1. ' + t('job.requested'), description: t('job.quotation_requested'), id: 1 },
    { name: '2. ' + t('job.approval'), description: t('job.awaiting_approval'), id: 2 },
    { name: '3. ' + t('job.approved'), description: t('job.awaiting_confirmation'), id: 3 },
    { name: '4. ' + t('job.awaiting_revision'), description: t('job.client_will_approve'), id: 4 },
  ];

  const supplierContents = [
    // <CreateNewJob isCreating={creating} history={history} id={id} />,
    <>
      <h1 className="title text--center">{t('dashboard.login_as_client')}</h1>
    </>,
    <SupplierRequestedJob />,
    <SupplierApprovalJob />,
    <SupplierApprovedJob />,
    <SupplierCompletedJob />,
  ];

  //End route logic

  return (
    <section className="section section--default job">
      <div className="container">
        {isLoading ? (
          <Preloader />
        ) : gotDataState || creating ? (
          <>
            <StatusSwitcher statuses={role === 3 || role === 1 ? supplierStatuses : clientStatuses} />
            <ContentSwitcher contents={role === 3 || role === 1 ? supplierContents : clientContents} />
          </>
        ) : null}
      </div>
    </section>
  );
};

export default withTranslation()(JobRoute);

export const notificationReducer = (state, action) => {
  switch (action.type) {
    case "SET-NOTIFICATION-DATA": {
      console.log("SET-NOTIFICATION-DATA");
      return [
        ...action.payload
      ];
    }
    case "ADD-NOTIFICATION-DATA": {
      console.log("ADD-NOTIFICATION-DATA");
      const notificaitons = action.payload.map(n => n.id ? n : { ...n, id: `f${(+(new Date)).toString(16)}` });
      return [...state, ...notificaitons];
    }
    case "DELETE-NOTIFICATION-DATA": {
      console.log("DELETE-NOTIFICATION-DATA");
      return [];
    }
    case "REMOVE-NOTIFICATION-DATA-BY-ID": {
      console.log("REMOVE-NOTIFICATION-DATA-BY-ID");
      const data = [...state];
      return data.filter(n => n.id !== action.payload);
    }
    case "MOVE-PUSH-NOTIFICATION-TO-NOTIFICATION-DATA": {
      console.log("MOVE-PUSH-NOTIFICATION-TO-NOTIFICATION-DATA");
      const data = [...state];
      return data.map(n => ({ ...n, isPush: false }));
    }
    default:
      break;
  }
};

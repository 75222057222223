/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import './SupplierRequestedJob.scss';
import Button from '../../../partials/Button/Button';
import InfoBlock from '../../../partials/InfoBlock/InfoBlock';
import ImagePreviews from '../../ImagePreviews/ImagePreviews';
import ImagePreviewSelected from '../../ImagePreviewSelected/ImagePreviewSelected';
import { DispatchJobContext, StateJobContext } from '../../../../context/JobContext';
import Modal, { ModalBody, ModalHeader } from '../../../partials/Modal/Modal';
import { useShowNotification } from '../../../../hooks/notifications/useShowNotification';
import { withTranslation } from 'react-i18next';
import DownloadJobButton from '../../../partials/DownloadJobButton/DownloadJobButton';
import { useJobChangeDifficulty } from '../../../../hooks/jobs/useJobChangeDifficulty';

const SupplierRequestedJob = ({ className = '', t, ...attr }) => {
  const jobState = useContext(StateJobContext);
  const jobDispatch = useContext(DispatchJobContext);
  const { infoNotification, errorNotification } = useShowNotification();
  // Job items
  const [{ isLoading: sendIsLoading, data: sendData, error: sendError }, { invokeJobChangeDifficulty }] = useJobChangeDifficulty(jobState ? jobState.id : null);

  //Start pricing
  const [submitPricingActiveState, setSubmitPricingActiveState] = useState(false);

  //Start modal
  const [allJobItemsModalState, setAllJobItemsModalState] = useState(false);
  const [allJobItemsModalTypeState, setAllJobItemsModalTypeState] = useState({});
  // Archive request

  const handleOpenAllJobItemsModal = type => {
    switch (type) {
      case 1:
        setAllJobItemsModalTypeState({
          title: (
            <>
              <span className="display--flex align-items--center">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle opacity="0.06" cx="16" cy="16" r="16" fill="#05C46B" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.3972 13.421C19.6449 13.421 19.7859 13.7043 19.6365 13.9019L19.1638 14.5272L18.7568 15.0626L12.9167 22.7821C12.8027 22.9336 12.5676 22.7919 12.6482 22.6204L12.6797 22.5532L15.3628 16.8821L15.9978 15.536H12.4735C12.2534 15.536 12.1082 15.3069 12.2022 15.1079L15.4783 8.17187C15.5279 8.06694 15.6336 8 15.7496 8H18.0865C18.3066 8 18.4518 8.22919 18.3577 8.4282L15.9978 13.421H19.3972Z"
                    fill="#05C46B"
                  />
                </svg>
                {t('job.simple_images')}
              </span>
              <span className="hidden-on-mobile ">{jobState.job_items.simple.length} {t('job.images')}</span>
            </>
          ),
          type,
        });
        break;
      case 2:
        setAllJobItemsModalTypeState({
          title: (
            <>
              <span className="display--flex align-items--center">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle opacity="0.06" cx="16" cy="16" r="16" fill="#F3CD29" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.3972 13.421C19.6449 13.421 19.7859 13.7043 19.6365 13.9019L19.1638 14.5272L18.7568 15.0626L12.9167 22.7821C12.8027 22.9336 12.5676 22.7919 12.6482 22.6204L12.6797 22.5532L15.3628 16.8821L15.9978 15.536H12.4735C12.2534 15.536 12.1082 15.3069 12.2022 15.1079L15.4783 8.17187C15.5279 8.06694 15.6336 8 15.7496 8H18.0865C18.3066 8 18.4518 8.22919 18.3577 8.4282L15.9978 13.421H19.3972Z"
                    fill="#F3CD29"
                  />
                </svg>
                {t('job.complex_images')}
              </span>
              <span className="hidden-on-mobile ">{jobState.job_items.complex.length} {t('job.images')}</span>
            </>
          ),
          type,
        });
        break;
      case 3:
        setAllJobItemsModalTypeState({
          title: (
            <>
              <span className="display--flex align-items--center">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle opacity="0.06" cx="16" cy="16" r="16" fill="#F18F1C" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.3972 13.421C19.6449 13.421 19.7859 13.7043 19.6365 13.9019L19.1638 14.5272L18.7568 15.0626L12.9167 22.7821C12.8027 22.9336 12.5676 22.7919 12.6482 22.6204L12.6797 22.5532L15.3628 16.8821L15.9978 15.536H12.4735C12.2534 15.536 12.1082 15.3069 12.2022 15.1079L15.4783 8.17187C15.5279 8.06694 15.6336 8 15.7496 8H18.0865C18.3066 8 18.4518 8.22919 18.3577 8.4282L15.9978 13.421H19.3972Z"
                    fill="#F18F1C"
                  />
                </svg>
                {t('job.super_complex_images')}
              </span>
              <span className="hidden-on-mobile ">{jobState.job_items.super_complex.length} {t('job.images')}</span>
            </>
          ),
          type,
        });
        break;

      default:
        break;
    }
    setAllJobItemsModalState(true);
  };

  const handleSelect = (type, jobItem) => {
    switch (type) {
      case 1:
        if (jobItem.difficulty === 1) {
          jobDispatch({
            type: 'REMOVE-SIMPLE-LOADED-IMAGE',
            payload: jobItem.id,
          });
          jobDispatch({
            type: 'UPDATE-LOADED-IMAGE',
            payload: { ...jobItem, difficulty: null },
          });
        } else if (jobItem.difficulty === 2) {
          jobDispatch({
            type: 'REMOVE-COMPLEX-LOADED-IMAGE',
            payload: jobItem.id,
          });

          jobDispatch({
            type: 'UPDATE-LOADED-IMAGE',
            payload: { ...jobItem, difficulty: 1 },
          });

          jobDispatch({
            type: 'SET-SIMPLE-LOADED-IMAGE',
            payload: { ...jobItem, difficulty: 1 },
          });
        } else if (jobItem.difficulty === 3) {
          jobDispatch({
            type: 'REMOVE-SUPER-COMPLEX-LOADED-IMAGE',
            payload: jobItem.id,
          });

          jobDispatch({
            type: 'UPDATE-LOADED-IMAGE',
            payload: { ...jobItem, difficulty: 1 },
          });

          jobDispatch({
            type: 'SET-SIMPLE-LOADED-IMAGE',
            payload: { ...jobItem, difficulty: 1 },
          });
        } else {
          jobDispatch({
            type: 'UPDATE-LOADED-IMAGE',
            payload: { ...jobItem, difficulty: 1 },
          });
          jobDispatch({
            type: 'SET-SIMPLE-LOADED-IMAGE',
            payload: { ...jobItem, difficulty: 1 },
          });
        }
        break;
      case 2:
        if (jobItem.difficulty === 2) {
          jobDispatch({
            type: 'REMOVE-COMPLEX-LOADED-IMAGE',
            payload: jobItem.id,
          });
          jobDispatch({
            type: 'UPDATE-LOADED-IMAGE',
            payload: { ...jobItem, difficulty: null },
          });
        } else if (jobItem.difficulty === 1) {
          jobDispatch({
            type: 'REMOVE-SIMPLE-LOADED-IMAGE',
            payload: jobItem.id,
          });

          jobDispatch({
            type: 'UPDATE-LOADED-IMAGE',
            payload: { ...jobItem, difficulty: 2 },
          });

          jobDispatch({
            type: 'SET-COMPLEX-LOADED-IMAGE',
            payload: { ...jobItem, difficulty: 2 },
          });
        } else if (jobItem.difficulty === 3) {
          jobDispatch({
            type: 'REMOVE-SUPER-COMPLEX-LOADED-IMAGE',
            payload: jobItem.id,
          });

          jobDispatch({
            type: 'UPDATE-LOADED-IMAGE',
            payload: { ...jobItem, difficulty: 2 },
          });

          jobDispatch({
            type: 'SET-COMPLEX-LOADED-IMAGE',
            payload: { ...jobItem, difficulty: 2 },
          });
        } else {
          jobDispatch({
            type: 'UPDATE-LOADED-IMAGE',
            payload: { ...jobItem, difficulty: 2 },
          });
          jobDispatch({
            type: 'SET-COMPLEX-LOADED-IMAGE',
            payload: { ...jobItem, difficulty: 2 },
          });
        }
        break;
      case 3:
        if (jobItem.difficulty === 3) {
          jobDispatch({
            type: 'REMOVE-SUPER-COMPLEX-LOADED-IMAGE',
            payload: jobItem.id,
          });
          jobDispatch({
            type: 'UPDATE-LOADED-IMAGE',
            payload: { ...jobItem, difficulty: null },
          });
        } else if (jobItem.difficulty === 2) {
          jobDispatch({
            type: 'REMOVE-COMPLEX-LOADED-IMAGE',
            payload: jobItem.id,
          });

          jobDispatch({
            type: 'UPDATE-LOADED-IMAGE',
            payload: { ...jobItem, difficulty: 3 },
          });

          jobDispatch({
            type: 'SET-SUPER-COMPLEX-LOADED-IMAGE',
            payload: { ...jobItem, difficulty: 3 },
          });
        } else if (jobItem.difficulty === 1) {
          jobDispatch({
            type: 'REMOVE-SIMPLE-LOADED-IMAGE',
            payload: jobItem.id,
          });

          jobDispatch({
            type: 'UPDATE-LOADED-IMAGE',
            payload: { ...jobItem, difficulty: 3 },
          });

          jobDispatch({
            type: 'SET-SUPER-COMPLEX-LOADED-IMAGE',
            payload: { ...jobItem, difficulty: 3 },
          });
        } else {
          jobDispatch({
            type: 'UPDATE-LOADED-IMAGE',
            payload: { ...jobItem, difficulty: 3 },
          });
          jobDispatch({
            type: 'SET-SUPER-COMPLEX-LOADED-IMAGE',
            payload: { ...jobItem, difficulty: 3 },
          });
        }
        break;
      default:
        break;
    }

  };

  useEffect(() => {
    allJobItemsModalTypeState.type && handleOpenAllJobItemsModal(allJobItemsModalTypeState.type);
  }, [jobState]);

  //End modal

  //End pricing

  //Start submit

  //Start sanding logic

  const handleSubmit = e => {
    e.preventDefault();
    if (jobState.job_items.all.length === (jobState.job_items.simple.length + jobState.job_items.complex.length + jobState.job_items.super_complex.length)) {
      const jobItems = jobState.job_items.all.map(item => ({ id: item.id, difficulty: item.difficulty }));
      invokeJobChangeDifficulty({ job_items: jobItems });
    } else {
      errorNotification(t('notifications.error.assign_all'));
    }
  };

  useEffect(() => {
    if (!(sendData === null) && !sendError && !sendIsLoading) {
      console.log("Data after update", sendData);
      jobDispatch({
        type: 'UPDATE-JOB-DATA',
        payload: sendData,
      });
      infoNotification(t('notifications.info.job_updated'));
    }
  }, [sendData, jobDispatch]);

  //Start parsing data
  //End submit

  const { name, job_items, description, description_en, urgency, uploaded_size } = jobState;

  return (
    <>
      <div className="container">
        <div className={`section__content job__requested  ${className} ${submitPricingActiveState && 'job__requested--pricing'}`} {...attr}>
          <div className="job__heading job__heading--two-column">
            <div>
              <h1 className="title">{t('job.quotation_requested')}</h1>
              <p className="sub-title">{t('job.requested_supplier_description')}</p>
            </div>
            {!submitPricingActiveState && (
              <InfoBlock className="info-block--yellow">
                {t('job.client_uploaded_images', {
                  'count': job_items.all.length,
                  'size': jobState.uploaded_size < 0.5
                          ? parseInt(jobState.uploaded_size * 1000) + ' kB'
                          : (+jobState.uploaded_size).toFixed(2) + ' MB'
                })}
              </InfoBlock>
            )}
          </div>
          <div className="job__body">
            {submitPricingActiveState ? (
              <>
                <div className="job__images">
                  <div className="job__detail-item">
                    <div className="content-line ">
                      <span>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle opacity="0.06" cx="16" cy="16" r="16" fill="#05C46B" />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M19.3972 13.421C19.6449 13.421 19.7859 13.7043 19.6365 13.9019L19.1638 14.5272L18.7568 15.0626L12.9167 22.7821C12.8027 22.9336 12.5676 22.7919 12.6482 22.6204L12.6797 22.5532L15.3628 16.8821L15.9978 15.536H12.4735C12.2534 15.536 12.1082 15.3069 12.2022 15.1079L15.4783 8.17187C15.5279 8.06694 15.6336 8 15.7496 8H18.0865C18.3066 8 18.4518 8.22919 18.3577 8.4282L15.9978 13.421H19.3972Z"
                            fill="#05C46B"
                          />
                        </svg>
                        {t('job.simple_images')}
                      </span>
                      <span>
                        <small>{job_items.simple.length} {t('job.images')}</small>
                      </span>
                    </div>
                    <ImagePreviews
                      selectModalButton={
                        <div
                          onClick={() => {
                            handleOpenAllJobItemsModal(1);
                          }}
                          className="img-previews__item select-job-item-button select-job-item-button--green"
                        >
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle opacity="0.1" cx="12" cy="12" r="12" fill="#05C46B" />
                            <path d="M12.7269 12.7269H17V11.2731H12.7269V7H11.2731V11.2731H7V12.7269H11.2731V17H12.7269V12.7269Z" fill="#05C46B" />
                          </svg>
                          <span>{t('job.add_images')}</span>
                        </div>
                      }
                      controls={false}
                      className="img-previews--full"
                      job_items={job_items.simple}
                      maxItems={4}
                      uploadedSize={job_items.simple.reduce((accumulator, currentValue) => accumulator + Number(currentValue.size), 0)}
                      editable={false}
                    />
                  </div>

                  <div className="job__detail-item">
                    <div className="content-line ">
                      <span>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle opacity="0.06" cx="16" cy="16" r="16" fill="#F3CD29" />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M19.3972 13.421C19.6449 13.421 19.7859 13.7043 19.6365 13.9019L19.1638 14.5272L18.7568 15.0626L12.9167 22.7821C12.8027 22.9336 12.5676 22.7919 12.6482 22.6204L12.6797 22.5532L15.3628 16.8821L15.9978 15.536H12.4735C12.2534 15.536 12.1082 15.3069 12.2022 15.1079L15.4783 8.17187C15.5279 8.06694 15.6336 8 15.7496 8H18.0865C18.3066 8 18.4518 8.22919 18.3577 8.4282L15.9978 13.421H19.3972Z"
                            fill="#F3CD29"
                          />
                        </svg>
                        {t('job.complex_images')}
                      </span>
                      <span>
                        <small>{job_items.complex.length} {t('job.images')}</small>
                      </span>
                    </div>

                    <ImagePreviews
                      selectModalButton={
                        <div
                          onClick={() => {
                            handleOpenAllJobItemsModal(2);
                          }}
                          className="img-previews__item select-job-item-button select-job-item-button--yellow "
                        >
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle opacity="0.1" cx="12" cy="12" r="12" fill="#F3CD29" />
                            <path d="M12.7269 12.7269H17V11.2731H12.7269V7H11.2731V11.2731H7V12.7269H11.2731V17H12.7269V12.7269Z" fill="#F3CD29" />
                          </svg>
                          <span>{t('job.add_images')}</span>
                        </div>
                      }
                      controls={false}
                      className="img-previews--full"
                      job_items={job_items.complex}
                      maxItems={4}
                      uploadedSize={job_items.complex.reduce((accumulator, currentValue) => accumulator + Number(currentValue.size), 0)}
                      editable={false}
                    />
                  </div>

                  <div className="job__detail-item">
                    <div className="content-line ">
                      <span>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle opacity="0.06" cx="16" cy="16" r="16" fill="#F18F1C" />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M19.3972 13.421C19.6449 13.421 19.7859 13.7043 19.6365 13.9019L19.1638 14.5272L18.7568 15.0626L12.9167 22.7821C12.8027 22.9336 12.5676 22.7919 12.6482 22.6204L12.6797 22.5532L15.3628 16.8821L15.9978 15.536H12.4735C12.2534 15.536 12.1082 15.3069 12.2022 15.1079L15.4783 8.17187C15.5279 8.06694 15.6336 8 15.7496 8H18.0865C18.3066 8 18.4518 8.22919 18.3577 8.4282L15.9978 13.421H19.3972Z"
                            fill="#F18F1C"
                          />
                        </svg>
                        {t('job.super_complex_images')}
                      </span>
                      <span>
                        <small>{job_items.super_complex.length} {t('job.images')}</small>
                      </span>
                    </div>

                    <ImagePreviews
                      selectModalButton={
                        <div
                          onClick={() => {
                            handleOpenAllJobItemsModal(3);
                          }}
                          className="img-previews__item select-job-item-button select-job-item-button--orange"
                        >
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle opacity="0.1" cx="12" cy="12" r="12" fill="#F18F1C" />
                            <path d="M12.7269 12.7269H17V11.2731H12.7269V7H11.2731V11.2731H7V12.7269H11.2731V17H12.7269V12.7269Z" fill="#F18F1C" />
                          </svg>
                          <span>{t('job.add_images')}</span>
                        </div>
                      }
                      controls={false}
                      className="img-previews--full"
                      job_items={job_items.super_complex}
                      maxItems={4}
                      uploadedSize={job_items.super_complex.reduce((accumulator, currentValue) => accumulator + Number(currentValue.size), 0)}
                      editable={false}
                    />
                  </div>
                </div>

                <div className="btn-group justify-content--center">
                  <Button
                    className="btn--gray btn--big"
                    onClick={() => {
                      setSubmitPricingActiveState(false);
                    }}
                  >
                    {t('job.back')}
                  </Button>
                  <Button className="btn--yellow btn--big" onClick={handleSubmit}>
                    {sendIsLoading ? t('job.submitting') : t('job.submit')}
                  </Button>
                </div>

                {!(sendData === null) && sendError && !sendIsLoading && <p className="form__result text--center form__result--red">{sendError}</p>}

                <Modal state={allJobItemsModalState} setState={setAllJobItemsModalState}>
                  <ModalHeader>{allJobItemsModalTypeState.title}</ModalHeader>
                  <ModalBody>
                    <div className="img-previews img-previews--modal">
                      {job_items.all.map(item => (
                        <ImagePreviewSelected
                          job_item={item}
                          key={item.id}
                          onClick={() => {
                            handleSelect(allJobItemsModalTypeState.type, item);
                          }}
                        />
                      ))}
                    </div>
                  </ModalBody>
                </Modal>
              </>
            ) : (
              <>
                <div className="job__detail">
                  <div className="job__detail-item">
                    <div>{t('job.job_name')}</div>
                    <div className="bullet bullet--yellow">{name}</div>
                  </div>

                  <div className="job__detail-item">
                    <div>{t('job.type')}</div>
                    {jobState.job_types.map((type) =>
                      <div key={type.id} className="bullet bullet--yellow">
                        {type.name}
                        {type.description_for_supplier && (
                          <div className="question-mark">
                            <span className="question-mark__icon">&#63;</span>
                            <div className="question-mark__description">
                              {type.description_for_supplier}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  {(description_en || description) && (
                    <div className="job__detail-item">
                      <div>{t('job.description')}</div>
                      <div className="bullet bullet--yellow">{description_en || description}</div>
                    </div>
                  )}

                  <div className="job__detail-item">
                    <div>{t('job.urgency.urgency_label')}</div>
                    <div className="bullet bullet--yellow">{urgency ? t('job.urgency.urgency_50') : t('job.urgency.urgency_0')}</div>
                  </div>
                </div>
                <div className="job__images">
                  <div className="job__detail-item">
                    <div>{t('job.uploaded_images')}</div>
                  </div>

                  <ImagePreviews job_items={job_items.all} maxItems={8} uploadedSize={uploaded_size} editable={false} />
                </div>
                <div className="btn-group">
                  <DownloadJobButton jobId={jobState.id} className="btn--big btn--yellow icon icon--left icon--download">
                    {t('job.download_images')}
                  </DownloadJobButton>
                  <Button
                    className="btn--yellow btn--big"
                    onClick={() => {
                      setSubmitPricingActiveState(true);
                    }}
                  >
                    {t('job.continue_to_pricing')}
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(SupplierRequestedJob);

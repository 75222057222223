/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState, useRef } from 'react';
import './SupplierApprovedJob.scss';
import UploadBox from '../../../partials/UploadBox/UploadBox';
import Button from '../../../partials/Button/Button';
import InfoBlock from '../../../partials/InfoBlock/InfoBlock';
import ImagePreviews from '../../ImagePreviews/ImagePreviews';
import { DispatchJobContext, StateJobContext } from '../../../../context/JobContext';
import countDownCounter from '../../../../helpers/countDownCounter';
import Plupload from 'react-plupload';
import { url } from '../../../../constants/url';
import { StateAuthContext } from '../../../../context/AuthContext';
import { useShowNotification } from '../../../../hooks/notifications/useShowNotification';
import { withTranslation } from 'react-i18next';
import DownloadJobButton from '../../../partials/DownloadJobButton/DownloadJobButton';
import { useUpdateJob } from '../../../../hooks/jobs/useUpdateJob';
import { useDeleteJobItem } from '../../../../hooks/jobItems/useDeleteJobItem';
import getJsonFromUrl from '../../../../helpers/getJsonFromUrl';
import { StateLocaleContext } from '../../../../context/LocaleContext';
import updateURLParameter from '../../../../helpers/changeUrlParam';

const SupplierApprovedJob = ({ className = '', t, ...attr }) => {
  const locale = useContext(StateLocaleContext);
  const jobState = useContext(StateJobContext);
  const jobDispatch = useContext(DispatchJobContext);
  const { infoNotification, errorNotification } = useShowNotification();

  const [imageUploading, setImageUploading] = useState(false);
  const { name, job_items, description, description_en, urgency, complete_at, id } = jobState;
  const jobTypesDropzones = jobState.job_types.filter(jobType => jobType.image_upload) || [];
  const jobTypesDropzoneCount = jobTypesDropzones.length;
  const [{ data: updateJobData, error: jobError, isLoading: jobIsLoading }, invokeUpdateJob] = useUpdateJob(id, { status: 4 });

  const uploadImageArraysInit = jobTypesDropzones.map(() => []);
  const [uploadImageArrays, setUploadImageArrays] = useState([...uploadImageArraysInit]);
  const [uploadCount, setUploadCount] = useState(jobTypesDropzones.map(() => 0));
  //image data
  const { access_token } = useContext(StateAuthContext);
  const uploadPlugin = React.createRef();
  // Job Item
  const [deleteId, setDeleteId] = useState(null);
  const [deleteJobItem, { deleteJobItemById }] = useDeleteJobItem();

  //Start countdown
  const countDownCounterFormated = (countDownDate, countDownInterval) => {
    const { hours, minutes } = countDownCounter(countDownDate, countDownInterval);
    return `${hours < 10 ? '0' + hours.toString() : hours}:${minutes < 10 ? '0' + minutes.toString() : minutes}`;
  };

  const [countDown, setCountDown] = useState(countDownCounterFormated(complete_at));

  const countDownInterval = setInterval(() => {
    setCountDown(countDownCounterFormated(complete_at, countDownInterval));
  }, 1000);
  //End countdown

  //Start image logic
  const handleLoadImage = (payload, e, jobTypeId) => {
    const relatedItem = job_items.all.find(item => item.name === payload.name && item.difficulty);
    const link = `${url}job-items/${jobState.id}?job_type_id=${jobTypeId}&difficulty=${relatedItem ? relatedItem.difficulty : ''}`;
    let urls = {...uploadPlugin.current.uploader.getOption('urls'), [payload.name]: link}

    setImageUploading(true);
    uploadPlugin.current.uploader.setOption('urls', urls);
    uploadPlugin.current.uploader.addFile(payload)
    uploadPlugin.current.doUpload(e);
  };

  const handleBeforeUpload = (uploader, file) => {
    const url = uploader.getOption('urls')[file.name];
    uploader.setOption('url', url);
  }

  const incrementUploadCount = dropZoneId => {
    setUploadCount(prevState => {
      return prevState.map((val, index) => dropZoneId === index ? ++val : val);
    });
  }

  const handleFileUploaded = (uploader, file, { response }) => {
    const uploadedImage = JSON.parse(response);
    const i = jobTypesDropzones.findIndex(jt => jt.id === uploadedImage.job_type.id);

    setUploadImageArrays(prevState => {
      prevState[i].push(uploadedImage);
      return [...prevState];
    });
    incrementUploadCount(i);

    if (uploader.files.length <= 1) {
      setImageUploading(false);
    }
  };
  //End image logic

  // On upload error
  const handleUploadError = (uploader, error) => {
    const params = getJsonFromUrl(uploader.getOption('url'));
    const i = jobTypesDropzones.findIndex(jt => jt.id === params.job_type_id);
    incrementUploadCount(i);
    const responseError = JSON.parse(error.response).error;
    errorNotification(responseError.message);

    if (uploader.files.length <= 1) {
      setImageUploading(false);
    }
  }

  //Start submit

  //Start count validation
  const sameCountValidation = () => {
    let countValidationResult = true;
    uploadImageArrays.forEach(uploadImageArray => {
      if (uploadImageArray.length !== uploadImageArrays[0].length) countValidationResult = false;
    });

    return countValidationResult;
  };

  const countValidation = () => {
    const totalUploadItems = uploadImageArrays.flat(1).length;
    const correctTotalUploadItems = jobTypesDropzoneCount * (
      job_items.simple.filter(ji => ! ji.job_type).length
      + job_items.complex.filter(ji => ! ji.job_type).length
      + job_items.super_complex.filter(ji => ! ji.job_type).length
    );
    return totalUploadItems === correctTotalUploadItems;
  };
  //End count validation
  // Start names validation
  const namesValidation = () => {
    let countValidationResult = true;
    const totalUploadItemNames = uploadImageArrays.flat(1);
    const correctTotalUploadItemNames = [...job_items.simple, ...job_items.complex, ...job_items.super_complex];
    const correctImageCountOnIteration = jobTypesDropzoneCount;

    correctTotalUploadItemNames.forEach(correctUploadItem => {
      let currentCount = 0;
      totalUploadItemNames.forEach(uploadItem => {
        if (uploadItem.name === correctUploadItem.name) {
          //Start add complexity filed
          setUploadImageArrays(uploadImageArrays =>
            uploadImageArrays.map(uploadImageArray =>
              uploadImageArray.map(uploadImageArrayItem => {
                if (uploadImageArrayItem.name === correctUploadItem.name) {
                  return { ...uploadImageArrayItem, difficulty: correctUploadItem.difficulty };
                }
                return uploadImageArrayItem;
              })
            )
          );
          //End add complexity filed
          ++currentCount;
        }
      });
      if (currentCount !== correctImageCountOnIteration) {
        countValidationResult = false;
      }
    });

    return countValidationResult;
  };
  //End names validation
  //End validation

  const handleSubmit = e => {
    e.preventDefault();

    if (!sameCountValidation()) {
      errorNotification(t('notifications.error.count_images_in_job_type'));
    } else {
      if (!countValidation()) {
        errorNotification(t('notifications.error.not_correct_count_images'));
      } else {
        if (!namesValidation()) {
          errorNotification(t('notifications.error.names_different'));
        } else {
          invokeUpdateJob();
        }
      }
    }
  };

  useEffect(() => {
    return () => {
      clearInterval(countDownInterval);
    };
  });

  useEffect(() => {
    if (! uploadImageArrays.flat(1).length) {
      const jobItems = [];
      jobTypesDropzones.forEach(jt => {
        jobItems.push(job_items.all.filter(ji => (ji.job_type && ji.job_type.id == jt.id)));
      })
      if (jobItems.length) {
        setUploadImageArrays(jobItems);
      }
    }
  }, [job_items])

  useEffect(() => {
    if (deleteId) {
      deleteJobItemById(deleteId)
    }
  }, [deleteId]);

  useEffect(() => {
    if (deleteJobItem.data !== null && ! deleteJobItem.isLoading && ! deleteJobItem.error) {
      let imagesArray = [];
      for (let images of uploadImageArrays) {
        imagesArray.push(images.filter(img => img.id !== deleteId));
      }
      setUploadImageArrays(imagesArray)
      setDeleteId(null);
      // setUploadCount(prevState => --prevState);
    } else if (deleteJobItem.error) {
      errorNotification("Couldn't delete item, please try again")
    }
  }, [deleteJobItem.data, deleteJobItem.isLoading, deleteJobItem.error]);

  useEffect(() => {
    if (!(updateJobData === null) && !jobError && !jobIsLoading) {
      if (updateJobData.status === 3) {
        errorNotification(t('notifications.error.server_validation'));
      }
      if (jobState.status === 3) {
        jobDispatch({
          type: 'UPDATE-JOB-DATA',
          payload: updateJobData,
        });
        infoNotification(t('notifications.info.images_uploaded'))
      }
    }
  }, [updateJobData, jobError, jobIsLoading]);

  return (
    <>
      <div className="container">
        <div className={`section__content job__create-new job__supplier-approved  ${className}`} {...attr}>
          <div className="job__heading job__heading--two-column">
            <div>
              <h1 className="title">{t('job.job_approved')}</h1>
              <p className="sub-title">{t('job.job_approved_description')}</p>
            </div>

            <InfoBlock className="info-block--yellow">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.2 5.60001H8.8V4.00001H7.2V5.60001ZM8 14.4C4.47119 14.4 1.6 11.5289 1.6 7.99998C1.6 4.4712 4.47119 1.6 8 1.6C11.5288 1.6 14.4 4.4712 14.4 7.99998C14.4 11.5289 11.5288 14.4 8 14.4ZM8 0C3.58159 0 0 3.5816 0 7.99998C0 12.4185 3.58159 16 8 16C12.4184 16 16 12.4185 16 7.99998C16 3.5816 12.4184 0 8 0ZM7.2 12H8.8V7.19998H7.2V12Z"
                  fill="#F3CD29"
                />
              </svg>
              {t('job.supplier_timer')} {countDown}
            </InfoBlock>
            <div className="countdown">{countDown}</div>
          </div>
          <div className="job__body">
            <form action="" className="form" onSubmit={handleSubmit} autoComplete="false">
              <div className="job__detail">
                <div className="job__detail-item">
                  <div>{t('job.job_name')}</div>
                  <div className="bullet bullet--yellow">{name}</div>
                </div>

                <div className="job__detail-item">
                  <div>{t('job.type')}</div>
                  {jobState.job_types.map((type) =>
                    <div key={type.id} className="bullet bullet--yellow">
                      {type.name}
                      {type.description_for_supplier && (
                        <div className="question-mark">
                          <span className="question-mark__icon">&#63;</span>
                          <div className="question-mark__description">
                            {type.description_for_supplier}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>

                {(description_en || description) && (
                  <div className="job__detail-item">
                    <div>{t('job.description')}</div>
                    <div className="bullet bullet--yellow">{description_en || description}</div>
                  </div>
                )}

                <div className="job__detail-item">
                  <div>{t('job.urgency.urgency_label')}</div>
                  <div className="bullet bullet--yellow">{urgency ? t('job.urgency.urgency_50') : t('job.urgency.urgency_0')}</div>
                </div>
              </div>
              <div className="job__images">
                <Plupload
                  ref={uploadPlugin}
                  onFileUploaded={handleFileUploaded}
                  onBeforeUpload={handleBeforeUpload}
                  id="plupload"
                  chunk_size="20mb"
                  onError={handleUploadError}
                  headers={{
                    'authorization': `Bearer ${access_token}`,
                    'Accept': 'application/json',
                    'X-Localization': locale,
                  }}
                  multipart
                  url={url + 'jobs/upload'}
                />
                {jobTypesDropzones.map((job_type, i) => (
                  <div key={job_type.id + i}>
                    <div className="job__detail-item">
                      <div>{t('job.upload_image_job_type', {'job_type': job_type.name}) }</div>
                      <UploadBox
                        id={`file-${i}`}
                        setLoadedFiles={(file, e) => handleLoadImage(file, e, job_type.id)}
                        jobType={job_type}
                        single={false}
                        disabled={imageUploading}
                        uploadCount={uploadCount[i]}
                        setUploadCount={(n) => { setUploadCount(prevState => prevState.map((val, index) => i === index ? n : val)) }} />
                    </div>
                    <ImagePreviews
                      removeJobItem={(id) => { setDeleteId(id) }}
                      job_items={uploadImageArrays[i]}
                      remove={true}
                      disabled={jobIsLoading || deleteId}
                      uploadedSize={uploadImageArrays[i] ? uploadImageArrays[i].reduce((accumulator, currentValue) => accumulator + Number(currentValue.size), 0) : 0}
                      maxItems={2} />
                  </div>
                ))}
              </div>

              <div className="btn-group job__requested">
                <DownloadJobButton
                  jobId={jobState.id}
                  className="btn--big btn--yellow icon icon--left icon--download">
                  {t('job.download_images')}
                </DownloadJobButton>

                <Button className="btn--yellow btn--big" type="submit" disabled={jobIsLoading || imageUploading}>
                  {jobIsLoading ? t('sign_in.loading') : t('modals.upload')}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(SupplierApprovedJob);

import React from 'react';
import ImagePreview from '../ImagePreview/ImagePreview';

const ImagePreviewSelected = ({ job_item = {}, onClick = () => {} }) => {
  return (
    <div className="img-previews__item-wrapper img-previews__item-wrapper--pointer" onClick={onClick}>
      <ImagePreview
        simple={job_item.difficulty === 1}
        complex={job_item.difficulty === 2}
        superComplex={job_item.difficulty === 3}
        controls={false}
        job_item={job_item}
      />
    </div>
  );
};

export default ImagePreviewSelected;

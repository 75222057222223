const errorToString = error => {
  let errorMessage = '';

  if (error.code === 'validation_error') {
    const errorFields = Object.values(error.message);
    errorMessage = errorFields.map(fieldError => fieldError).join(' ')
  } else {
    errorMessage = error.message || error.data.message;
  }

  return errorMessage;
}

export default errorToString;
import React, { useState, useEffect } from 'react';
import Modal, { ModalBody, ModalHeader } from '../Modal/Modal';
import Checkbox from '../Checkbox/Checkbox';
import Button from '../Button/Button';
import { withTranslation } from 'react-i18next';

const ModalSelect = ({
                       name = '',
                       options = [],
                       values = [],
                       onChange = () => {
                       },
                       selectLabel = '',
                       modalTitle = '',
                       wrapperClassName = '',
                       className = '',
                       t,
                     }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [optionsState, setOptionsState] = useState(
    Array.isArray(values)
      ? options.filter(opt => values.includes(opt.id)).map(opt => opt.id)
      : [],
  );

  const handleOptionsSelection = (jobType, e) => {
    if (optionsState.find(jobTypeFromState => jobTypeFromState === jobType)) {
      setOptionsState(prevState => prevState.filter(jobTypeFromState => jobTypeFromState !== jobType));
    } else {
      setOptionsState(prevState => [...prevState, jobType]);
    }
  };

  useEffect(() => {
    onChange({
      target: {
        name: name,
        type: 'input',
        value: optionsState,
      },
    });
  }, [optionsState]);

  return (
    <>
      <div className={wrapperClassName}>
        <div>{selectLabel}</div>
        <Button
          className="btn--select"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          {optionsState.length ? t('profile.form.job_types_selected', {'job_types': optionsState.length}) : t('profile.form.job_types_default', {'job_types': selectLabel})}
        </Button>
      </div>
      <Modal state={isOpen} setState={setIsOpen}>
        <ModalHeader>
          {modalTitle} <span
          className="hidden-on-mobile">{options !== null && t('profile.form.job_types_available', {'count': options.length})}</span>
        </ModalHeader>
        <ModalBody className="modal-window__body--single-file-upload">
          <div className="form form--select-job-type">
            {options !== null &&
            options.map((item, index) => (
              <div className="form__item" key={item.id}>
                <Checkbox
                  type="checkbox"
                  id={index}
                  inputValue={item.id}
                  value={item.name}
                  checked={optionsState.includes(item.id)}
                  onChange={handleOptionsSelection.bind(this, item.id)}
                />
              </div>
            ))}
          </div>
          <div className="display--flex justify-content--center">
            <Button
              className="btn--yellow btn--big"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              {t('modals.apply')}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default withTranslation()(ModalSelect);
import React from "react";
import "./Checkbox.scss";

const Checkbox = ({
	checked = false,
	value = "Checkbox",
	inputValue = null,
	disabled = false,
	id = "",
	wrapperClassName = "",
	onChange = () => {},
	...attrs
}) => {
	const onChangeAction = e => {
		if (disabled) {
			e.preventDefault();
		} else {
			return onChange(e);
		}
	};

	return (
		<div className={`checkbox ${wrapperClassName}`}>
			<input
				type="checkbox"
				disabled={disabled}
				checked={checked}
				onChange={onChangeAction}
				id={id}
				value={inputValue}
				{...attrs}
			/>
			<label htmlFor={id}>{value}</label>
		</div>
	);
};

export default Checkbox;

import React, { createContext, useEffect, useReducer } from 'react';
import { userReducer } from '../reducers/userReducer';

export const DispatchUserContext = createContext();
export const StateUserContext = createContext();

const UserProvider = ({ children }) => {
  //Start create and set a default auth state with data from local storage
  const userData = JSON.parse(localStorage.getItem('user-data')) || {};

  const initialState = userData;

  const [userState, userDispatch] = useReducer(userReducer, initialState);
  //End create and set a default auth state with data from local storage

  //Start update local storage data
  useEffect(() => {
    if (userState.updated_at !== userData.updated_at && userState.updated_at !== undefined) {
      userDispatch({
        type: 'SAVE-USER-DATA',
      });
    }
  }, [userState, userData]);
  //End update local storage data

  return (
    <DispatchUserContext.Provider value={userDispatch}>
      <StateUserContext.Provider value={userState}>{children}</StateUserContext.Provider>
    </DispatchUserContext.Provider>
  );
};

export default UserProvider;

import React from "react";
import "./LangFormSwitcher.scss";

const LangFormSwitcher = ({
	value = "",
	onChange = () => {},
	langs = null,
	className = "",
}) => {
	const langItems = langs || ['en', 'nl', 'fr'];

	return (
		<ul className={`lang-switcher ${className}`}>
			{langItems.map((lang, index) => (
				<li key={index} className={value === lang ? 'active' : ''} onClick={onChange.bind(this, lang)}>{lang}</li>
			))}
		</ul>
	);
};

export default LangFormSwitcher;

import { useState, useEffect } from 'react';
import useAPIService from '../common/useApiService';

export const useJobChangeDifficulty = id => {
  const [data, setData] = useState(null);
  const [jobChangeDifficulty, invokeJobChangeDifficulty] = useAPIService(
    `jobs/${id}/change-complexity`, { method: 'post', data }
    );

  useEffect(() => {
    if (data) {
      invokeJobChangeDifficulty();
    }
  }, [data]);

  return [
    jobChangeDifficulty,
    {
      invokeJobChangeDifficulty: setData
    }
  ];
}
import { useState, useReducer } from 'react';
import { formReducer } from '../../reducers/formReducer';

const useForm = (defaultState) => {
  const [state, dispatch] = useReducer(formReducer, { ...defaultState });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = (callback, event) => {
    if (event) event.preventDefault();
    callback(state, event);
  };

  const handleChange = event => {
    event.persist();
    dispatch({ type: 'FILLING', payload: event.target });
  };

  const updateFields = filedData => {
    dispatch({
      type: 'UPDATE-DEFAULT',
      payload: filedData
    });
  }

  const updateFormState = ({ data, isLoading = false, error = null }) => {
    dispatch({
      type: 'UPDATE-DEFAULT',
      payload: data
    });
    setIsLoading(isLoading);
    setError(error);
  }

  return [
    state,
    {
      isLoading,
      error,
      updateFields,
      handleChange,
      handleSubmit,
      updateFormState,
    }
  ]
}

export default useForm;
import { useContext, useReducer } from 'react';
import { StateAuthContext } from '../../context/AuthContext';
import axios from 'axios';
import { url } from '../../constants/url';
import { fetchReducer } from '../../reducers/fetchReducer';
import { DispatchNotificationContext } from '../../context/NotificationContext';
import { StateLocaleContext } from '../../context/LocaleContext';

// ---- Hook
/**
 * Use API hook, get an service method and params to use that service
 * @param endpoint
 * @param params
 */

const useAPIService = (
  endpoint,
  params = {},
) => {
  const authState = useContext(StateAuthContext);
  const DispatchNotification = useContext(DispatchNotificationContext);
  const locale = useContext(StateLocaleContext);

  const defaultParams = {
    headers: {
      authorization: `Bearer ${authState ? authState.access_token : ''}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-Localization': locale,
    },
  };
  // ---- States
  const [state, dispatch] = useReducer(fetchReducer, {
    isLoading: false,
    error: false,
    data: null,
  });

  // ---- API
  const invoke = async () => {
    dispatch({type: 'FETCH-INIT'});
    try {
      const setting = { ...params, ...defaultParams };
      if (params.headers) {
        setting.headers = { ...setting.headers, ...params.headers };
      }
      const response = await axios({
        url: `${url + endpoint}`,
        ...setting,
      });
      dispatch({
        type: 'FETCH-SUCCESS',
        payload: await response.data,
      });
    } catch (error) {
      let errorMessage = '';
      console.log("Error", error.response);
      if (error.response && error.response.data.error.code === 'validation_error') {
        const errorFields = Object.values(error.response.data.error.message);
        errorMessage = errorFields.map(fieldError => fieldError).join(' ')
      } else {
        errorMessage = error.response && error.response.data
          ? error.response.data.error.message || error.response.data.message
          : null
      }

      DispatchNotification({
        type: 'ADD-NOTIFICATION-DATA',
        payload: [{
          data: {
            description: errorMessage,
          },
          type: 'error',
          isSystem: true,
        }],
      })

      dispatch({
        type: 'FETCH-FAILURE',
        payload: errorMessage,
      });
    }
  };

  return [{ ...state }, invoke];
};

export default useAPIService;
import React from "react";
import "./Field.scss";

const Field = ({
	value = "",
	onChange = () => { },
	disabled = false,
	icon = "",
	tag = "input",
	wrapperClassName = "",
	className = "",
	type = "text",
	...attrs
}) => {
	const onChangeAction = e => {
		if (disabled) {
			e.preventDefault();
		} else {
			return onChange(e);
		}
	};

	const classes = `${tag}  ${icon ? `icon icon--${icon}` : ''}`;
	const Tag = tag;

	return (
		<div className={`${classes} ${wrapperClassName}`}>
			<Tag className={className} disabled={disabled} value={value} type={type} onChange={onChangeAction} {...attrs} />
		</div>
	);
};

export default Field;

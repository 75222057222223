import React, { createContext, useEffect, useReducer } from 'react';
import { authReducer } from '../reducers/authReducer';

export const DispatchAuthContext = createContext();
export const StateAuthContext = createContext();

const AuthProvider = ({ children }) => {
  //Start create and set a default auth state with data from local storage
  const authData = JSON.parse(localStorage.getItem('auth-data')) || {};

  const initialState = {
    access_token: authData.access_token,
    expires_in: authData.expires_in,
    token_type: authData.token_type,
    refresh_token: authData.refresh_token,
    time_of_set: authData.time_of_set,
  };

  const [authState, authDispatch] = useReducer(authReducer, initialState);
  //End create and set a default auth state with data from local storage

  //Start update local storage data
  useEffect(() => {
    // console.log(authState.access_token !== authData.access_token, authState.access_token, authData.access_token)
    if (
      (authState.access_token !== authData.access_token && authState.access_token !== undefined) ||
      (authState.refresh_token !== authData.refresh_token && authState.refresh_token !== undefined)
    ) {
      authDispatch({
        type: 'SAVE-AUTH-DATA',
      });
    }
  }, [authState, authData]);
  //End update local storage data

  return (
    <DispatchAuthContext.Provider value={authDispatch}>
      <StateAuthContext.Provider value={authState}>{children}</StateAuthContext.Provider>
    </DispatchAuthContext.Provider>
  );
};

export default AuthProvider;

import React, { useState, useEffect, useContext } from 'react';
import './DownloadJobButton.scss';
import useAPIService from '../../../hooks/common/useApiService';
import io from 'socket.io-client';
import useSocket from '../../../hooks/common/useSocket';
import { useTranslation } from 'react-i18next';
import { StateUserContext } from '../../../context/UserContext';

const DownloadJobButton = ({
                             jobId,
                             children = '',
                             className = '',
                             onlyPercent = false,
                             ...attrs
                           }) => {
  const [downloadArchive, invokeDownloadArchive] = useAPIService(`jobs/get-job-item/${jobId}`);
  const [disabled, setDisabled] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [socketConnection] = useSocket();
  const userState = useContext(StateUserContext);
  const { t } = useTranslation();
  window.io = io;

  const onClickAction = e => {
    if (disabled) {
      e.preventDefault();
    } else {
      setDisabled(true);
      invokeDownloadArchive();
    }
  };

  useEffect(() => {
    if (socketConnection) {
      socketConnection.private(`archive.${userState.id}.${jobId}`)
        .listen('.download.status.updated', ({ data, isLoading }) => {
          if (isLoading) {
            setDisabled(true);
            setPercentage(data.percentage);
            sessionStorage.setItem(`download-${jobId}`, data.percentage);
          } else if (! isLoading) {
            window.open(data.link, '_self');
            setPercentage(0);
            setDisabled(false);
            sessionStorage.removeItem(`download-${jobId}`);
          }
          console.log(data, isLoading);
        });
    }

    return () => {
      if (socketConnection) {
        socketConnection.leave(`archive.${userState.id}.${jobId}`);
      }
    };
  }, [downloadArchive.data]);

  return (
    <>
      <button
        className={`btn ${className}`}
        type="button"
        disabled={disabled}
        onClick={onClickAction}
        {...attrs}
      >
        {disabled ? (
          <>
            <span className="btn__progress-bar" style={{ width: percentage + '%' }}></span>
            {onlyPercent ? parseInt(+percentage) + '%' : t('job.downloading', { 'percent': parseInt(+percentage) })}
          </>
        ) : children}
      </button>
    </>
  );
};

export default DownloadJobButton;

import React, { useContext, useState, useEffect } from 'react'
import { StateNotificationContext, DispatchNotificationContext } from "../../context/NotificationContext"
import Notification from "./Notification.js"
import './Notification.scss';

const notificationTypes = [
  {"App\\Notifications\\JobNotification": "info"}
]

const Notifications = ({ showNotificationBtn = false, setShowNotificationBtn }) => {
  const StateNotification = useContext(StateNotificationContext);
  const DispatchNotification = useContext(DispatchNotificationContext);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    if (StateNotification.length) {
      const notificationsId = notifications.map(n => n.id);
      if (StateNotification.find(n => n.isSystem)) {
        const systemNotifications = StateNotification.filter(n => n.isSystem && ! notificationsId.includes(n.id));
        setNotifications(prevState => [...prevState, ...systemNotifications]);
      } else if (StateNotification.find(n => n.isPush)) {
        const pushNotifications = StateNotification.filter(n => n.isPush && ! notificationsId.includes(n.id));
        setNotifications(prevState => [...prevState, ...pushNotifications]);
      }
    } else {
      setNotifications([]);
    }
  }, [StateNotification]);

  useEffect(() => {
    if (showNotificationBtn && StateNotification.length) {
      setNotifications(StateNotification);
      setShowNotificationBtn(false);
    } else {
      if (setShowNotificationBtn) {
        setShowNotificationBtn(false);
      }
    }
  }, [showNotificationBtn, StateNotification]);

  return (
    <div className="notifications">
      {notifications.map((notification, i) => (
        <Notification
          key={notification.id}
          type={
            notificationTypes.findIndex(type => type[notification.type]) !== -1
              ? notificationTypes.find(type => type[notification.type])[notification.type]
              : notification.type
          }
          notification={notification}
          setNotifications={setNotifications}
          DispatchNotification={DispatchNotification}
          delay={i * 200} />
      ))}
    </div>
  )
}

export default Notifications

import React, { useContext } from 'react';
import './RequestedJob.scss';
import Button from '../../../partials/Button/Button';
import ImagePreviews from '../../ImagePreviews/ImagePreviews';
import { StateJobContext } from '../../../../context/JobContext';
import { withTranslation } from 'react-i18next';

const RequestedJob = ({ className = '', t, ...attr }) => {
  const jobState = useContext(StateJobContext);
  const { name, job_items, job_types, description, urgency, uploaded_size } = jobState;

  return (
    <>
      <div className="container">
        <div className={`section__content job__requested  ${className}`} {...attr}>
          <div className="job__heading job__heading--two-column">
            <div>
              <h1 className="title">{t('job.quotation_requested')}</h1>
              <p className="sub-title">{t('job.requested_client_description')}</p>
            </div>
            {/*<InfoBlock className="info-block--yellow">{t('job.upload_max')}</InfoBlock>*/}
          </div>
          <div className="job__body">
            <div className="job__detail">
              <div className="job__detail-item">
                <div>{t('job.job_name')}</div>
                <div className="bullet bullet--yellow">{name}</div>
              </div>

              <div className="job__detail-item">
                <div>{t('job.type')}</div>
                {job_types && job_types.map((item, index) =>
                  <div className="bullet bullet--yellow" key={index + item.id}>
                    <span>{item.name}</span>
                    {item.description && (
                      <div className="question-mark">
                        <span className="question-mark__icon">&#63;</span>
                        <div className="question-mark__description">
                          {item.description}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>

              {description && (
                <div className="job__detail-item">
                  <div>{t('job.description')}</div>
                  <div className="bullet bullet--yellow">{description}</div>
                </div>
              )}

              <div className="job__detail-item">
                <div>{t('job.urgency.urgency_label')}</div>
                {urgency ? (
                  <div className="bullet bullet--yellow">{t('job.urgency.urgency_50')}</div>
                ) : (
                  <div className="bullet bullet--yellow">{t('job.urgency.urgency_0')}</div>
                )}
              </div>
            </div>
            <div className="job__images">
              <div className="job__detail-item">
                <div>{t('job.uploaded_images')}</div>
              </div>

              <ImagePreviews job_items={job_items.all} maxItems={8} uploadedSize={uploaded_size} editable={false} />
            </div>

            <div className="btn-group">
              <Button className="btn--yellow btn--big" to="/">
                {t('job.go_to_dashboard')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(RequestedJob);

import React, { useEffect, useState } from 'react'
import FadeTransition from "../partials/FadeTransition/FadeTransition"
import './Notification.scss';
import { useHistory } from "react-router-dom";
import { useNotificationsDelete } from '../../hooks/notifications/useNotificationsDelete';

const Notification = ({ notification, type = 'info', state = false, delay = 0, setNotifications, DispatchNotification }) => {
  const history = useHistory();
  const [fade, setFade] = useState(true);
  const { id, data } = notification;
  const [{ deleteNotification }, { deleteById }] = useNotificationsDelete();

  const remove = id => {
    if (! notification.isSystem) {
      deleteById(id);
    }
    DispatchNotification({
      type: 'REMOVE-NOTIFICATION-DATA-BY-ID',
      payload: id,
    })
  }

  const handleClickNotification = () => {
    if (fade) {
      setFade(false);
      remove(id);
      if (data.job) {
        history.push(`/jobs/${data.job.id}`);
      }
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (fade) {
        setFade(false);
        if (notification.isPush) {
          setNotifications(prevState => prevState.filter(n => n.id !== id))
          DispatchNotification({
            type: 'MOVE-PUSH-NOTIFICATION-TO-NOTIFICATION-DATA',
            payload: id,
          })
        } else {
          remove(id);
        }
      }
    }, 4000)
  }, [fade]);

  useEffect(() => {
    if (deleteNotification.data !== null) {
      DispatchNotification({
        type: 'REMOVE-NOTIFICATION-DATA-BY-ID',
        payload: id,
      })
    }
  }, [deleteNotification.data])

  return (
    <FadeTransition
      in={fade}
      unmountOnExit
      delay={delay}
    >
      <div className="notification" onClick={handleClickNotification}>
        <div className={`notification__icon notification__icon--${type}`}>
          {type !== 'error' ?
            (<svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22 2.68682L7.6 17L1 10.4398L2.69705 8.75298L7.6 13.6264L20.303 1L22 2.68682Z" fill="#F3CD29" stroke="#F3CD29"/>
              </svg>)
          :
            (<svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.877257 16.4961C0.488374 17.1628 0.969246 18 1.74104 18H19.259C20.0308 18 20.5116 17.1628 20.1227 16.4961L11.3638 1.48076C10.9779 0.819256 10.0221 0.819259 9.63622 1.48076L0.877257 16.4961ZM11.4545 15.1579H9.54545V13.2632H11.4545V15.1579ZM11.4545 11.3684H9.54545V7.57895H11.4545V11.3684Z" fill="#FF3939"/>
              </svg>)
          }
        </div>
        <span>{data.description}</span>
      </div>
    </FadeTransition>
  )
}

export default Notification

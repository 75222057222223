import { useState, useEffect } from 'react';
import useAPIService from '../common/useApiService';

export const useDeleteJobItem = () => {
  const [id, setId] = useState(null);
  const [deleteJobItem, invokeDeleteJobItem] = useAPIService(`job-items/${id}`, { method: 'delete' });

  useEffect(() => {
    if (id) {
      invokeDeleteJobItem();
    }
  }, [id])

  return [
    deleteJobItem,
    {
      deleteJobItemById: setId
    }
  ];
}
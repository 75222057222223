import React from "react";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
import Header from "../../../layouts/Header/Header";
import Footer from "../../../layouts/Footer/Footer";

const DefaultPrivateRouteLayout = ({ component: Component, ...rest }) => {
	return (
		<PrivateRoute
			{...rest}
			render={props => (
				<>
					<Header {...props} />
					<div className="route-wrapper">
						<Component {...props} />
					</div>
					<Footer {...props} />
				</>
			)}
		/>
	);
};

export default DefaultPrivateRouteLayout;
